
export const getUsers=async ()=>{
    
    //console.log('here')
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/auth/getuser/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,}
    })
    return data.json();
}

export const getUsers_=async (user)=>{
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/auth/getuser/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,}
    })
    const json=data.json();
    // console.log(json)
    return json
}

export const createUsers=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/auth/createuser/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}

export const deletUsers=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    //console.log(postData)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/auth/deleteuser/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`},
        body: JSON.stringify( {name:postData} ),
    })
    return data.json();
}

export const updateUsers=async (user)=>{
    if(!user) return Promise.reject("Data is Required");
    //console.log(user)
    if(user.active==='false') user.active=false
    else user.active=true
    //console.log(user.active)
    if(user.password==undefined) delete user['password']
    //else console.log('define')
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/auth/updateuser/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`},
        body: JSON.stringify( {uname:user.name,upassword:user.password,ucompanyaddress:user.companyaddress
            ,ucompaneyname:user.companyname,uactive:user.active,_id:user._id,ucustomername:user.customername,
            ucustomerno:user.customerno}),
    })
    return data.json();
}

export const getAdmin=async ()=>{
    
    //console.log('here')
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/auth/getadmin/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,}
    })
    return data.json();
}
export const updateAdmin=async (user)=>{
    if(!user) return Promise.reject("Data is Required");
    //console.log(user)
    //console.log(user.active)
    if(user.apassword==undefined) delete user['apassword']
    //else console.log('define')
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/auth/updateadmin/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`},
        body: JSON.stringify( {uname:user.aname,upassword:user.apassword,_id:user._id}),
    })
    return data.json();
}

export const getImei=async (user)=>{
    console.log(user)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/fetchallimei/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( {user:user}),
    })
    const json=data.json();
    //console.log(json)
    return json
}

export const getImei_ = async (user) => {
    //console.log(user)
    const data = await fetch(
      `http://gtswiman.electrowaretech.com:8000/api/imei/fetchallimei?page=${user.currentPage}&limit=${user.limit}&user=${user.user}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${localStorage.getItem("token")}`,
        },
        // body: JSON.stringify( {user:user}),
      }
    );
    const json = data.json();
    //console.log(json)
    return json;
  };
  export const getImeiSearch = async (user) => {
    //console.log(user.user)
    const data = await fetch(
      `http://gtswiman.electrowaretech.com:8000/api/imei/fetchsearchimei?page=${user.currentPage}&limit=${user.limit}&user=${user.user}&search=${user.searc}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": `${localStorage.getItem("token")}`,
        },
        // body: JSON.stringify( {user:user}),
      }
    );
    const json = data.json();
    //console.log(json)
    return json;
  };

export const addImei=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    console.log(postData)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/addimei/',{

        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( {user:postData.duser,imei:postData.dimei,location:postData.dlocation,active:postData.dactive,equipment:postData.dequipment} ),
    })
    //user,imei, location, equipment,active
    return data.json();
}
export const deletImei=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    //console.log(postData)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/deleteimei/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`},
        body: JSON.stringify( {imei:postData.imei,user:postData.user} ),
    })
    return data.json();
}
export const updateImei=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
     console.log(postData)
    //console.log(user.active)
    // if(user.apassword==undefined) delete user['apassword']
    //else console.log('define')
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/updateimei/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`},
        body: JSON.stringify( {user:postData.user,imei:postData.imei,uimei:postData.eimei,ulocation:postData.elocation,uequipment:postData.eequipment,uactive:postData.eactive}),
    })
    //user, imei,uimei, ulocation,uequipment,uactive
    return data.json();
}

export const getImeiData=async (postData)=>{
    // console.log(postData)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/fetchallimeidata/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( {imei:postData}),
    })
    const json=data.json();
    // console.log(json)
    return json
}

export const getProgSeq=async (user)=>{
    // console.log(user)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/fetchseqprog/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify(user),
    })
    const json=data.json();
    //console.log(json)
    return json
}

export const getInitialData=async (user)=>{
    // console.log(user)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/fetchallimeidata/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify(user),
    })
    const json=data.json();
    //console.log(json)
    return json
}

export const getPlotData=async (user)=>{
    // console.log(user)
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/imei/fetchplotdata/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify(user),
    })
    const json=data.json();
    //console.log(json)
    return json
}

export const updateIso=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updateiso/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
export const updateNas=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updatenas/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
export const updateSae=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updatesae/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
export const updateDig=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updatedig/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
export const updateAin=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updateain/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
export const updateTemp=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updatetemp/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
export const updateSig=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updatesig/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
export const updatePulse=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updatepulse/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
//updateseqprog
export const updateSeqProg=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updateseqprog/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}

export const updatePlotDim_=async (postData)=>{
    if(!postData) return Promise.reject("Data is Required");
    const data=await fetch('http://gtswiman.electrowaretech.com:8000/api/plot/updateplotdim/',{
        method:"POST",
        headers:{'Content-Type':"application/json",
                "auth-token": `${localStorage.getItem("token")}`,},
        body: JSON.stringify( postData ),
    })
    return data.json();
}
