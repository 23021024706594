import React, { useEffect, useState } from "react";
import {RgbaColorPicker } from "react-colorful";

const ProgressNasPara = (props) => {
  const {
    plotView,
    plotType,
    onChange,
    onPopup,
    onChecked,
    changeTitleColor,
    changeMiscColor,

    titleColor,
    miscColor,
    legIsoTextColor11,
    legIsoTextColor12,
    contColor,
    markColor,
    pathColor,
    trailColor,
    valueColor,
    plotColor,
    marksColor,
    needlePinColor,
    needleCircleColor,
    dangerColor,
    segColor1,
    segColor2,

    changeLegIsoTextColor11,
    changeLegIsoTextColor12,
    changeContColor,
    changeMarkColor,
    changePathColor,
    changeTrailColor,
    changeValueColor,
    changePlotColor,
    changeMarksColor,
    changeNeedlePinColor,
    changeNeedleCircleColor,
    changeDangerColor,
    changeSegColor1,
    changeSegColor2,

    keyAllow,

    savePlotView,
  } = props;
  useEffect(() => {}, []);
  // console.log(`rgba(${color.r },${color.g},${color.b},${color.a})`)
  let collapse_plot_title = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "300px",
    marginBottom: "300px",
  };
  let collapse_plot_path = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "430px",
    marginBottom: "300px",
  };
  let collapse_plot_trail = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "500px",
    marginBottom: "300px",
  };
  let collapse_plot_misc = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "200px",
    marginBottom: "300px",
  };
  let collapse_plot_leg = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "200px",
    marginBottom: "300px",
  };
  let collapse_plot_cont = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "400px",
    marginBottom: "300px",
  };
  let collapse_plot_mark = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "500px",
    marginBottom: "300px",
  };
  let collapse_plot_ticks = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "180px",
    marginBottom: "300px",
  };
  let collapse_plot_plot = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "300px",
    marginBottom: "300px",
  };
  let collapse_plot_needle_pin = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "450px",
    marginBottom: "300px",
  };
  let collapse_plot_needle_circle = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "500px",
    marginBottom: "300px",
  };
  let collapse_plot_danger = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "650px",
    marginBottom: "300px",
  };

  let collapse_plot_seg_1 = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "70px",
    marginBottom: "300px",
  };
  let collapse_plot_seg_2 = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "150px",
    marginBottom: "300px",
  };
  let collapse_plot_seg_3 = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "200px",
    marginBottom: "300px",
  };
  let collapse_plot_seg_4 = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "270px",
    marginBottom: "300px",
  };
  let collapse_plot_seg_5 = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "330px",
    marginBottom: "300px",
  };
  return (
    <div className="mb-3 d-flex flex-column align-items-start justify-content-start flex-wrap">
      <button
        className="btn btn-primary my-3"
        onClick={savePlotView}
      >
        Save
      </button>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <div className="form-floating my-3" style={{ width: "150px" }}>
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            value={plotView ? plotView.graphtype : "Speedo"}
            name={`graphtype`}
            onChange={onChange}
            style={{ height: "70px" }}
          >
            <option value="Speedo">Speedo</option>
            <option value="SemiSpeedo">Semi Speedo</option>
            <option value="SemiGauge">Semi Gauge</option>
            <option value="Progress">Progress</option>
            <option value="Lollipop">Lollipop</option>
            <option value="Line">Line</option>
            <option value="LineScatter">Line Scatter</option>
            <option value="Scatter">Scatter</option>
            <option value="Area">Area</option>
          </select>
          <label htmlFor="floatingSelect" style={{ fontSize: "20px" }}>
            Plot Type
          </label>
        </div>
        <label
          htmlFor="misc"
          className="form-label mx-3"
          style={{ fontSize: "20px" }}
        >
          Misc :
        </label>
        <div
          style={{
            background: `rgba(${miscColor.r},${miscColor.g},${miscColor.b},${miscColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_misc`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_misc`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_misc`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${miscColor.r},${miscColor.g},${miscColor.b},${miscColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_misc`}
          style={collapse_plot_misc}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={miscColor}
              onChange={changeMiscColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        {plotType !== "SemiGauge" ? (
          <>
            <label
              htmlFor="Container"
              className="form-label mx-3"
              style={{ fontSize: "20px" }}
            >
              Container :
            </label>
            <div
              style={{
                background: `rgba(${contColor.r},${contColor.g},${contColor.b},${contColor.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_misc`}>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_misc`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_cont`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${contColor.r},${contColor.g},${contColor.b},${contColor.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_cont`}
              style={collapse_plot_cont}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker color={contColor} onChange={changeContColor} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <label
          htmlFor="Marker"
          className="form-label mx-3"
          style={{ fontSize: "20px" }}
        >
          Marker :
        </label>
        <div
          style={{
            background: `rgba(${markColor.r},${markColor.g},${markColor.b},${markColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_misc`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_misc`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_mark`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${markColor.r},${markColor.g},${markColor.b},${markColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_mark`}
          style={collapse_plot_mark}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={markColor}
              onChange={changeMarkColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_title"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          {plotType !== "SemiGauge" ? "Title" : "Value"}
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei_title"
          name="graphtitle"
          onChange={onChange}
          value={plotView.graphtitle}
          maxLength={30}
          style={{ width: "270px", textAlign: "center", height: "35px" }}
        />
        {/* <div style={{ display: "null" }}>
          
        </div> */}
        <div
          style={{
            background:
              plotType !== "SemiGauge"
                ? `rgba(${titleColor.r},${titleColor.g},${titleColor.b},${titleColor.a})`
                : `rgba(${valueColor.r},${valueColor.g},${valueColor.b},${valueColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_title`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background:
                  plotType !== "SemiGauge"
                    ? `rgba(${titleColor.r},${titleColor.g},${titleColor.b},${titleColor.a})`
                    : `rgba(${valueColor.r},${valueColor.g},${valueColor.b},${valueColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_title`}
          style={collapse_plot_title}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={plotType !== "SemiGauge" ? titleColor : valueColor}
              onChange={
                plotType !== "SemiGauge" ? changeTitleColor : changeValueColor
              } /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <label
          htmlFor="dimei_1"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Path:
        </label>
        <div
          style={{
            background: `rgba(${pathColor.r},${pathColor.g},${pathColor.b},${pathColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_path`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${pathColor.r},${pathColor.g},${pathColor.b},${pathColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_path`}
          style={collapse_plot_path}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={pathColor}
              onChange={changePathColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <label
          htmlFor="dimei_2"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Trail:
        </label>
        <div
          style={{
            background: `rgba(${trailColor.r},${trailColor.g},${trailColor.b},${trailColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_trail`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${trailColor.r},${trailColor.g},${trailColor.b},${trailColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_trail`}
          style={collapse_plot_trail}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={trailColor}
              onChange={changeTrailColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_keytext_1"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Key 1
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei_keytext_1"
          name="keytext1"
          onChange={onChange}
          value={plotView.keytext1}
          maxLength={15}
          style={{ width: "150px", textAlign: "center", height: "35px" }}
        />
        <div
          style={{
            background: `rgba(${legIsoTextColor11.r},${legIsoTextColor11.g},${legIsoTextColor11.b},${legIsoTextColor11.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_leg_iso_text_11`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${legIsoTextColor11.r},${legIsoTextColor11.g},${legIsoTextColor11.b},${legIsoTextColor11.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_leg_iso_text_11`}
          style={collapse_plot_leg}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={legIsoTextColor11}
              onChange={changeLegIsoTextColor11} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <input
          className="form-check-input"
          type="checkbox"
          checked={keyAllow.keyallow1}
          // value={plotView.keyallow1}
          id="flexCheckDefault1"
          onChange={onChecked}
          name="keyallow1"
        />
        {plotType !== "SemiGauge" ? (
          <>
            <label
              htmlFor="dimei_12"
              className="form-label mx-4"
              style={{ fontSize: "18px" }}
            >
              Value :
            </label>
            <div
              style={{
                background: `rgba(${valueColor.r},${valueColor.g},${valueColor.b},${valueColor.a})`,
              }}
              className=""
            >
              <p className={`options_plot_leg`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_value`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${valueColor.r},${valueColor.g},${valueColor.b},${valueColor.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_value`}
              style={collapse_plot_path}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={valueColor}
                  onChange={changeValueColor} /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <label
          htmlFor="dimei_1_fixed"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Decimal :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_fixed"
          name="dashsize"
          onChange={onChange}
          value={plotView.dashsize}
          // maxLength={15}
          min={0}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_keytext_2"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Key 2
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei_keytext_2"
          name="keytext2"
          onChange={onChange}
          value={plotView.keytext2}
          maxLength={15}
          style={{ width: "150px", textAlign: "center", height: "35px" }}
        />
        <div
          style={{
            background: `rgba(${legIsoTextColor12.r},${legIsoTextColor12.g},${legIsoTextColor12.b},${legIsoTextColor12.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_leg_iso_text_12`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${legIsoTextColor12.r},${legIsoTextColor12.g},${legIsoTextColor12.b},${legIsoTextColor12.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_leg_iso_text_12`}
          style={collapse_plot_leg}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={legIsoTextColor12}
              onChange={changeLegIsoTextColor12} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <input
          className="form-check-input"
          type="checkbox"
          checked={keyAllow.keyallow2}
          // value={plotView.keyallow1}
          id="flexCheckDefault2"
          onChange={onChecked}
          name="keyallow2"
          style={{ marginRight: "10px" }}
        />
        <label
          htmlFor="dimei_1_max"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Max :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_max"
          name="highlimit"
          onChange={onChange}
          value={plotView.highlimit}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <label
          htmlFor="dimei_1_min"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Min :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_min"
          name="lowlimit"
          onChange={onChange}
          value={plotView.lowlimit}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_1_map_max"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Map_H :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_map_max"
          name="highmap"
          onChange={onChange}
          value={plotView.highmap}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <label
          htmlFor="dimei_1_map_min"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Map_L :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_map_min"
          name="lowmap"
          onChange={onChange}
          value={plotView.lowmap}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_popup_h"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Alert_H :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_popup_h"
          name="popup_h"
          onChange={onChange}
          value={plotView.popup_h}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <label
          htmlFor="dimei_popup_l"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Alert_L :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_popup_l"
          name="popup_l"
          onChange={onChange}
          value={plotView.popup_l}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <input
          className="form-check-input"
          type="checkbox"
          checked={plotView.popup_enable}
          // value={plotView.keyallow1}
          id="flexCheckDefault_alert"
          onChange={onPopup}
          name="popup_enable"
          style={{ marginRight: "10px" }}
        />
        {plotType !== "Progress" && plotType !== "SemiGauge" ? (
          <>
            <label
              htmlFor="dimei_danger"
              className="form-label mx-2"
              style={{ fontSize: "18px" }}
            >
              Danger :
            </label>
            <input
              type="number"
              className="form-control mx-2"
              id="dimei_d_angle"
              name="legisoper12"
              onChange={onChange}
              value={plotView.legisoper12}
              // maxLength={15}
              style={{ width: "120px", textAlign: "center", height: "35px" }}
            />
            <div
              style={{
                background: `rgba(${dangerColor.r},${dangerColor.g},${dangerColor.b},${dangerColor.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_leg`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_danger`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${dangerColor.r},${dangerColor.g},${dangerColor.b},${dangerColor.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_danger`}
              style={collapse_plot_danger}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={dangerColor}
                  onChange={changeDangerColor} /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        {plotType !== "Progress" ? (
          <>
            <label
              htmlFor="dimei_Marks"
              className="form-label mx-2"
              style={{ fontSize: "18px" }}
            >
              Ticks :
            </label>
            <input
              type="number"
              className="form-control mx-2"
              id="dimei_marks"
              name="legisoper11"
              onChange={onChange}
              value={plotView.legisoper11}
              min={1}
              // disabled={plotType!=='SemiGauge'? false : true}
              // maxLength={15}
              style={{ width: "120px", textAlign: "center", height: "35px" }}
            />
            <div
              style={{
                background: `rgba(${marksColor.r},${marksColor.g},${marksColor.b},${marksColor.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_leg`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_marks`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${marksColor.r},${marksColor.g},${marksColor.b},${marksColor.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_marks`}
              style={collapse_plot_ticks}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={marksColor}
                  onChange={changeMarksColor} /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {plotType !== "Progress" ? (
          <>
            <label
              htmlFor="dimei_plot"
              className="form-label mx-2"
              style={{ fontSize: "18px" }}
            >
              Plot :
            </label>
            <div
              style={{
                background: `rgba(${plotColor.r},${plotColor.g},${plotColor.b},${plotColor.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_leg`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_plot`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${plotColor.r},${plotColor.g},${plotColor.b},${plotColor.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_plot`}
              style={collapse_plot_plot}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={plotColor}
                  onChange={changePlotColor} /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {plotType !== "Progress" ? (
          <>
            <label
              htmlFor="dimei_needle_pin"
              className="form-label mx-2"
              style={{ fontSize: "18px" }}
            >
              Needle :
            </label>
            <div
              style={{
                background: `rgba(${needlePinColor.r},${needlePinColor.g},${needlePinColor.b},${needlePinColor.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_leg`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_needle_pin`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${needlePinColor.r},${needlePinColor.g},${needlePinColor.b},${needlePinColor.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_needle_pin`}
              style={collapse_plot_needle_pin}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={needlePinColor}
                  onChange={
                    changeNeedlePinColor
                  } /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>
            {plotType !== "SemiGauge" ? (
              <>
                <div
                  style={{
                    background: `rgba(${needleCircleColor.r},${needleCircleColor.g},${needleCircleColor.b},${needleCircleColor.a})`,
                  }}
                  className=" mx-2"
                >
                  <p className={`options_plot_leg`} /*style={options_plot}*/>
                    <button
                      className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseExample_needle_circle`}
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      style={{
                        background: `rgba(${needleCircleColor.r},${needleCircleColor.g},${needleCircleColor.b},${needleCircleColor.a})`,
                      }}
                    >
                      Color
                    </button>
                  </p>
                </div>
                <div
                  className="collapse"
                  id={`collapseExample_needle_circle`}
                  style={collapse_plot_needle_circle}
                >
                  <div className={`card card-body`}>
                    <RgbaColorPicker
                      color={needleCircleColor}
                      onChange={
                        changeNeedleCircleColor
                      } /*onMouseUp={setTitleColorFn}*/
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
      {plotType === "SemiGauge" ? (
        <>
          <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
            <label
              htmlFor="dimei_segments"
              className="form-label mx-2"
              style={{ fontSize: "18px" }}
            >
              Segments :
            </label>

            <div
              style={{
                background: `rgba(${segColor1.r},${segColor1.g},${segColor1.b},${segColor1.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_leg`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_segment_1`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${segColor1.r},${segColor1.g},${segColor1.b},${segColor1.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_segment_1`}
              style={collapse_plot_seg_1}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={segColor1}
                  onChange={changeSegColor1} /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>

            <div
              style={{
                background: `rgba(${segColor2.r},${segColor2.g},${segColor2.b},${segColor2.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_leg`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_segment_2`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${segColor2.r},${segColor2.g},${segColor2.b},${segColor2.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_segment_2`}
              style={collapse_plot_seg_2}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={segColor2}
                  onChange={changeSegColor2} /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProgressNasPara;
