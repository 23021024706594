import logo from "./logo.svg";
import React, { Component, useState } from "react";
import "./App.css";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashState from "./context/dash/dashState";
import LoginForm from "./components/login/LoginForm";
import Navbar from "./components/navbar/Navbar";
import Alert from "./components/alert/Alert";
import About from "./pages/about/About";
// import ImeiData from "./pages/imei/ImeiData";
import Init from "./components/Init";
import Dummy from "./components/dummy";
import Imei from "./components/imei/Imei";
// import ImeiDataPage from "./pages/imei/ImeiDataPage";
import Dashboard from "./pages/imei/Dashboard";
import AinPlot from "./pages/imei/AinPlot";
import DigPlot from "./pages/imei/DigPlot";
import PulsePlot from "./pages/imei/PulsePlot";
import NasPlot from "./pages/imei/NasPlot";
import IsoPlot from "./pages/imei/IsoPlot";
import SaePlot from "./pages/imei/SaePlot";
import TempPlot from "./pages/imei/TempPlot";
import SigPlot from "./pages/imei/SigPlot";

import AinSetting from "./pages/imei/AinSetting";
import DigSetting from "./pages/imei/DigSetting";
import PulseSetting from "./pages/imei/PulseSetting";
import NasSetting from "./pages/imei/NasSetting";
import IsoSetting from "./pages/imei/IsoSetting";
import SaeSetting from "./pages/imei/SaeSetting";
import TempSetting from "./pages/imei/TempSetting";
import SigSetting from "./pages/imei/SigSetting";
import PlotSeq from "./pages/imei/PlotSeq";
import PlotSize from "./pages/imei/PlotSize";

function App() {
  // const [alert, setAlert] = useState(null);
  // const showAlert = (message, type) => {
  //   setAlert({
  //     msg: message,
  //     type: type,
  //   });
  //   setTimeout(() => {
  //     setAlert(null);
  //   }, 3000);
  // };

  return (
    <DashState>
      <Router>
        <Init></Init>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about" element={<About></About>}></Route>
          <Route exact path="/loading" element={<Dummy></Dummy>}></Route>
          <Route exact path="/devices/:userId/:user" element={<Imei></Imei>}></Route>
          <Route exact path="/dashboard/:userId/:user/:imei" element={<Dashboard></Dashboard>}></Route>

          <Route exact path="/ainplot/:userId/:user/:imei" element={<AinPlot/>}></Route>
          <Route exact path="/digplot/:userId/:user/:imei" element={<DigPlot/>}></Route>
          <Route exact path="/pulseplot/:userId/:user/:imei" element={<PulsePlot/>}></Route>
          <Route exact path="/nasplot/:userId/:user/:imei" element={<NasPlot/>}></Route>
          <Route exact path="/isoplot/:userId/:user/:imei" element={<IsoPlot/>}></Route>
          <Route exact path="/saeplot/:userId/:user/:imei" element={<SaePlot/>}></Route>
          <Route exact path="/tempplot/:userId/:user/:imei" element={<TempPlot/>}></Route>
          <Route exact path="/sigplot/:userId/:user/:imei" element={<SigPlot/>}></Route>

          <Route exact path="/ainplotsetting/:userId/:user/:imei" element={<AinSetting/>}></Route>
          <Route exact path="/digplotsetting/:userId/:user/:imei" element={<DigSetting/>}></Route>
          <Route exact path="/pulseplotsetting/:userId/:user/:imei" element={<PulseSetting/>}></Route>
          <Route exact path="/nasplotsetting/:userId/:user/:imei" element={<NasSetting/>}></Route>
          <Route exact path="/isoplotsetting/:userId/:user/:imei" element={<IsoSetting/>}></Route>
          <Route exact path="/saeplotsetting/:userId/:user/:imei" element={<SaeSetting/>}></Route>
          <Route exact path="/tempplotsetting/:userId/:user/:imei" element={<TempSetting/>}></Route>
          <Route exact path="/sigplotsetting/:userId/:user/:imei" element={<SigSetting/>}></Route>

          <Route exact path="/plotsequence/:userId/:user/:imei" element={<PlotSeq/>}></Route>

          <Route exact path="/plotsize/:userId/:user/:imei" element={<PlotSize/>}></Route>
        </Routes>
      </Router>
    </DashState>
  );
}

export default App;
