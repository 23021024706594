import PropTypes from 'prop-types'
import React, { Component } from 'react'
import loading from "./Spinner-1s-200px.gif"

const Spinner=()=>  {
  //static propTypes = {}

    return (
      <div className='text-center'>
        <img src={loading} alt='loading'></img>
      </div>
    )
}

export default Spinner