import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import data from "./uid_1_data(2).csv";
import $ from "jquery";
import "./simpleplot.scss";
import { DateRangePicker } from "rsuite";
// import "react-awesome-popover/build/index.css";

const AreaGroupPlot = (props) => {
  const updateFetch = useRef(0);
  const {
    plot_id,
    title,
    width,
    height,
    datePicker,
    handleOk,
    plotData,
    plotKeys,
    latestData,
    latestDate,
    validData,
    plotView,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [actPlotData, setActPlotData] = useState();
  const [legBox, setLegBox] = useState({
    leg1: true,
    leg2: true,
    leg3: true,
    leg4: true,
  });
  // const { status, data, isFetching } = useQuery(
  //   ["imeiData"],
  //   () =>
  //     getImeiData(imei),
  //   {
  //     onSuccess: (e) => {
  //       // console.log(e)
  //       if (updateFetch.current===1) {
  //         updateFetch.current=0;
  //         console.log(e)
  //         // dispatch(updateSeqProgams(0));
  //       }
  //       //console.log(storeData)
  //       //settime()
  //     },
  //   },
  // );

  const dragItem = useRef(null);

  const rstZoomRef = useRef();

  const toolRef = useRef();

  const updateRef = useRef(false);

  const [currentZoomState, setCurrentZoomState] = useState();

  const [resetScalex, setResetScalex] = useState();

  const [lastUpdated, setLastUpdated] = useState();

  const [tooltipAllow, setTooltipAllow] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [update, setUpdate] = useState(0);
  const [firstUpdate, setFirstUpdate] = useState(0);

  const plot_container = {
    // background-color: gray
    borderRadius: "10px",
  };
  const xTitle_val = {
    transform: "translateY(-20px)",
  };
  const Title_val = {
    transform: "translateY(-40px)",
    margin: "10px 10px",
  };
  const options_plot = {
    display: "flex",
    justifyContent: "end",
    marginRight: "10px",
    // height: 20px;
    marginTop: "-40px",
  };
  const collapse_plot = {
    width: "480px",
    marginLeft: `${width - 480}px`,
    // marginTop: `-${height * 0.4}px`,
  };
  const legend_plot_1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: `${width}px`,
    margin: "10px 5px",
    marginTop: "5px",
  };
  const legend_box_1 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor11}`,
    color: `${plotView[0].legisocolor11}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_1 = {
    marginRight: "3px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor11}`,
  };
  const legend_box_2 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor12}`,
    color: `${plotView[0].legisocolor12}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_2 = {
    marginRight: "4px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor12}`,
  };
  const legend_box_3 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor13}`,
    color: `${plotView[0].legisocolor13}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_3 = {
    marginRight: "4px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor13}`,
  };
  const legend_box_4 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor14}`,
    color: `${plotView[0].legisocolor14}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_4 = {
    marginRight: "3px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor14}`,
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let dimensions = {
    width: width,
    height: height,
    margin: {
      top: 80,
      bottom: 70,
      right: 10,
      left: 80,
    },
    padding: {
      titleX: 15,
      titleY: 30,
      xLabel: -1,
      yLabel: "-1.3em",
    },
  };

  let tooltipCircles = {
    strokeWidth: "2.5px",
  };

  let data_ = 0;
  const ref = useRef();
  var timeFormat = d3.timeParse("%Y-%m-%d %H:%M:%S");
  let parseRow = (d) => {
    d.dtm = timeFormat(d.dtm);
    //d.dtm=timechg(d.dtm)
    //console.log(d.dtm)

    d.sr = +d["sr"];

    d.analog_data = +d["analog_data"];

    d.dig_data = JSON.parse(d.digital_data);
    d.di1 = +d.dig_data["di1"];
    d.di2 = +d.dig_data["di2"];
    d.di3 = +d.dig_data["di3"];
    d.di4 = +d.dig_data["di4"];
    delete d.digital_data;
    delete d.dig_data;
    // console.log(d.dig_data['di1'])

    d.iso_4um = +d["iso-4um"];
    d.iso_6um = +d["iso-6um"];
    d.iso_14um = +d["iso-14um"];
    d.iso_21um = +d["iso-21um"];
    delete d["iso-4um"];
    delete d["iso-6um"];
    delete d["iso-14um"];
    delete d["iso-21um"];

    d.nas_6_14um = +d["nas-6-14um"];
    d.nas_14_21um = +d["nas-14-21um"];
    delete d["nas-6-14um"];
    delete d["nas-14-21um"];

    d.node_id = +d.node_id;

    d.pulse_count = +d.pulse_count;

    d.reg_len = +d.reg_len;

    d.sae_4um = +d["sae-4um"];
    d.sae_6um = +d["sae-6um"];
    d.sae_14um = +d["sae-14um"];
    d.sae_21um = +d["sae-21um"];
    delete d["sae-4um"];
    delete d["sae-6um"];
    delete d["sae-14um"];
    delete d["sae-21um"];

    d.seq = +d.seq;

    d.sig = +d.sig;

    d.temperature = +d.temperature;
    return d;
  };
  function mapValues(val) {
    let x1 = +plotView[0].lowlimit;
    let x2 = +plotView[0].highlimit;
    let y1 = +plotView[0].lowmap;
    let y2 = +plotView[0].highmap;
    let m = (y2 - y1) / (x2 - x1);
    let y = y1 + m * (val - x1);
    return Number(y).toFixed(+plotView[0].dashsize);
  }
  const createGraph = async () => {
    data_ = await d3.csv(data, parseRow);
    // set the dimensions and margins of the graph
    const mydata = (d) => {
      return d.analog_data;
    };
    // console.log(data_);

    dimensions.boundedWidth =
      dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.boundedHeight =
      dimensions.height - dimensions.margin.top - dimensions.margin.bottom;
    let my_leg_margin_left = dimensions.margin.left;
    //let my_leg_margin_right = dimensions.margin.right;
    let selection = d3.select(ref.current);
    const svg = selection
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .style("background-color", `${plotView[0].contbgcolor}`);

    let bounds = d3
      .select(`#bounds_${plot_id}`)
      .attr(
        "transform",
        `translate(${my_leg_margin_left},${dimensions.margin.top})`
      );
    const getXdata = (v) => timeFormat(v.datedevice);
    const getYdata1 = (v) => {
      if (plotKeys.length > 0) {
        return mapValues(v[plotKeys[0]]);
      } else return 0;
    };
    const getYdata2 = (v) => {
      if (plotKeys.length - 1 > 0) {
        return mapValues(v[plotKeys[1]]);
      } else return 0;
    };
    const getYdata3 = (v) => {
      if (plotKeys.length - 2 > 0) {
        return mapValues(v[plotKeys[2]]);
      } else return 0;
    };
    const getYdata4 = (v) => {
      if (plotKeys.length - 3 > 0) {
        return mapValues(v[plotKeys[3]]);
      } else return 0;
    };

    const onMouseOver = () => {
      // d3.select(`.tooltip-area_${plot_id}`).style("opacity", 1);
    };

    const tooltips = d3
      .select(`#tooltips_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", "20px")
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", -10)
      .attr("transform-origin", "center center")
      .attr("width", "200px")
      .style("background", `${plotView[0].tooltipbgcolor}`);

    const onMouseMove = (event, d) => {
      // d3.select('#data1').raise();
      // d3.select(this).raise()
      let u_cnt = 0;
      let sel_index = 0;
      // while (u_cnt < my_key.length) {
      //     if (`${base_id}_${my_scatter_class[u_cnt]}` == event.target.className.baseVal) {
      //         change_yAccessor_Key(my_key, u_cnt)
      //         sel_index = u_cnt;
      //     }
      //     u_cnt++;
      // }
      // console.log(event.target.className);
      // console.log(event.target);
      const mousePosition = d3.pointer(event);
      const hoverDate = zoomedScalex.invert(mousePosition[0]);
      const getDistanceFromHoverDate = (d) => Math.abs(getXdata(d) - hoverDate);
      const closestIndex = d3.scan(
        actPlotData,
        (a, b) => getDistanceFromHoverDate(a) - getDistanceFromHoverDate(b)
      );
      const closestDataPoint = actPlotData[closestIndex];
      // console.log(closestDataPoint);

      const closestxValue = getXdata(closestDataPoint);
      let closestyValue = getYdata1(closestDataPoint);

      // console.log(closestxValue);
      // console.log(closestyValue);

      const formatDate = d3.timeFormat("%d/%m/%Y %I:%M:%S %p");
      let x = zoomedScalex(closestxValue);
      const y = yScale(closestyValue);
      if (
        x <= dimensions.boundedWidth &&
        x >= 0 &&
        tooltipAllow === true &&
        (legBox["leg1"] === true ||
          legBox["leg2"] === true ||
          legBox["leg3"] === true ||
          legBox["leg4"] === true)
      ) {
        // console.log(closestyValue);
        // tooltip.style(
        //     "transform",
        //     `translate(` + `calc( 10% + ${x}px),` + `calc(50% + ${y}px)` + `)`
        // );

        // tooltip.style('opacity', 1)
        let temp_data = [];
        temp_data[0] = {
          data: 0,
          color: "red",
        };
        let tool_data = [];
        //console.log('obj: ')
        //console.log(temp_data.data)
        let tool_color = [];
        let tooltip_y_pt = 0;
        let tooltip_x_pt = 0;
        let tooltip_y1_valid = 0;
        let tooltip_y2_valid = 0;
        let tooltip_y3_valid = 0;
        let tooltip_y4_valid = 0;
        tooltip_y1_valid = yScale(closestyValue);
        if(plotView[0].mainisokey1=='nas_6_14um') {
          if(closestyValue==-1) {
            tooltip_y1_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y1_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y1_valid = `${closestyValue} | Class > 12`;
          }
        }
        else if(plotView[0].mainisokey1=='sae_4um') {
          if(closestyValue==-2) {
            tooltip_y1_valid = `${closestyValue} | Class 000`;
          }
          else if(closestyValue==-1) {
            tooltip_y1_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y1_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y1_valid = `${closestyValue} | Class > 12`;
          }
        }
        else {
          tooltip_y1_valid = closestyValue;
        }

        //if ($(`#data1_${plot_id}`).css("opacity")) {
        tooltip_circle_1
          .style(
            "opacity",
            plotKeys.length - 0 > 0 && legBox["leg1"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue));
        //}

        closestyValue = getYdata2(closestDataPoint);
        if(plotView[0].mainisokey1=='nas_6_14um') {
          if(closestyValue==-1) {
            tooltip_y2_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y2_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y2_valid = `${closestyValue} | Class > 12`;
          }
        }
        else if(plotView[0].mainisokey1=='sae_4um') {
          if(closestyValue==-2) {
            tooltip_y2_valid = `${closestyValue} | Class 000`;
          }
          else if(closestyValue==-1) {
            tooltip_y2_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y2_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y2_valid = `${closestyValue} | Class > 12`;
          }
        }
        else {
          tooltip_y2_valid = closestyValue;
        }
        // tooltip_y2_valid = yScale(closestyValue);
        tooltip_circle_2
          .style(
            "opacity",
            plotKeys.length - 1 > 0 && legBox["leg2"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue) + 4);

        closestyValue = getYdata3(closestDataPoint);
        if(plotView[0].mainisokey1=='nas_6_14um') {
          if(closestyValue==-1) {
            tooltip_y3_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y3_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y3_valid = `${closestyValue} | Class > 12`;
          }
        }
        else if(plotView[0].mainisokey1=='sae_4um') {
          if(closestyValue==-2) {
            tooltip_y3_valid = `${closestyValue} | Class 000`;
          }
          else if(closestyValue==-1) {
            tooltip_y3_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y3_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y3_valid = `${closestyValue} | Class > 12`;
          }
        }
        else {
          tooltip_y3_valid = closestyValue;
        }
        // tooltip_y3_valid = yScale(closestyValue);
        tooltip_circle_3
          .style(
            "opacity",
            plotKeys.length - 2 > 0 && legBox["leg3"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue) + 8);

        closestyValue = getYdata4(closestDataPoint);
        if(plotView[0].mainisokey1=='nas_6_14um') {
          if(closestyValue==-1) {
            tooltip_y4_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y4_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y4_valid = `${closestyValue} | Class > 12`;
          }
        }
        else if(plotView[0].mainisokey1=='sae_4um') {
          if(closestyValue==-2) {
            tooltip_y4_valid = `${closestyValue} | Class 000`;
          }
          else if(closestyValue==-1) {
            tooltip_y4_valid = `${closestyValue} | Class 00`;
          }
          else if(closestyValue>=0 && closestyValue<=12 ) {
            tooltip_y4_valid = `${closestyValue} | Class ${closestyValue}`;
          }
          else if(closestyValue>12 ) {
            tooltip_y4_valid = `${closestyValue} | Class > 12`;
          }
        }
        else {
          tooltip_y4_valid = closestyValue;
        }
        // tooltip_y4_valid = yScale(closestyValue);
        tooltip_circle_4
          .style(
            "opacity",
            plotKeys.length - 3 > 0 && legBox["leg4"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue) + 12);

        // tooltip_circle_2.style('opacity', 1)
        // .attr("cx", xScale(closestxValue))
        // .attr("cy", yScale(closestyValue))
        if (
          legBox["leg1"] === true ||
          legBox["leg2"] === true ||
          legBox["leg3"] === true ||
          legBox["leg4"] === true
        ) {
          if (plotKeys.length === 1) {
            if (legBox["leg1"] === true) {
              xAxisLine.attr("display", null);
              xAxisLine.attr("x", xScale(closestxValue));
            }
          } else if (plotKeys.length !== 0) {
            xAxisLine.attr("display", null);
            xAxisLine.attr("x", xScale(closestxValue));
          }
        }

        let elem = document.getElementById(`main_Plot`);
        // console.log(elem.className)
        let value_x;
        // console.log(value_x=parseInt(getComputedStyle(document.querySelector(`.${elem.className}`)).marginLeft));
        let r = 0;
        if (event.pageX > width) {
          r = event.pageX - width;
        } else {
          r = event.pageX;
        }
        let tool = event.layerY;
        //console.log(xScale(closestxValue))
        //console.log(event.pageX)
        if (xScale(closestxValue) > dimensions.boundedWidth - 150) {
          x = x - 250;
        } else {
          x = x + 15;
        }
        tooltips
          .html("")
          .style("display", "block")
          .style("text-align", "center")
          .style("left", `${x + 60}px`)
          .style("top", `${tool}px`)
          .style("z-index", 50)
          .style("margin", "5px 15px")
          .style("font-size", "16px");
        // .attr('transform',`translate(${event.pageX}px,${event.pagey}px)`)
        if (
          legBox["leg1"] === true ||
          legBox["leg2"] === true ||
          legBox["leg3"] === true ||
          legBox["leg4"] === true
        ) {
          if (plotKeys.length === 1) {
            if (legBox["leg1"] === true) {
              tooltips
                .append("span")
                .style("text-align", "center")
                .style("text-decoration", "underline")
                .html(`${plotView[0].tooltiphead}`)
                .style("font-weight", "bold")
                .style("color", `${plotView[0].tooltipheadcolor}`)
                .style("width", "200px");
            }
          } else if (plotKeys.length != 0) {
            tooltips
              .append("span")
              .style("text-align", "center")
              .style("text-decoration", "underline")
              .html(`${plotView[0].tooltiphead}`)
              .style("font-weight", "bold")
              .style("color", `${plotView[0].tooltipheadcolor}`)
              .style("width", "200px");
          }
        }

        if (plotKeys.length - 0 > 0 && legBox["leg1"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");

          tool = tooltips
            .select(`.tool_txt_${plot_id}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor11}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].legisotext11}: ${tooltip_y1_valid}`);
        }

        if (plotKeys.length - 1 > 0 && legBox["leg2"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id + 1}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 1}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor12}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 1}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].legisotext12}: ${tooltip_y2_valid}`);
        }

        if (plotKeys.length - 2 > 0 && legBox["leg3"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id + 2}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 2}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor13}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 2}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].legisotext13}: ${tooltip_y3_valid}`);
        }

        if (plotKeys.length - 3 > 0 && legBox["leg4"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id + 3}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 3}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor14}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 3}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].legisotext14}: ${tooltip_y4_valid}`);
        }

        if (
          plotKeys.length > 0 &&
          (legBox["leg1"] === true ||
            legBox["leg2"] === true ||
            legBox["leg3"] === true ||
            legBox["leg4"] === true)
        ) {
          if (plotKeys.length === 1) {
            if (legBox["leg1"] === true) {
              tooltips
                .append("div")
                .attr("class", `tool_txt_${plot_id + 4}`)
                .style("display", "flex");
              tool = tooltips
                .select(`.tool_txt_${plot_id + 4}`)
                .append("span")
                .style("padding-right", "5px")
                .style("padding-left", "5px")
                .style("color", `${plotView[0].tooltipdatacolor}`)
                .html(formatDate(closestxValue))
                // .style('font-size', tooltip_fontsize)
                .style("text-align", "center");
            }
          } else if (plotKeys.length !== 0) {
            tooltips
              .append("div")
              .attr("class", `tool_txt_${plot_id + 4}`)
              .style("display", "flex");
            tool = tooltips
              .select(`.tool_txt_${plot_id + 4}`)
              .append("span")
              .style("padding-right", "5px")
              .style("padding-left", "5px")
              .style("color", `${plotView[0].tooltipdatacolor}`)
              .html(formatDate(closestxValue))
              // .style('font-size', tooltip_fontsize)
              .style("text-align", "center");
          }
        }
        // .style('color', tooltip_data_color)
      }
    };
    const onMouseLeave = (e) => {
      // console.log("Leave");
      xAxisLine.attr("display", "none");
      tooltip_circle_1.style("opacity", 0);
      tooltip_circle_2.style("opacity", 0);
      tooltip_circle_3.style("opacity", 0);
      tooltip_circle_4.style("opacity", 0);
      tooltips.style("display", "none");
      // d3.select(`#data1_${plot_id}`)
      //   .selectAll(`.circle1_${plot_id}`)
      //   .attr("stroke", `${plotView[0].legisocolor11}`)
      //   .attr("fill", `${plotView[0].legisocolor11}`);
      // d3.select(`#data2_${plot_id}`)
      //   .selectAll(`.circle2_${plot_id}`)
      //   .attr("stroke", `${plotView[0].legisocolor12}`)
      //   .attr("fill", `${plotView[0].legisocolor12}`);
      // d3.select(`#data3_${plot_id}`)
      //   .selectAll(`.circle3_${plot_id}`)
      //   .attr("stroke", `${plotView[0].legisocolor13}`)
      //   .attr("fill", `${plotView[0].legisocolor13}`);
      // d3.select(`#data4_${plot_id}`)
      //   .selectAll(`.circle4_${plot_id}`)
      //   .attr("stroke", `${plotView[0].legisocolor14}`)
      //   .attr("fill", `${plotView[0].legisocolor14}`);
    };
    d3.select(`.tooltip-area_${plot_id}`).style("opacity", 0);

    //  .attr('transform', `translate(${my_leg_margin_left - my_leg_margin_right},${dimensions.margin.top - dimensions.margin.bottom / 1.55})`);
    const innerRect = d3
      .select(`#innerRect_${plot_id}`)
      .attr("x", 0)
      .attr("width", dimensions.boundedWidth)
      .attr("y", 0)
      .attr("height", dimensions.boundedHeight)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      .on("mousemove", actPlotData ? onMouseMove : nOP)
      .on("mouseleave", actPlotData ? onMouseLeave : nOP)
      .on("mouseover", actPlotData ? onMouseOver : nOP);
    function nOP() {}

    let xAxisLine = d3
      .select(`#xAxisLine_rect_${plot_id}`)
      .attr("stroke-width", "1px")
      .attr("width", "1px")
      .attr("height", dimensions.boundedHeight)
      .attr("y", 0)
      .attr("x", 0)
      .attr("fill", `${plotView[0].tooltipstroke}`)
      .attr("display", "none");
    let plot_defs = d3
      .select(`#plot_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.boundedWidth)
      .attr("height", dimensions.boundedHeight);
    let graph_defs = d3
      .select(`#graph_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(actPlotData, getXdata))
      .range([0, dimensions.boundedWidth])
      .nice();

    let zoomedScalex = 0;
    zoomedScalex = xScale;
    setResetScalex(xScale);
    // let resetScalex = zoomedScalex;
    setResetScalex(xScale);

    if (currentZoomState) {
      const newXscale = currentZoomState.rescaleX(zoomedScalex);
      zoomedScalex.domain(newXscale.domain());
    }

    const yScale = d3
      .scaleLinear()
      .domain([
        (+plotView[0].lowmap) - (+plotView[0].lowlimitmargin),
        (+plotView[0].highmap) + (+plotView[0].highlimitmargin),
      ])
      .range([dimensions.boundedHeight, 0])
      .nice();

    const xAxis = d3.axisBottom(xScale).ticks(5);
    const yAxis = d3.axisLeft(yScale).ticks(5);
    // .tickSize(-dimensions.boundedWidth);
    const make_y_gridlines = d3
      .axisLeft(yScale)
      .ticks(5)
      .tickSize(-dimensions.boundedWidth)
      .tickFormat("");
    const myLine1 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata1(e)) + 0)
      .curve(d3.curveMonotoneX);
    const myArea1 = d3
      .area()
      .x((e) => zoomedScalex(getXdata(e)))
      .y0(dimensions.boundedHeight)
      .y1((e) => yScale(getYdata1(e)) + 0)
      .curve(d3.curveMonotoneX);

    const myLine2 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata2(e)) + 4)
      .curve(d3.curveMonotoneX);
    const myArea2 = d3
      .area()
      .x((e) => zoomedScalex(getXdata(e)))
      .y0(dimensions.boundedHeight)
      .y1((e) => yScale(getYdata2(e)) + 4)
      .curve(d3.curveMonotoneX);

    const myLine3 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata3(e)) + 8)
      .curve(d3.curveMonotoneX);
    const myArea3 = d3
      .area()
      .x((e) => zoomedScalex(getXdata(e)))
      .y0(dimensions.boundedHeight)
      .y1((e) => yScale(getYdata3(e)) + 8)
      .curve(d3.curveMonotoneX);

    const myLine4 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata4(e)) + 12)
      .curve(d3.curveMonotoneX);
    const myArea4 = d3
      .area()
      .x((e) => zoomedScalex(getXdata(e)))
      .y0(dimensions.boundedHeight)
      .y1((e) => yScale(getYdata4(e)) + 12)
      .curve(d3.curveMonotoneX);
    // // let u=bounds

    let tooltip_circle_1 = d3
      .select(`.tooltip_circle_1_${plot_id}`)
      .attr("r", 5)
      .attr("stroke", `${plotView[0].legisocolor11}`)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      // .attr("storke-width",8)
      .style("opacity", 0);
    let tooltip_circle_2 = d3
      .select(`.tooltip_circle_2_${plot_id}`)
      .attr("r", 5)
      .style("opacity", 0)
      .attr("storke-width", 5)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      .attr("stroke", `${plotView[0].legisocolor12}`);
    let tooltip_circle_3 = d3
      .select(`.tooltip_circle_3_${plot_id}`)
      .attr("r", 5)
      .style("opacity", 0)
      .attr("storke-width", 5)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      .attr("stroke", `${plotView[0].legisocolor13}`);
    let tooltip_circle_4 = d3
      .select(`.tooltip_circle_4_${plot_id}`)
      .attr("r", 5)
      .style("opacity", 0)
      .attr("storke-width", 5)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      .attr("stroke", `${plotView[0].legisocolor14}`);

    d3.select(`#grid_${plot_id}`).call(make_y_gridlines);

    d3.select(`#yAxis_${plot_id}`)
      // .attr("transform", `translate(0,${dimensions.boundedHeight})`)
      .call(yAxis);
    d3.select(`#xAxis_${plot_id}`)
      .attr("transform", `translate(0,${dimensions.boundedHeight})`)
      .call(xAxis);

    const stackGenerator = d3.stack().keys(plotKeys);
    const layers = stackGenerator(actPlotData);
    const extent = [
      0,
      d3.max(layers, (layer) => d3.max(layer, (sequence) => sequence[1])),
    ];
    // console.log(layers);
    // console.log(extent);
    const stackYscale = d3.scaleLinear().domain(extent).range([dimensions]);

    const handleData1Enter = (e) => {
      d3.select(`.circle1_${plot_id}`).raise();
      d3.select(`.line1_${plot_id}`).raise();
      console.log("rasie1");
    };
    const handleData2Enter = () => {
      d3.select(`#data2_${plot_id}`).raise();
    };
    const handleData3Enter = () => {
      d3.select(`#data3_${plot_id}`).raise();
    };
    const handleData4Enter = () => {
      d3.select(`#data4_${plot_id}`).raise();
    };

    d3.select(`#data1_${plot_id}`)
      .selectAll(`.line1_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `line1_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .attr("d", (v) => myLine1(v))
      .attr("fill", "none")
      .style(
        "opacity",
        plotKeys.length - 0 > 0 && legBox["leg1"] === true ? 1 : 0
      )
      .attr("stroke", `${plotView[0].legisocolor11}`)
      .attr("stroke-width", "4px")
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data1_${plot_id}`)
      .selectAll(`.circle1_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `circle1_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .style(
        "opacity",
        plotKeys.length - 0 > 0 && legBox["leg1"] === true ? 1 : 0
      )
      // .attr("stroke", "none")
      .attr("stroke", `${plotView[0].legisocolor11}`)
      .attr("d", (v) => myArea1(v))
      .attr("fill", `${plotView[0].legisocolor11}`)
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data1_${plot_id}`).on("mouseenter", function () {
      d3.select(`#data1_${plot_id}`).raise();
    });

    d3.select(`#data2_${plot_id}`)
      .selectAll(`.line2_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `line2_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .attr("d", (v) => myLine2(v))
      .attr("fill", "none")
      .style(
        "opacity",
        plotKeys.length - 1 > 0 && legBox["leg2"] === true ? 1 : 0
      )
      .attr("stroke", `${plotView[0].legisocolor12}`)
      .attr("stroke-width", "4px")
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data2_${plot_id}`)
      .selectAll(`.circle2_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `circle2_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .style(
        "opacity",
        plotKeys.length - 1 > 0 && legBox["leg2"] === true ? 1 : 0
      )
      // .attr("stroke", "none")
      .attr("stroke", `${plotView[0].legisocolor12}`)
      .attr("d", (v) => myArea2(v))
      .attr("fill", `${plotView[0].legisocolor12}`)
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data2_${plot_id}`).on("mouseenter", function () {
      d3.select(`#data2_${plot_id}`).raise();
    });

    d3.select(`#data3_${plot_id}`)
      .selectAll(`.line3_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `line3_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .attr("d", (v) => myLine3(v))
      .attr("fill", "none")
      .style(
        "opacity",
        plotKeys.length - 2 > 0 && legBox["leg3"] === true ? 1 : 0
      )
      .attr("stroke", `${plotView[0].legisocolor13}`)
      .attr("stroke-width", "4px")
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data3_${plot_id}`)
      .selectAll(`.circle3_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `circle3_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .style(
        "opacity",
        plotKeys.length - 2 > 0 && legBox["leg3"] === true ? 1 : 0
      )
      // .attr("stroke", "none")
      .attr("stroke", `${plotView[0].legisocolor13}`)
      .attr("d", (v) => myArea3(v))
      .attr("fill", `${plotView[0].legisocolor13}`)
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data3_${plot_id}`).on("mouseenter", function () {
      d3.select(`#data3_${plot_id}`).raise();
    });

    d3.select(`#data4_${plot_id}`)
      .selectAll(`.line4_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `line4_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .attr("d", (v) => myLine4(v))
      .attr("fill", "none")
      .style(
        "opacity",
        plotKeys.length - 3 > 0 && legBox["leg4"] === true ? 1 : 0
      )
      .attr("stroke", `${plotView[0].legisocolor14}`)
      .attr("stroke-width", "4px")
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data4_${plot_id}`)
      .selectAll(`.circle4_${plot_id}`)
      .data([actPlotData])
      .join("path")
      .attr("class", `circle4_${plot_id}`)
      .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
      .style(
        "opacity",
        plotKeys.length - 3 > 0 && legBox["leg4"] === true ? 1 : 0
      )
      // .attr("stroke", "none")
      .attr("stroke", `${plotView[0].legisocolor14}`)
      .attr("d", (v) => myArea4(v))
      .attr("fill", `${plotView[0].legisocolor14}`)
      .on("mousemove", onMouseMove)
      .on("mouseleave", onMouseLeave);
    d3.select(`#data4_${plot_id}`).on("mouseover", function () {
      d3.select(`#data4_${plot_id}`).raise();
    });

    d3.select(`#data1_${plot_id}`).style(
      "opacity",
      plotKeys.length - 0 > 0 ? 1 : 0
    );
    d3.select(`#data2_${plot_id}`).style(
      "opacity",
      plotKeys.length - 1 > 0 ? 1 : 0
    );
    d3.select(`#data3_${plot_id}`).style(
      "opacity",
      plotKeys.length - 2 > 0 ? 1 : 0
    );
    d3.select(`#data4_${plot_id}`).style(
      "opacity",
      plotKeys.length - 3 > 0 ? 1 : 0
    );

    d3.select(`#Title_val_${plot_id}`)
      .attr("x", dimensions.boundedWidth / 2)
      .attr("y", dimensions.margin.bottom / 4)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text(`${plotView[0].graphtitle}`)
      .attr("fill", `${plotView[0].titlecolor}`)
      .style("padding", "15px 5px")
      .style("font-weight", 600);

    d3.select(`#xTitle_val_${plot_id}`)
      .attr("x", dimensions.boundedWidth / 2)
      .attr("y", dimensions.height - dimensions.margin.bottom * 1.25)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text(`${plotView[0].xaxistitle}`)
      .attr("fill", `${plotView[0].xlabelcolor}`)
      .style("padding", "15px 15px");
    d3.select(`#yTitle_val_${plot_id}`)
      .attr("x", -dimensions.boundedHeight / 2)
      .attr("y", -dimensions.margin.left / 2 - 14)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text(`${plotView[0].yaxistitle}`)
      .attr("fill", `${plotView[0].ylabelcolor}`)
      .style("padding", "5px 5px")
      .attr("transform", "rotate(-90)");
    d3.select(`#legend_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", 6);

    setLastUpdated(latestDate);
    d3.select(`#last_update_text_1_${plot_id}`)
      .attr("x", -dimensions.margin.left / 1.5)
      .attr("y", dimensions.boundedHeight + dimensions.margin.bottom / 1.15)
      .attr("font-size", "18px")
      .attr("fill", `${plotView[0].misccolor}`)
      .text(`Last Updated: ${lastUpdated}`)
      .style("font-weight", 600);

    d3.select(`tooltip_plot_1_${plot_id}`)
      .style("position", "absolute")
      .style("background", `green`)
      .attr("padding", 6)
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", 10)
      .attr("transform-origin", "center center");

    const getLatestdate = (data) => {
      const formatDate = d3.timeFormat("%Y-%m-%d %I:%M:%S");
      let x = d3.max(data, (d) => {
        return getXdata(d);
      });
      // console.log(x)
      let y = formatDate(x);
      //setLastUpdated(y);
      // console.log(d)
    };
    //getLatestdate(actPlotData);

    const myResetHandle = () => {
      // console.log('reset')
      setCurrentZoomState(resetScalex);
      bounds.call(zoom.transform, d3.zoomIdentity);
    };

    const handleLegendAllow = (e) => {
      let uid=0;
      console.log(e.target.className);
      if(e.target.className==="non_leg") {
        uid=e.target.innerHTML
      }
      else {
        uid = $(`#span_${e.target.id}`).html();
      }
      console.log(uid);
      let r_ = $(`#data${uid}`).css("opacity");
      let r1 = uid.split("_");
      console.log(r1);
      if (legBox[`leg${r1[0]}`] == true) {
        setLegBox({
          ...legBox,
          [`leg${r1[0]}`]: false,
        });
        $(`#legend_text_${uid}`).css("text-decoration", "line-through");
      } else {
        setLegBox({
          ...legBox,
          [`leg${r1[0]}`]: true,
        });
        $(`#legend_text_${uid}`).css("text-decoration", "none");
      }
      //   console.log(r1[0]);
      if (r_ == 1) {
        //d3.select(`#data${e.target.innerHTML}`).style("opacity", 0);
        // console.log("zero");
      } else {
        //d3.select(`#data${e.target.innerHTML}`).style("opacity", 1);
        // console.log("one");
      }
      // console.log(e.target.innerHTML)
      // console.log($(`#data${e.target.innerHTML}`).css('opacity'))
      // d3.select(`#data${e.target.innerHTML}`).style('opacity',0)
    };
    d3.select(`#legend_box_1_${plot_id}`).on("click", handleLegendAllow);
    d3.select(`#legend_box_2_${plot_id}`).on("click", handleLegendAllow);
    d3.select(`#legend_box_3_${plot_id}`).on("click", handleLegendAllow);
    d3.select(`#legend_box_4_${plot_id}`).on("click", handleLegendAllow);

    // function make_y_gridlines() {
    //   return d3.axisLeft(yScale).ticks(yScale.ticks().length);
    // }
    let grids = d3.select(`#grid_${plot_id}`);
    // console.log(grids)
    let ticks = grids.selectAll(".tick");
    d3.selection.prototype.first = function () {
      return d3.select(this[0][0]);
    };
    d3.selection.prototype.last = function () {
      var last = this.size() - 1;
      return d3.select(this[0][last]);
    };
    let line_ = ticks.size();
    console.log(line_);
    d3.select(`#yAxis_${plot_id}`).attr("color", `blue`);
    let grid1 = d3.select(`#yAxis_${plot_id}`);
    let ticks1 = grid1.selectAll(".tick");
    console.log(ticks1.size());

    //GRID LINES CONTROL
    ticks.selectAll("line").attr("stroke", `${plotView[0].isomisc1}`);
    let j = ticks.select("line");

    d3.selection.prototype.first = function () {
      return d3.select(this[0][0]);
    };
    d3.selection.prototype.last = function () {
      var last = this.size() - 1;
      return d3.select(this[0][last]);
    };

    // console.log(ticks.selectAll('line:first-of-type line'))
    ticks.select("text").style("opacity", 0);
    let e = ticks.select("line");
    let r = e._groups[0][0];
    //X-AXIS COLOR
    // grids
    //   .select(".tick")
    //   .select("line")
    //   .attr("stroke", "red")
    //   .style("opacity", 1);
    // console.log(ticks.select('line'))
    grids = d3
      .select(`#xAxis_${plot_id}`)
      .attr("color", `${plotView[0].xaxislinecolor}`);
    // console.log(grids)
    ticks = $(`#grid_${plot_id} .tick`);
    // console.log(ticks);
    if (ticks) {
      if (line_ >= ticks1.size()) {
        // $(`#grid_${plot_id}:last-child`).attr('id', `grid_las_${plot_id}`)
        // console.log(ticks[ticks.length - 1]);
        // if(updateRef.current===false)
        // ticks[ticks.length - 1].remove();
        // console.log(
        //   $(`#grid_${plot_id} .tick:last-child`)
        // );
      }
    }
    //Y- AXIS COLOR
    d3.select(`#yAxis_${plot_id}`).attr(
      "color",
      `${plotView[0].yaxislinecolor}`
    );
    // console.log(ticks)
    // ticks.select("line").style("opacity", 1).attr("stroke", `red`);
    // ticks.select("text").style("opacity", 1).style("color", `red`);
    // grids.attr('stroke','red')
    // d3.select(`.grid_${plot_id}`)
    // .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
    // .attr('stroke','red')
    // .call(make_y_gridlines())
    // .tickSize(-dimensions.boundedWidth)
    // //.tickValues(yMax/2)
    // .tickFormat("")

    // const rstdiv=d3.select(`reset_zoom_${plot_id}`)
    document.getElementById(`reset_zoom_${plot_id}`).onclick = myResetHandle;
    // rstdiv.on('click',myResetHandle)

    var exten = [
      [-10, dimensions.margin.top],
      [dimensions.width, dimensions.height],
    ];

    let zoom = d3
      .zoom()
      // .scaleExtent([0.5, 40])
      .translateExtent(exten)
      .extent(exten)
      .on("zoom", zoomed);
    function zoomed({ transform }) {
      const zoomState = transform;
      setCurrentZoomState(zoomState);
      //   console.log(transform);
      // const zoomState=d3.zoomTransform(bounds.node())
      // console.log(zoomState)
      // const newScalex=transform.rescaleX(xScale)
      // console.log(newScalex.domain())
      // zoomedScalex = transform.rescaleX(xScale);
      // zoomedScalex.domain(zoomedScalex_.domain())
      // d3.select(`#xAxis_${plot_id}`)
      //   .attr("transform", `translate(0,${dimensions.boundedHeight})`)
      //   .call(xAxis);
    }
    dragItem.current = zoom;
    bounds.call(zoom);
    rstZoomRef.current = bounds;
    var ActionsEnum = {
      None: 0,
      LeftResize: 1,
      TopResize: 2,
      RightResize: 3,
      BottomResize: 4,
      TopLeftResize: 5,
      BottomLeftResize: 6,
      TopRightResize: 7,
      BottomRightResize: 8,
      Move: 9,
    };
    // console.log('Problem2')
  };

  const createEmptyGraph = async () => {
    dimensions.boundedWidth =
      dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.boundedHeight =
      dimensions.height - dimensions.margin.top - dimensions.margin.bottom;
    let my_leg_margin_left = dimensions.margin.left;
    let my_leg_margin_right = dimensions.margin.right;
    let selection = d3.select(ref.current);
    const svg = selection
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .style("background-color", `${plotView[0].contbgcolor}`);
    let bounds = d3
      .select(`#bounds_${plot_id}`)
      .attr(
        "transform",
        `translate(${my_leg_margin_left},${dimensions.margin.top})`
      );

    const tooltips = d3
      .select(`#tooltips_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", "20px")
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", -10)
      .attr("transform-origin", "center center")
      .attr("width", "200px")
      .style("background", `#d0d3dbB8`);

    d3.select(`.tooltip-area_${plot_id}`).style("opacity", 0);

    const innerRect = d3
      .select(`#innerRect_${plot_id}`)
      .attr("x", 0)
      .attr("width", dimensions.boundedWidth)
      .attr("y", 0)
      .attr("height", dimensions.boundedHeight)
      .attr("fill", `${plotView[0].plotbgcolor}`);
    let xAxisLine = d3
      .select(`#xAxisLine_rect_${plot_id}`)
      .attr("stroke-width", "1px")
      .attr("width", "1px")
      .attr("height", dimensions.boundedHeight)
      .attr("y", 0)
      .attr("x", 0)
      .attr("fill", `black`)
      .attr("display", "none");
    let plot_defs = d3
      .select(`#plot_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.boundedWidth)
      .attr("height", dimensions.boundedHeight);
    let graph_defs = d3
      .select(`#graph_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    d3.select(`#Title_val_${plot_id}`)
      .attr("x", dimensions.boundedWidth / 2)
      .attr("y", dimensions.margin.bottom / 4)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text("Title")
      .style("padding", "15px 5px");

    d3.select(`#xTitle_val_${plot_id}`)
      .attr("x", dimensions.boundedWidth / 2)
      .attr("y", dimensions.height - dimensions.margin.bottom * 1.25)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text("Time Line")
      .style("padding", "15px 15px");
    d3.select(`#yTitle_val_${plot_id}`)
      .attr("x", -dimensions.boundedHeight / 2)
      .attr("y", -dimensions.margin.left / 2 - 2.5)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text(`${title}`)
      .style("padding", "5px 5px")
      .attr("transform", "rotate(-90)");
    d3.select(`#legend_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", 6);

    setLastUpdated(latestDate);
    d3.select(`#last_update_text_1_${plot_id}`)
      .attr("x", -dimensions.margin.left / 1.5)
      .attr("y", dimensions.boundedHeight + dimensions.margin.bottom / 1.15)
      .attr("font-size", "18px")
      .text(`Last Updated: ${lastUpdated}`);
    d3.select(`tooltip_plot_1_${plot_id}`)
      .style("position", "absolute")
      .style("background", `green`)
      .attr("padding", 6)
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", 10)
      .attr("transform-origin", "center center");
    d3.select(`#data1_${plot_id}`).style("opacity", 0);
    d3.select(`#data2_${plot_id}`).style("opacity", 0);
    d3.select(`#data3_${plot_id}`).style("opacity", 0);
    d3.select(`#data4_${plot_id}`).style("opacity", 0);
    // d3.select(`#data1_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
    // d3.select(`#data2_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
    // d3.select(`#data3_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
    // d3.select(`#data4_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
  };

  // useEffect(() => {

  //   if(data_.length) {
  //     console.log('geretyk')
  //     createGraph();
  //   }
  // }, []);
  useEffect(() => {
    //func()
    if (plotData) {
      setActPlotData([]);
      // if(update===1)
      setActPlotData(plotData);
      // setActPlotData_(plotData);
      // console.log(plotData)
      //if(firstUpdate===1) {
      //console.log('Update')
      //if(storeData.firstUpdate===0) {
      //dispatch(updateFirstPlot(1))
      //console.log('PLOYT')
      //createGraph();
      //}
      //}
      // setUpdate(1)
      // console.log(update)
    } else if (validData === "0") {
      createEmptyGraph();
    }

    if (actPlotData && update === 1) {
      // setFirstUpdate(1);
      if (plotData === actPlotData) {
        // console.log('EQUAL')
      }
      //setUpdate(0)
      //if(storeData.firstPlot===1) {
      //if(storeData.firstPlot===1) {
      if (actPlotData.length > 0 && validData === "1") {
        // console.log(validData);
        if(plot_id>0) {
            createGraph();
        }
      } else if (validData === "0") {
        // console.log(plot_id)
        // console.log("empty");
        createEmptyGraph();
      }
      //}
      // dispatch(updateFirstPlot(0))
      //}
      // toolRef.current.click()
      // setTooltipAllow(true)
    }
    // else if(firstUpdate===1) {
    //   console.log('firstUpdate')
    //   //if(storeData.firstUpdate===0) {
    //     //dispatch(updateFirstPlot(1))
    //     //console.log('PLOYT')
    //     createGraph();
    //   //}
    // }
    // setUpdate(1)
    // if(plotData) {
    //   if(plotData.length===actPlotData.length) {
    //     //   console.log('fsomfosdm')
    //        createGraph();
    //     }
    // }
    updateFetch.current = 1;
    // console.log(plotData[0].datedevice);
  }, [
    width,
    height,
    currentZoomState,
    tooltipAllow,
    lastUpdated,
    plotData,
    update,
    actPlotData,
    validData,
    plotView,
    legBox,
  ]);

  useEffect(() => {
    if (latestData && actPlotData) {
      if (latestData.length > 0) {
        // console.log(latestData)
        // console.log(latestData)
        setActPlotData([...actPlotData, ...latestData]);
        setFirstUpdate(0);
      } else {
        setLastUpdated(latestDate);
      }
      // if(latestDate) {
      //   // console.log(latestDate)
      //   setLastUpdated(latestDate)
      // }
    }
  }, [latestData, latestDate]);

  useEffect(() => {
    if (updateRef.current === false) {
      // setUpdate(1)
      // setFirstUpdate(1)
      //setTimeout(() => {
      setFirstUpdate(1);
      setUpdate(1);
      // console.log(plotView[0])
      // setActPlotData([]);
      // setActPlotData(storeData.plotFirstData)
      // console.log('FirstUpdate')
      // console.log(actPlotData)
      //createGraph()
      //}, 5000);
      return () => {
        updateRef.current = true;
        // d3.select(`#main_plot_${plot_id}`).remove()
        // dispatch(updateFirstPlot(1));
      };
    }
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    // $('#plot').remove()
  };

  const handleDatePicker = (value, states) => {
    let str = 0;
    let newDate = new Date(value[0]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1
    }-${newDate.getDate()}`;
    setFromDate(str);
    console.log(fromDate);
    newDate = new Date(value[1]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1
    }-${newDate.getDate()}`;
    // console.log(str);
    setToDate(str);
    console.log(toDate);
  };

  const handleZoomReset = (e) => {
    console.log(e);
    setCurrentZoomState(resetScalex);
    // rstZoomRef.current.call(d3.zoomIdentity)
    // zoomedScalex.domain(newXscale.domain())
  };

  const onDragStart = (e) => {
    // e.preventDefault();
    console.log(e.target.id);
    console.log("start");
    // console.log(i)
  };
  const onDragEnter = (e) => {
    // e.preventDefault();
    console.log(e.target.className.baseVal);
    console.log("enter");
    // console.log(i)
  };
  const onDragEnd = (e) => {
    // e.preventDefault();
    console.log(e.target.id);
    console.log("end");
    // console.log(i)
  };
  const handleTooltipAllow = (e) => {
    // if(tooltipAllow===false) setTooltipAllow(true)
    setTooltipAllow(e.target.checked);
    console.log(e.target.checked);
  };

  let optionStyle = {
    // transform: `translateY(-${height - 2}px)`,
    fontSize: "27px",
    marginRight: "18px",
    textAlign: "center",
    color: `${plotView[0].titlecolor}`,
    fontWeight: 600,
    marginBottom: "10px",
  };
  let optionStyle_ = {
    // transform: `translateY(-66px)`,
    fontSize: "18px",
    marginRight: "18px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    width: width,
    justifyItems: "center",
    justifyContent: "space-evenly",
    alignItems: "center",
    fontWeight: 600,
    flexWrap: "wrap",
    marginBottom: "15px",
  };
  let avg_min_max = {
    color: `${plotView[0].misccolor}`,
    marginLeft: "10px",
    marginRight: "10px",
    // marginBottom: "15px",
    marginTop: "15px",
  };

  return (
    // <div className="plot_container">
    <>
      {/* <div id={`plot_1_${plot_id}`} style={plot} draggable={true}> */}
      <div
        id={`main_plot_${plot_id}`}
        style={{
          zIndex: 5,
          // background: `${plotView[0].contbgcolor}`,
          borderRadius: "5px",
        }}
      >
        <div id={`legend_plot_1_${plot_id}`} style={legend_plot_1}>
          {plotKeys && plotKeys.length - 0 > 0 ? (
            <div className="legend">
              <div id={`legend_box_1_${plot_id}`} style={legend_box_1}>
              <span
                  id={`span_legend_box_1_${plot_id}`}
                  style={{ opacity: 0 }}
                  className="non_leg"
                >
                  {`1_${plot_id}`}
                </span>
              </div>
              <p id={`legend_text_1_${plot_id}`} style={legend_text_1}>
                {plotView[0].legisotext11}
              </p>
            </div>
          ) : (
            ""
          )}
          {plotKeys && plotKeys.length - 1 > 0 ? (
            <div className="legend">
              <div id={`legend_box_2_${plot_id}`} style={legend_box_2}>
              <span
                  id={`span_legend_box_2_${plot_id}`}
                  style={{ opacity: 0 }}
                  className="non_leg"
                >
                  {`2_${plot_id}`}
                </span>
              </div>
              <p id={`legend_text_2_${plot_id}`} style={legend_text_2}>
                {plotView[0].legisotext12}
              </p>
            </div>
          ) : (
            ""
          )}
          {plotKeys && plotKeys.length - 2 > 0 ? (
            <div className="legend">
              <div id={`legend_box_3_${plot_id}`} style={legend_box_3}>
              <span
                  id={`span_legend_box_3_${plot_id}`}
                  style={{ opacity: 0 }}
                  className="non_leg"
                >
                  {`3_${plot_id}`}
                </span>
              </div>
              <p id={`legend_text_3_${plot_id}`} style={legend_text_3}>
                {plotView[0].legisotext13}
              </p>
            </div>
          ) : (
            ""
          )}
          {plotKeys && plotKeys.length - 3 > 0 ? (
            <div className="legend">
              <div id={`legend_box_4_${plot_id}`} style={legend_box_4}>
              <span
                  id={`span_legend_box_4_${plot_id}`}
                  style={{ opacity: 0 }}
                  className="non_leg"
                >
                  {`4_${plot_id}`}
                </span>
              </div>
              <p id={`legend_text_4_${plot_id}`} style={legend_text_4}>
                {plotView[0].legisotext14}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>

        <svg
          className={`plot_container_${plot_id}`}
          ref={ref}
          style={plot_container}
        >
          <g id={`bounds_${plot_id}`}>
            {/* <g className={`grid_${plot_id}`}></g> */}
            <rect id={`innerRect_${plot_id}`}></rect>
            <g id={`xAxisLine_${plot_id}`}>
              <rect id={`xAxisLine_rect_${plot_id}`}></rect>
            </g>
            <defs>
              <clipPath id={`plot_clipPath_${plot_id}`}>
                <rect id={`plot_clipPath_rect_${plot_id}`}></rect>
              </clipPath>
            </defs>
            <g id={`data1_${plot_id}`}></g>
            <g id={`data2_${plot_id}`}></g>
            <g id={`data3_${plot_id}`}></g>
            <g id={`data4_${plot_id}`}></g>
            {/* <g id={`stack_data_${plot_id}`}></g> */}
            <g id={`grid_${plot_id}`}></g>
            <g id={`yAxis_${plot_id}`}></g>
            <g
              id={`xAxis_${plot_id}`}
              clipPath={`url(#plot_clipPath_${plot_id})`}
            ></g>
            <g>
              <circle
                className={`tooltip_circle_1_${plot_id}`}
                style={tooltipCircles}
              ></circle>
              <circle
                className={`tooltip_circle_2_${plot_id}`}
                style={tooltipCircles}
              ></circle>
              <circle
                className={`tooltip_circle_3_${plot_id}`}
                style={tooltipCircles}
              ></circle>
              <circle
                className={`tooltip_circle_4_${plot_id}`}
                style={tooltipCircles}
              ></circle>
            </g>
            <g id={`Title_${plot_id}`}>
              <text id={`Title_val_${plot_id}`} style={Title_val}></text>
            </g>
            <g id={`xTitle_${plot_id}`}>
              <text id={`xTitle_val_${plot_id}`} style={xTitle_val}></text>
            </g>
            <g id={`yTitle_${plot_id}`}>
              <text id={`yTitle_val_${plot_id}`}></text>
            </g>
            <g id={`last_update_1_${plot_id}`} style={{ margin: "5px 5px" }}>
              <a href="#">
                <text
                  id={`last_update_text_1_${plot_id}`}
                  style={{ width: "5px 5px" }}
                ></text>
              </a>
            </g>
            {/* <g className={`tooltip-area_${plot_id}`}>
            <text className={`tooltip-area__text_${plot_id}`}>aas</text>
          </g> */}
          </g>
          <defs>
            <clipPath id={`graph_clipPath_${plot_id}`}>
              <rect id={`graph_clipPath_rect_${plot_id}`}></rect>
            </clipPath>
          </defs>
        </svg>
        <div>
          <p className={`options_plot_${plot_id}`} style={options_plot}>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_${plot_id}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_${plot_id}`}
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Options
            </button>
          </p>
        </div>
        <div style={optionStyle_}>
          <div
            className="collapse"
            id={`collapseExample_${plot_id}`}
            style={collapse_plot}
          >
            <div className={`card card-body bottom_plot`}>
              {/* <div className="bottom_plot"> */}
              <DateRangePicker
                // className="datepicker1"
                placement="auto"
                onChange={datePicker}
                popupstyle={{
                  // position: "relative",
                  marginRight: "-50px",
                }}
                onOpen={() =>
                  handleOk(`dateFrom${plot_id}`, `dateTrom${plot_id}`, plot_id)
                }
                id={`dateFrom${plot_id}`}
                size={"md"}
                // style={{fontSize:'8px'}}
              ></DateRangePicker>
              <button
                type="button"
                className="bottom_plot_elements btn btn-primary btn-sm"
                id={`reset_zoom_${plot_id}`}
              >
                Reset Zoom
              </button>
              <div className="form-check bottom_plot_elements">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={tooltipAllow}
                  id="flexCheckDefault"
                  onClick={handleTooltipAllow}
                  ref={toolRef}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Tool Tip
                </label>
              </div>
            </div>
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 0 > 0 && actPlotData
              ? `${plotView[0].keytext1}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[0]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 0 > 0 && actPlotData
              ? `${plotView[0].keytext1}_Max : ${d3.max(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[0]]);
                  })
                )}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 0 > 0 && actPlotData
              ? `${plotView[0].keytext1}_Min : ${d3.min(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[0]]);
                  })
                )}`
              : ""}
          </div>

          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 1 > 0 && actPlotData
              ? `${plotView[0].keytext2}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[1]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 1 > 0 && actPlotData
              ? `${plotView[0].keytext2}_Max : ${d3.max(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[1]]);
                  })
                )}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 1 > 0 && actPlotData
              ? `${plotView[0].keytext2}_Min : ${d3.min(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[1]]);
                  })
                )}`
              : ""}
          </div>

          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 2 > 0 && actPlotData
              ? `${plotView[0].keytext3}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[2]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 2 > 0 && actPlotData
              ? `${plotView[0].keytext3}_Max : ${d3.max(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[2]]);
                  })
                )}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 2 > 0 && actPlotData
              ? `${plotView[0].keytext3}_Min : ${d3.min(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[2]]);
                  })
                )}`
              : ""}
          </div>

          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 3 > 0 && actPlotData
              ? `${plotView[0].keytext4}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[3]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 3 > 0 && actPlotData
              ? `${plotView[0].keytext4}_Max : ${d3.max(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[3]]);
                  })
                )}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && plotKeys.length - 3 > 0 && actPlotData
              ? `${plotView[0].keytext4}_Min : ${d3.min(
                  actPlotData.map((e) => {
                    return mapValues(e[plotKeys[3]]);
                  })
                )}`
              : ""}
          </div>
        </div>
      </div>
      {/* <div className="datepicker">
        <DateRangePicker className="datepicker1" onChange={handleDatePicker} />
        </div> */}
      <div id={`tooltips_plot_1_${plot_id}`}></div>

      {/* </div> */}
    </>
  );
};

export default AreaGroupPlot;
