import React, { useContext } from 'react'
import DashContext from '../context/dash/dashContext'
import Navbar from './navbar/Navbar'
import Alert from './alert/Alert'
import Sidebar from './navbar/Sidebar'

const Init = () => {
    const{alert}=useContext(DashContext)
  return (
    <>
      <Navbar></Navbar>
      {/* <Sidebar></Sidebar> */}
      <Alert alert={alert}></Alert>
    </>
  )
}

export default Init
