import React, { useContext, useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import * as d3 from "d3";
import { CSVReader } from "react-papaparse";
import data from "./uid_1_data(2).csv";
import $ from "jquery";
import "./simpleplot.scss";
import { DateRangePicker } from "rsuite";
// import "react-awesome-popover/build/index.css";
import { Button, Popover, Typography } from "@mui/material";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getImeiData } from "../help";
import zIndex from "@mui/material/styles/zIndex";
import { act } from "@testing-library/react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFirstPlot,
  addPlotFirstData,
} from "../../redux/features/postSlice";
import Speedometer, {
  Background,
  Arc,
  Needle,
  Progress,
  Marks,
  Indicator,
  DangerPath,
} from "react-speedometer";

const SpeedoGroupPlot = (props) => {
  const storeData = useSelector((state) => {
    return state.app;
  });
  const dispatch = useDispatch();
  const updateFetch = useRef(0);
  const {
    plot_id,
    title,
    width,
    height,
    imei,
    datePicker,
    handleOk,
    plotData,
    plotKeys,
    plot_,
    highLimit,
    lowLimit,
    latestData,
    latestDate,
    validData,
    plotView,
    lastRecordData,
  } = props;
  const [SvgData, setSvgData] = useState([25, 30, 45, 60, 20]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actPlotData, setActPlotData] = useState();
  const [actPlotData_, setActPlotData_] = useState();
  const [radius_, setRadius_] = useState(0);
  const [legBox, setLegBox] = useState({
    leg1: true,
    leg2: true,
    leg3: true,
    leg4: true,
  });
  const [currKey, setCurrKey] = useState(plotKeys[0]);
  // const { status, data, isFetching } = useQuery(
  //   ["imeiData"],
  //   () =>
  //     getImeiData(imei),
  //   {
  //     onSuccess: (e) => {
  //       // console.log(e)
  //       if (updateFetch.current===1) {
  //         updateFetch.current=0;
  //         console.log(e)
  //         // dispatch(updateSeqProgams(0));
  //       }
  //       //console.log(storeData)
  //       //settime()
  //     },
  //   },
  // );

  const dragItem = useRef(null);
  const dragOver = useRef(null);

  const rstZoomRef = useRef();

  const toolRef = useRef();

  const updateRef = useRef(false);

  const [currentZoomState, setCurrentZoomState] = useState();

  const [resetScalex, setResetScalex] = useState();

  const [lastUpdated, setLastUpdated] = useState();

  const [tooltipAllow, setTooltipAllow] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [update, setUpdate] = useState(0);
  const [firstUpdate, setFirstUpdate] = useState(0);

  const gaugeData = [
    ["Label", "Value"],
    ["Memory", 80],
  ];

  const plot = {
    margin: "10px 10px",
    width: `600px`,
    maxWidth: "600px",
    resize: "both",
    cursor: "move",
  };
  const bottom_plot = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "center",
    border: "2px solid rgb(17, 124, 154)",
    borderRadius: "10px",
    margin: "3px 3px",
    padding: "3px 3px",
    backgroundColor: "aliceblue",
    maxWidth: "600px",
    textAlign: "center",
    zIndex: 1,
  };
  const plot_container = {
    // background-color: gray
    borderRadius: "10px",
  };
  const xTitle_val = {
    transform: "translateY(-20px)",
  };
  const Title_val = {
    transform: "translateY(-40px)",
    margin: "10px 10px",
  };
  const datepicker = {
    // text-align: center;
    position: "relative",
  };
  const formCheck = {
    paddingTop: "3px",
  };
  const datepicker1 = {
    position: "relative",
    marginTop: "-36px",
    marginLeft: "270px",
  };
  const popover = {
    zIndex: "-1",
  };
  const options_plot = {
    display: "flex",
    justifyContent: "end",
    marginRight: "10px",
    // height: 20px;
    marginTop: "-40px",
  };
  const collapse_plot = {
    width: "480px",
    marginLeft: "-13px",
  };
  const legend_plot_1 = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: `${width}px`,
    margin: "10px 5px",
    marginTop: "5px",
  };
  const legend = {
    display: "flex",
    // flex-direction: column;
    justifyContent: "center",
    alignItems: "center",
    margin: "5px 5px",
  };
  const legend_box_1 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor11}`,
    color: `${plotView[0].legisocolor11}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_1 = {
    marginRight: "3px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor11}`,
  };
  const legend_box_2 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor12}`,
    color: `${plotView[0].legisocolor12}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_2 = {
    marginRight: "4px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor12}`,
  };
  const legend_box_3 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor13}`,
    color: `${plotView[0].legisocolor13}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_3 = {
    marginRight: "4px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor13}`,
  };
  const legend_box_4 = {
    cursor: "pointer",
    width: "38px",
    backgroundColor: `${plotView[0].legisocolor14}`,
    color: `${plotView[0].legisocolor14}`,
    marginRight: "3px",
    borderRadius: "8px",
  };
  const legend_text_4 = {
    marginRight: "3px",
    fontWeight: "600",
    color: `${plotView[0].legisotextcolor14}`,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let dimensions = {
    width: width,
    height: height,
    margin: {
      top: 80,
      bottom: 50,
      right: 10,
      left: 10,
    },
    padding: {
      titleX: 15,
      titleY: 30,
      xLabel: -1,
      yLabel: "-1.3em",
    },
  };

  let tooltipCircles = {
    strokeWidth: "2.5px",
  };

  let data_ = 0;
  const ref = useRef();
  var timeFormat = d3.timeParse("%Y-%m-%d %H:%M:%S");
  const csvUrl = "uid_1_data(2).csv";
  let parseRow = (d) => {
    let dig_data;
    d.dtm = timeFormat(d.dtm);
    //d.dtm=timechg(d.dtm)
    //console.log(d.dtm)

    d.sr = +d["sr"];

    d.analog_data = +d["analog_data"];

    d.dig_data = JSON.parse(d.digital_data);
    d.di1 = +d.dig_data["di1"];
    d.di2 = +d.dig_data["di2"];
    d.di3 = +d.dig_data["di3"];
    d.di4 = +d.dig_data["di4"];
    delete d.digital_data;
    delete d.dig_data;
    // console.log(d.dig_data['di1'])

    d.iso_4um = +d["iso-4um"];
    d.iso_6um = +d["iso-6um"];
    d.iso_14um = +d["iso-14um"];
    d.iso_21um = +d["iso-21um"];
    delete d["iso-4um"];
    delete d["iso-6um"];
    delete d["iso-14um"];
    delete d["iso-21um"];

    d.nas_6_14um = +d["nas-6-14um"];
    d.nas_14_21um = +d["nas-14-21um"];
    delete d["nas-6-14um"];
    delete d["nas-14-21um"];

    d.node_id = +d.node_id;

    d.pulse_count = +d.pulse_count;

    d.reg_len = +d.reg_len;

    d.sae_4um = +d["sae-4um"];
    d.sae_6um = +d["sae-6um"];
    d.sae_14um = +d["sae-14um"];
    d.sae_21um = +d["sae-21um"];
    delete d["sae-4um"];
    delete d["sae-6um"];
    delete d["sae-14um"];
    delete d["sae-21um"];

    d.seq = +d.seq;

    d.sig = +d.sig;

    d.temperature = +d.temperature;
    return d;
  };
  const func = async () => {
    data_ = await d3.csv(data, parseRow);
    console.log(data_);
  };
  const myData = [25, 30, 45, 60, 20];
  const createGraph = async () => {
    data_ = await d3.csv(data, parseRow);
    // set the dimensions and margins of the graph
    const mydata = (d) => {
      return d.analog_data;
    };
    // console.log(data_);

    dimensions.boundedWidth =
      dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.boundedHeight =
      dimensions.height - dimensions.margin.top - dimensions.margin.bottom;
    let my_leg_margin_left = dimensions.margin.left;
    let my_leg_margin_right = dimensions.margin.right;
    let selection = d3.select(ref.current);

    let margin_h = Math.max(dimensions.margin.left, dimensions.margin.right);
    let margin_v = Math.max(dimensions.margin.top, dimensions.margin.bottom);

    let my_per = [25, 25, 25, 25];
    let my_color = ["#0000FF", "#00FF00", "#FF0000", "#FF00FF"];

    let radius = Math.min(dimensions.boundedHeight, dimensions.boundedWidth);
    console.log(radius, dimensions.boundedHeight, dimensions.boundedWidth);
    const pie_color = d3.scaleOrdinal().range(my_color);
    let colors = my_per;
    var pie = d3
      .pie()
      .startAngle((-1 * Math.PI) / 2)
      .endAngle((1 * Math.PI) / 2)
      .sort(null)
      .value(function (colors) {
        let i = 0;
        // return 100 / (my_color.length);
        // console.log('colors start')
        // console.log(colors)
        // console.log('colors end')
        return colors;
      });
    var arcGenerator = d3
      .arc()
      .innerRadius(dimensions.boundedWidth / 2)
      .outerRadius(dimensions.boundedHeight)
      .cornerRadius(0)
      .padAngle(0.01);
    const svg = selection
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .style("background-color", `${plotView[0].contbgcolor}`);

    let bounds = d3
      .select(`#bounds_${plot_id}`)
      .attr(
        "transform",
        `translate(${my_leg_margin_left},${dimensions.margin.top})`
      );
    d3.select(`#arc_plot_${plot_id}`).attr(
      "transform",
      `translate(${dimensions.boundedWidth / 2},${dimensions.height / 1.5})`
    );
    d3.selectAll(`#arc_plot_${plot_id} path`).remove();
    d3.select(`#arc_plot_${plot_id}`)
      .selectAll(`.arc_${plot_id}`)
      .attr("class", `arc_${plot_id}`)
      .data(
        pie([
          plotView[0].legisoper11,
          plotView[0].legisoper12,
          plotView[0].legisoper13,
          plotView[0].legisoper14,
        ])
      )
      .join("path")
      .attr("d", arcGenerator)
      .style("fill", function (d, i) {
        console.log("my color start");
        console.log(my_color[i]);
        console.log("my color end");
        return my_color[i];
      });

    const getXdata = (v) => timeFormat(v.datedevice);
    const getYdata1 = (v) => {
      if (plotKeys.length > 0) {
        return v[plotKeys[0]];
      } else return 0;
    };
    const getYdata2 = (v) => {
      if (plotKeys.length - 1 > 0) {
        return v[plotKeys[1]];
      } else return 0;
    };
    const getYdata3 = (v) => {
      if (plotKeys.length - 2 > 0) {
        return v[plotKeys[2]];
      } else return 0;
    };
    const getYdata4 = (v) => {
      if (plotKeys.length - 3 > 0) {
        return v[plotKeys[3]];
      } else return 0;
    };

    const onMouseOver = () => {
      // d3.select(`.tooltip-area_${plot_id}`).style("opacity", 1);
    };

    const tooltips = d3
      .select(`#tooltips_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", "20px")
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", -10)
      .attr("transform-origin", "center center")
      .attr("width", "200px")
      .style("background", `${plotView[0].tooltipbgcolor}B8`);

    const onMouseMove = (event, d) => {
      // console.log("Move");
      // d3.select('#data1').raise();
      let u_cnt = 0;
      let sel_index = 0;
      // while (u_cnt < my_key.length) {
      //     if (`${base_id}_${my_scatter_class[u_cnt]}` == event.target.className.baseVal) {
      //         change_yAccessor_Key(my_key, u_cnt)
      //         sel_index = u_cnt;
      //     }
      //     u_cnt++;
      // }
      // console.log(event.target.className);
      // console.log(event.target);
      const mousePosition = d3.pointer(event);
      const hoverDate = zoomedScalex.invert(mousePosition[0]);
      const getDistanceFromHoverDate = (d) => Math.abs(getXdata(d) - hoverDate);
      const closestIndex = d3.scan(
        actPlotData,
        (a, b) => getDistanceFromHoverDate(a) - getDistanceFromHoverDate(b)
      );
      const closestDataPoint = actPlotData[closestIndex];
      // console.log(closestDataPoint);

      const closestxValue = getXdata(closestDataPoint);
      let closestyValue = getYdata1(closestDataPoint);

      // console.log(closestxValue);
      // console.log(closestyValue);

      const formatDate = d3.timeFormat("%d/%m/%Y %I:%M:%S %p");
      let x = zoomedScalex(closestxValue);
      const y = yScale(closestyValue);
      if (
        x <= dimensions.boundedWidth &&
        x >= 0 &&
        tooltipAllow === true &&
        (legBox["leg1"] === true ||
          legBox["leg2"] === true ||
          legBox["leg3"] === true ||
          legBox["leg4"] === true)
      ) {
        // console.log(closestyValue);
        // tooltip.style(
        //     "transform",
        //     `translate(` + `calc( 10% + ${x}px),` + `calc(50% + ${y}px)` + `)`
        // );

        // tooltip.style('opacity', 1)
        let temp_data = [];
        temp_data[0] = {
          data: 0,
          color: "red",
        };
        let tool_data = [];
        //console.log('obj: ')
        //console.log(temp_data.data)
        let tool_color = [];
        let tooltip_y_pt = 0;
        let tooltip_x_pt = 0;
        let tooltip_y1_valid = 0;
        let tooltip_y2_valid = 0;
        let tooltip_y3_valid = 0;
        let tooltip_y4_valid = 0;
        tooltip_y1_valid = yScale(closestyValue);
        tooltip_y1_valid = closestyValue;

        //if ($(`#data1_${plot_id}`).css("opacity")) {
        tooltip_circle_1
          .style(
            "opacity",
            plotKeys.length - 0 > 0 && legBox["leg1"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue));
        //}

        closestyValue = getYdata2(closestDataPoint);
        tooltip_y2_valid = closestyValue;
        // tooltip_y2_valid = yScale(closestyValue);
        tooltip_circle_2
          .style(
            "opacity",
            plotKeys.length - 1 > 0 && legBox["leg2"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue) + 4);

        closestyValue = getYdata3(closestDataPoint);
        tooltip_y3_valid = closestyValue;
        // tooltip_y3_valid = yScale(closestyValue);
        tooltip_circle_3
          .style(
            "opacity",
            plotKeys.length - 2 > 0 && legBox["leg3"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue) + 8);

        closestyValue = getYdata4(closestDataPoint);
        tooltip_y4_valid = closestyValue;
        // tooltip_y4_valid = yScale(closestyValue);
        tooltip_circle_4
          .style(
            "opacity",
            plotKeys.length - 3 > 0 && legBox["leg4"] === true ? 1 : 0
          )
          .attr("cx", xScale(closestxValue))
          .attr("cy", yScale(closestyValue) + 12);

        // tooltip_circle_2.style('opacity', 1)
        // .attr("cx", xScale(closestxValue))
        // .attr("cy", yScale(closestyValue))
        if (
          legBox["leg1"] === true ||
          legBox["leg2"] === true ||
          legBox["leg3"] === true ||
          legBox["leg4"] === true
        ) {
          if (plotKeys.length === 1) {
            if (legBox["leg1"] === true) {
              xAxisLine.attr("display", null);
              xAxisLine.attr("x", xScale(closestxValue));
            }
          } else if (plotKeys.length !== 0) {
            xAxisLine.attr("display", null);
            xAxisLine.attr("x", xScale(closestxValue));
          }
        }

        let elem = document.getElementById(`main_Plot`);
        // console.log(elem.className)
        let value_x;
        // console.log(value_x=parseInt(getComputedStyle(document.querySelector(`.${elem.className}`)).marginLeft));
        let r = 0;
        if (event.pageX > width) {
          r = event.pageX - width;
        } else {
          r = event.pageX;
        }
        let tool = event.layerY;
        //console.log(xScale(closestxValue))
        //console.log(event.pageX)
        if (xScale(closestxValue) > dimensions.boundedWidth - 150) {
          x = x - 230;
        }
        tooltips
          .html("")
          .style("display", "block")
          .style("text-align", "center")
          .style("left", `${x + 60}px`)
          .style("top", `${tool}px`)
          .style("z-index", 50)
          .style("margin", "5px 15px")
          .style("font-size", "16px");
        // .attr('transform',`translate(${event.pageX}px,${event.pagey}px)`)
        if (
          legBox["leg1"] === true ||
          legBox["leg2"] === true ||
          legBox["leg3"] === true ||
          legBox["leg4"] === true
        ) {
          if (plotKeys.length === 1) {
            if (legBox["leg1"] === true) {
              tooltips
                .append("span")
                .style("text-align", "center")
                .style("text-decoration", "underline")
                .html(">Tooltip Head<")
                .style("font-weight", "bold")
                .style("color", `${plotView[0].tooltipheadcolor}`)
                .style("width", "200px");
            }
          } else if (plotKeys.length != 0) {
            tooltips
              .append("span")
              .style("text-align", "center")
              .style("text-decoration", "underline")
              .html(">Tooltip Head<")
              .style("font-weight", "bold")
              .style("color", `${plotView[0].tooltipheadcolor}`)
              .style("width", "200px");
          }
        }

        if (plotKeys.length - 0 > 0 && legBox["leg1"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");

          tool = tooltips
            .select(`.tool_txt_${plot_id}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor11}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].keytext1}: ${tooltip_y1_valid}`);

          d3.select(`#data1_${plot_id}`)
            .selectAll(`.circle1_${plot_id}`)
            .attr("stroke", `${plotView[0].legisocolor11}`)
            .attr("fill", `${plotView[0].plotbgcolor}`);
        }

        if (plotKeys.length - 1 > 0 && legBox["leg2"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id + 1}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 1}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor12}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 1}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].keytext2}: ${tooltip_y2_valid}`);
          d3.select(`#data2_${plot_id}`)
            .selectAll(`.circle2_${plot_id}`)
            .attr("stroke", `${plotView[0].legisocolor12}`)
            .attr("fill", `${plotView[0].plotbgcolor}`);
        }

        if (plotKeys.length - 2 > 0 && legBox["leg3"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id + 2}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 2}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor13}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 2}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].keytext3}: ${tooltip_y3_valid}`);
          d3.select(`#data3_${plot_id}`)
            .selectAll(`.circle3_${plot_id}`)
            .attr("stroke", `${plotView[0].legisocolor13}`)
            .attr("fill", `${plotView[0].plotbgcolor}`);
        }

        if (plotKeys.length - 3 > 0 && legBox["leg4"] === true) {
          tooltips
            .append("div")
            .style("text-align", "center")
            .style("display", "flex")
            .attr("class", `tool_txt_${plot_id + 3}`)
            .style("text-decoration", "none")
            .style("width", "250px")
            .style("text-align", "center");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 3}`)
            .append("div")
            .style("width", "25px")
            .style("height", "13px")
            .style("background", `${plotView[0].legisocolor14}`)
            .style("margin", "5px")
            .style("border-radius", "8px");
          tool = tooltips
            .select(`.tool_txt_${plot_id + 3}`)
            .append("span")
            .style("padding-right", "10px")
            .style("color", `${plotView[0].tooltipdatacolor}`)
            .html(`${plotView[0].keytext4}: ${tooltip_y4_valid}`);
          d3.select(`#data4_${plot_id}`)
            .selectAll(`.circle4_${plot_id}`)
            .attr("stroke", `${plotView[0].legisocolor14}`)
            .attr("fill", `${plotView[0].plotbgcolor}`);
        }

        if (
          plotKeys.length > 0 &&
          (legBox["leg1"] === true ||
            legBox["leg2"] === true ||
            legBox["leg3"] === true ||
            legBox["leg4"] === true)
        ) {
          if (plotKeys.length === 1) {
            if (legBox["leg1"] === true) {
              tooltips
                .append("div")
                .attr("class", `tool_txt_${plot_id + 4}`)
                .style("display", "flex");
              tool = tooltips
                .select(`.tool_txt_${plot_id + 4}`)
                .append("span")
                .style("padding-right", "5px")
                .style("padding-left", "5px")
                .style("color", `${plotView[0].tooltipdatacolor}`)
                .html(formatDate(closestxValue))
                // .style('font-size', tooltip_fontsize)
                .style("text-align", "center");
            }
          } else if (plotKeys.length !== 0) {
            tooltips
              .append("div")
              .attr("class", `tool_txt_${plot_id + 4}`)
              .style("display", "flex");
            tool = tooltips
              .select(`.tool_txt_${plot_id + 4}`)
              .append("span")
              .style("padding-right", "5px")
              .style("padding-left", "5px")
              .style("color", `${plotView[0].tooltipdatacolor}`)
              .html(formatDate(closestxValue))
              // .style('font-size', tooltip_fontsize)
              .style("text-align", "center");
          }
        }
        // .style('color', tooltip_data_color)
      }
    };
    const onMouseLeave = (e) => {
      // console.log("Leave");
      xAxisLine.attr("display", "none");
      tooltip_circle_1.style("opacity", 0);
      tooltip_circle_2.style("opacity", 0);
      tooltip_circle_3.style("opacity", 0);
      tooltip_circle_4.style("opacity", 0);
      tooltips.style("display", "none");
      d3.select(`#data1_${plot_id}`)
        .selectAll(`.circle1_${plot_id}`)
        .attr("stroke", `${plotView[0].legisocolor11}`)
        .attr("fill", `${plotView[0].legisocolor11}`);
      d3.select(`#data2_${plot_id}`)
        .selectAll(`.circle2_${plot_id}`)
        .attr("stroke", `${plotView[0].legisocolor12}`)
        .attr("fill", `${plotView[0].legisocolor12}`);
      d3.select(`#data3_${plot_id}`)
        .selectAll(`.circle3_${plot_id}`)
        .attr("stroke", `${plotView[0].legisocolor13}`)
        .attr("fill", `${plotView[0].legisocolor13}`);
      d3.select(`#data4_${plot_id}`)
        .selectAll(`.circle4_${plot_id}`)
        .attr("stroke", `${plotView[0].legisocolor14}`)
        .attr("fill", `${plotView[0].legisocolor14}`);
    };
    d3.select(`.tooltip-area_${plot_id}`).style("opacity", 0);

    //  .attr('transform', `translate(${my_leg_margin_left - my_leg_margin_right},${dimensions.margin.top - dimensions.margin.bottom / 1.55})`);
    // const innerRect = d3
    //   .select(`#innerRect_${plot_id}`)
    //   .attr("x", 0)
    //   .attr("width", dimensions.boundedWidth)
    //   .attr("y", 0)
    //   .attr("height", dimensions.boundedHeight)
    //   .attr("fill", `${plotView[0].contbgcolor}`)
    //   .on("mousemove", actPlotData ? onMouseMove : nOP)
    //   .on("mouseleave", actPlotData ? onMouseLeave : nOP)
    //   .on("mouseover", actPlotData ? onMouseOver : nOP);
    function nOP() {}

    let xAxisLine = d3
      .select(`#xAxisLine_rect_${plot_id}`)
      .attr("stroke-width", "1px")
      .attr("width", "1px")
      .attr("height", dimensions.boundedHeight)
      .attr("y", 0)
      .attr("x", 0)
      .attr("fill", `${plotView[0].tooltipstroke}`)
      .attr("display", "none");
    let plot_defs = d3
      .select(`#plot_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.boundedWidth)
      .attr("height", dimensions.boundedHeight);
    let graph_defs = d3
      .select(`#graph_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(actPlotData, getXdata))
      .range([0, dimensions.boundedWidth])
      .nice();

    let zoomedScalex = 0;
    zoomedScalex = xScale;
    setResetScalex(xScale);
    // let resetScalex = zoomedScalex;
    setResetScalex(xScale);

    if (currentZoomState) {
      const newXscale = currentZoomState.rescaleX(zoomedScalex);
      zoomedScalex.domain(newXscale.domain());
    }

    const yScale = d3
      .scaleLinear()
      .domain([lowLimit, highLimit])
      .range([dimensions.boundedHeight, 0])
      .nice();

    const xAxis = d3.axisBottom(xScale).ticks(5);
    const yAxis = d3.axisLeft(yScale).ticks(5);
    // .tickSize(-dimensions.boundedWidth);
    const make_y_gridlines = d3
      .axisLeft(yScale)
      .ticks(5)
      .tickSize(-dimensions.boundedWidth)
      .tickFormat("");
    const myLine1 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata1(e)) + 0)
      .curve(d3.curveMonotoneX);
    const myLine2 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata2(e)) + 4)
      .curve(d3.curveMonotoneX);
    const myLine3 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata3(e)) + 8)
      .curve(d3.curveMonotoneX);
    const myLine4 = d3
      .line()
      .x((e) => zoomedScalex(getXdata(e)))
      .y((e) => yScale(getYdata4(e)) + 12)
      .curve(d3.curveMonotoneX);
    // // let u=bounds

    let tooltip_circle_1 = d3
      .select(`.tooltip_circle_1_${plot_id}`)
      .attr("r", 5)
      .attr("stroke", `${plotView[0].legisocolor11}`)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      // .attr("storke-width",8)
      .style("opacity", 0);
    let tooltip_circle_2 = d3
      .select(`.tooltip_circle_2_${plot_id}`)
      .attr("r", 5)
      .style("opacity", 0)
      .attr("storke-width", 5)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      .attr("stroke", `${plotView[0].legisocolor12}`);
    let tooltip_circle_3 = d3
      .select(`.tooltip_circle_3_${plot_id}`)
      .attr("r", 5)
      .style("opacity", 0)
      .attr("storke-width", 5)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      .attr("stroke", `${plotView[0].legisocolor13}`);
    let tooltip_circle_4 = d3
      .select(`.tooltip_circle_4_${plot_id}`)
      .attr("r", 5)
      .style("opacity", 0)
      .attr("storke-width", 5)
      .attr("fill", `${plotView[0].plotbgcolor}`)
      .attr("stroke", `${plotView[0].legisocolor14}`);

    // d3.select(`#grid_${plot_id}`).call(make_y_gridlines);

    // d3.select(`#yAxis_${plot_id}`)
    //   .call(yAxis);
    // d3.select(`#xAxis_${plot_id}`)
    //   .attr("transform", `translate(0,${dimensions.boundedHeight})`)
    //   .call(xAxis);

    const stackGenerator = d3.stack().keys(plotKeys);
    const layers = stackGenerator(actPlotData);
    const extent = [
      0,
      d3.max(layers, (layer) => d3.max(layer, (sequence) => sequence[1])),
    ];
    // console.log(layers);
    // console.log(extent);
    const stackYscale = d3.scaleLinear().domain(extent).range([dimensions]);

    const handleData1Enter = (e) => {
      d3.select(`#data1_${plot_id}`).raise();
    };
    const handleData2Enter = () => {
      d3.select(`#data2_${plot_id}`).raise();
    };
    const handleData3Enter = () => {
      d3.select(`#data3_${plot_id}`).raise();
    };
    const handleData4Enter = () => {
      d3.select(`#data4_${plot_id}`).raise();
    };

    // d3.select(`#data1_${plot_id}`)
    //   .selectAll(`.line1_${plot_id}`)
    //   .data([actPlotData])
    //   .join("path")
    //   .attr("class", `line1_${plot_id}`)
    //   .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
    //   .attr("d", (v) => myLine1(v))
    //   .attr("fill", "none")
    //   .style(
    //     "opacity",
    //     plotKeys.length - 0 > 0 && legBox["leg1"] === true ? 1 : 0
    //   )
    //   .attr("stroke", `${plotView[0].legisocolor11}`)
    //   .attr("stroke-width", "4px")
    //   .on("mousemove", onMouseMove)
    //   .on("mouseleave", onMouseLeave);
    // d3.select(`#data1_${plot_id}`).on("mouseenter", handleData1Enter);
    // /*.x((e) => zoomedScalex(getXdata(e)))
    //   .y((e) => yScale(getYdata1(e)) + 0)*/

    // d3.select(`#data2_${plot_id}`)
    //   .selectAll(`.line2_${plot_id}`)
    //   .data([actPlotData])
    //   .join("path")
    //   .attr("class", `line2_${plot_id}`)
    //   .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
    //   .attr("d", (v) => myLine2(v))
    //   .attr("fill", "none")
    //   .style(
    //     "opacity",
    //     plotKeys.length - 1 > 0 && legBox["leg2"] === true ? 1 : 0
    //   )
    //   .attr("stroke", `${plotView[0].legisocolor12}`)
    //   .attr("stroke-width", "4px")
    //   .on("mousemove", onMouseMove)
    //   .on("mouseleave", onMouseLeave);
    // d3.select(`#data2_${plot_id}`).on("mouseenter", handleData2Enter);

    // d3.select(`#data3_${plot_id}`)
    //   .selectAll(`.line3_${plot_id}`)
    //   .data([actPlotData])
    //   .join("path")
    //   .attr("class", `line3_${plot_id}`)
    //   .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
    //   .attr("d", (v) => myLine3(v))
    //   .attr("fill", "none")
    //   .style(
    //     "opacity",
    //     plotKeys.length - 2 > 0 && legBox["leg3"] === true ? 1 : 0
    //   )
    //   .attr("stroke", `${plotView[0].legisocolor13}`)
    //   .attr("stroke-width", "4px")
    //   .on("mousemove", onMouseMove)
    //   .on("mouseleave", onMouseLeave);
    // d3.select(`#data3_${plot_id}`).on("mouseenter", handleData3Enter);

    // d3.select(`#data4_${plot_id}`)
    //   .selectAll(`.line4_${plot_id}`)
    //   .data([actPlotData])
    //   .join("path")
    //   .attr("class", `line4_${plot_id}`)
    //   .attr("clip-path", `url(#plot_clipPath_${plot_id})`)
    //   .attr("d", (v) => myLine4(v))
    //   .attr("fill", "none")
    //   .style(
    //     "opacity",
    //     plotKeys.length - 3 > 0 && legBox["leg4"] === true ? 1 : 0
    //   )
    //   .attr("stroke", `${plotView[0].legisocolor14}`)
    //   .attr("stroke-width", "4px")
    //   .on("mousemove", onMouseMove)
    //   .on("mouseleave", onMouseLeave);
    // d3.select(`#data4_${plot_id}`).on("mouseenter", handleData4Enter);

    d3.select(`#data1_${plot_id}`).style(
      "opacity",
      plotKeys.length - 0 > 0 ? 1 : 0
    );
    d3.select(`#data2_${plot_id}`).style(
      "opacity",
      plotKeys.length - 1 > 0 ? 1 : 0
    );
    d3.select(`#data3_${plot_id}`).style(
      "opacity",
      plotKeys.length - 2 > 0 ? 1 : 0
    );
    d3.select(`#data4_${plot_id}`).style(
      "opacity",
      plotKeys.length - 3 > 0 ? 1 : 0
    );

    d3.select(`#Title_val_${plot_id}`)
      .attr("x", dimensions.boundedWidth / 2)
      .attr("y", dimensions.margin.bottom / 4)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text(`${plotView[0].graphtitle}`)
      .attr("fill", `${plotView[0].titlecolor}`)
      .style("padding", "15px 5px");

    // d3.select(`#xTitle_val_${plot_id}`)
    //   .attr("x", dimensions.boundedWidth / 2)
    //   .attr("y", dimensions.height - dimensions.margin.bottom * 1.25)
    //   .attr("text-anchor", "middle")
    //   .attr("font-size", "25px")
    //   .text(`${plotView[0].xaxistitle}`)
    //   .attr("fill", `${plotView[0].xlabelcolor}`)
    //   .style("padding", "15px 15px");
    // d3.select(`#yTitle_val_${plot_id}`)
    //   .attr("x", -dimensions.boundedHeight / 2)
    //   .attr("y", -dimensions.margin.left / 2 - 2.5)
    //   .attr("text-anchor", "middle")
    //   .attr("font-size", "25px")
    //   .text(`${plotView[0].yaxistitle}`)
    //   .attr("fill", `${plotView[0].ylabelcolor}`)
    //   .style("padding", "5px 5px")
    //   .attr("transform", "rotate(-90)");
    d3.select(`#legend_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", 6);

    setLastUpdated(latestDate);
    d3.select(`#last_update_text_1_${plot_id}`)
      .attr("x", -dimensions.margin.left / 1.5)
      .attr("y", dimensions.boundedHeight + dimensions.margin.bottom / 1.15)
      .attr("font-size", "18px")
      .attr("fill", `${plotView[0].misccolor}`)
      .text(`Last Updated: ${lastUpdated}`);
    d3.select(`tooltip_plot_1_${plot_id}`)
      .style("position", "absolute")
      .style("background", `green`)
      .attr("padding", 6)
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", 10)
      .attr("transform-origin", "center center");

    const getLatestdate = (data) => {
      const formatDate = d3.timeFormat("%Y-%m-%d %I:%M:%S");
      let x = d3.max(data, (d) => {
        return getXdata(d);
      });
      // console.log(x)
      let y = formatDate(x);
      //setLastUpdated(y);
      // console.log(d)
    };
    //getLatestdate(actPlotData);

    const myResetHandle = () => {
      // console.log('reset')
      setCurrentZoomState(resetScalex);
      bounds.call(zoom.transform, d3.zoomIdentity);
    };

    const handleLegendAllow = (e) => {
      let r_ = $(`#data${e.target.innerHTML}`).css("opacity");
      let r1 = e.target.innerHTML.split("_");
      console.log(r1);
      if (legBox[`leg${r1[0]}`] == true) {
        setLegBox({
          ...legBox,
          [`leg${r1[0]}`]: false,
        });
        $(`#legend_text_${e.target.innerHTML}`).css(
          "text-decoration",
          "line-through"
        );
      } else {
        setLegBox({
          ...legBox,
          [`leg${r1[0]}`]: true,
        });
        $(`#legend_text_${e.target.innerHTML}`).css("text-decoration", "none");
      }
      //   console.log(r1[0]);
      if (r_ == 1) {
        //d3.select(`#data${e.target.innerHTML}`).style("opacity", 0);
        // console.log("zero");
      } else {
        //d3.select(`#data${e.target.innerHTML}`).style("opacity", 1);
        // console.log("one");
      }
      // console.log(e.target.innerHTML)
      // console.log($(`#data${e.target.innerHTML}`).css('opacity'))
      // d3.select(`#data${e.target.innerHTML}`).style('opacity',0)
    };
    d3.select(`#legend_box_1_${plot_id}`).on("click", handleLegendAllow);
    d3.select(`#legend_box_2_${plot_id}`).on("click", handleLegendAllow);
    d3.select(`#legend_box_3_${plot_id}`).on("click", handleLegendAllow);
    d3.select(`#legend_box_4_${plot_id}`).on("click", handleLegendAllow);

    // function make_y_gridlines() {
    //   return d3.axisLeft(yScale).ticks(yScale.ticks().length);
    // }
    let grids = d3.select(`#grid_${plot_id}`);
    // console.log(grids)
    let ticks = grids.selectAll(".tick");
    d3.selection.prototype.first = function () {
      return d3.select(this[0][0]);
    };
    d3.selection.prototype.last = function () {
      var last = this.size() - 1;
      return d3.select(this[0][last]);
    };
    let line_ = ticks.size();
    console.log(line_);
    d3.select(`#yAxis_${plot_id}`).attr("color", `blue`);
    let grid1 = d3.select(`#yAxis_${plot_id}`);
    let ticks1 = grid1.selectAll(".tick");
    console.log(ticks1.size());

    //GRID LINES CONTROL
    ticks.select("line").style("opacity", 0.3).attr("stroke", `black`);
    let j = ticks.select("line");

    d3.selection.prototype.first = function () {
      return d3.select(this[0][0]);
    };
    d3.selection.prototype.last = function () {
      var last = this.size() - 1;
      return d3.select(this[0][last]);
    };

    // const rstdiv=d3.select(`reset_zoom_${plot_id}`)
    document.getElementById(`reset_zoom_${plot_id}`).onclick = myResetHandle;
    // rstdiv.on('click',myResetHandle)

    var exten = [
      [-10, dimensions.margin.top],
      [dimensions.width, dimensions.height],
    ];

    let zoom = d3
      .zoom()
      // .scaleExtent([0.5, 40])
      .translateExtent(exten)
      .extent(exten)
      .on("zoom", zoomed);
    function zoomed({ transform }) {
      const zoomState = transform;
      setCurrentZoomState(zoomState);
      console.log(transform);
      // const zoomState=d3.zoomTransform(bounds.node())
      // console.log(zoomState)
      // const newScalex=transform.rescaleX(xScale)
      // console.log(newScalex.domain())
      // zoomedScalex = transform.rescaleX(xScale);
      // zoomedScalex.domain(zoomedScalex_.domain())
      // d3.select(`#xAxis_${plot_id}`)
      //   .attr("transform", `translate(0,${dimensions.boundedHeight})`)
      //   .call(xAxis);
    }
    dragItem.current = zoom;
    bounds.call(zoom);
    rstZoomRef.current = bounds;
    var ActionsEnum = {
      None: 0,
      LeftResize: 1,
      TopResize: 2,
      RightResize: 3,
      BottomResize: 4,
      TopLeftResize: 5,
      BottomLeftResize: 6,
      TopRightResize: 7,
      BottomRightResize: 8,
      Move: 9,
    };
    // console.log('Problem2')
  };

  const createEmptyGraph = async () => {
    dimensions.boundedWidth =
      dimensions.width - dimensions.margin.left - dimensions.margin.right;
    dimensions.boundedHeight =
      dimensions.height - dimensions.margin.top - dimensions.margin.bottom;
    let my_leg_margin_left = dimensions.margin.left;
    let my_leg_margin_right = dimensions.margin.right;
    let selection = d3.select(ref.current);
    const svg = selection
      .attr("width", dimensions.width)
      .attr("height", dimensions.height)
      .style("background-color", `${plotView[0].contbgcolor}`);
    let bounds = d3
      .select(`#bounds_${plot_id}`)
      .attr(
        "transform",
        `translate(${my_leg_margin_left},${dimensions.margin.top})`
      );

    const tooltips = d3
      .select(`#tooltips_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", "20px")
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", -10)
      .attr("transform-origin", "center center")
      .attr("width", "200px")
      .style("background", `#d0d3dbB8`);

    d3.select(`.tooltip-area_${plot_id}`).style("opacity", 0);

    const innerRect = d3
      .select(`#innerRect_${plot_id}`)
      .attr("x", 0)
      .attr("width", dimensions.boundedWidth)
      .attr("y", 0)
      .attr("height", dimensions.boundedHeight)
      .attr("fill", `${plotView[0].plotbgcolor}`);
    let xAxisLine = d3
      .select(`#xAxisLine_rect_${plot_id}`)
      .attr("stroke-width", "1px")
      .attr("width", "1px")
      .attr("height", dimensions.boundedHeight)
      .attr("y", 0)
      .attr("x", 0)
      .attr("fill", `black`)
      .attr("display", "none");
    let plot_defs = d3
      .select(`#plot_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.boundedWidth)
      .attr("height", dimensions.boundedHeight);
    let graph_defs = d3
      .select(`#graph_clipPath_rect_${plot_id}`)
      .attr("width", dimensions.width)
      .attr("height", dimensions.height);

    d3.select(`#Title_val_${plot_id}`)
      .attr("x", dimensions.boundedWidth / 2)
      .attr("y", dimensions.margin.bottom / 4)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text("Title")
      .style("padding", "15px 5px");

    d3.select(`#xTitle_val_${plot_id}`)
      .attr("x", dimensions.boundedWidth / 2)
      .attr("y", dimensions.height - dimensions.margin.bottom * 1.25)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text("Time Line")
      .style("padding", "15px 15px");
    d3.select(`#yTitle_val_${plot_id}`)
      .attr("x", -dimensions.boundedHeight / 2)
      .attr("y", -dimensions.margin.left / 2 - 2.5)
      .attr("text-anchor", "middle")
      .attr("font-size", "25px")
      .text(`${title}`)
      .style("padding", "5px 5px")
      .attr("transform", "rotate(-90)");
    d3.select(`#legend_plot_1_${plot_id}`)
      .style("position", "absolute")
      .attr("padding", 6);

    setLastUpdated(latestDate);
    d3.select(`#last_update_text_1_${plot_id}`)
      .attr("x", -dimensions.margin.left / 1.5)
      .attr("y", dimensions.boundedHeight + dimensions.margin.bottom / 1.15)
      .attr("font-size", "18px")
      .text(`Last Updated: ${lastUpdated}`);
    d3.select(`tooltip_plot_1_${plot_id}`)
      .style("position", "absolute")
      .style("background", `green`)
      .attr("padding", 6)
      .style("display", "none")
      .attr("left", "50%")
      .attr("bottom", 0)
      .attr("z-index", 10)
      .attr("transform-origin", "center center");
    d3.select(`#data1_${plot_id}`).style("opacity", 0);
    d3.select(`#data2_${plot_id}`).style("opacity", 0);
    d3.select(`#data3_${plot_id}`).style("opacity", 0);
    d3.select(`#data4_${plot_id}`).style("opacity", 0);
    // d3.select(`#data1_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
    // d3.select(`#data2_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
    // d3.select(`#data3_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
    // d3.select(`#data4_${plot_id}`)
    //   .on("mousemove", "")
    //   .on("mouseenter", "")
    //   .on("mouseleave", "");
  };

  // useEffect(() => {

  //   if(data_.length) {
  //     console.log('geretyk')
  //     createGraph();
  //   }
  // }, []);
  function mapValues(val) {
    let x1 = +plotView[0].lowlimit;
    let x2 = +plotView[0].highlimit;
    let y1 = +plotView[0].lowmap;
    let y2 = +plotView[0].highmap;
    let m = (y2 - y1) / (x2 - x1);
    let y = y1 + m * (val - x1);
    return Number(y).toFixed(+plotView[0].dashsize);
  }
  function mapValues_(val) {
    let x1 = +plotView[0].lowlimit;
    let x2 = +plotView[0].highlimit;
    let y1 = +plotView[0].lowmap;
    let y2 = +plotView[0].highmap;
    let m = (y2 - y1) / (x2 - x1);
    let y = y1 + m * (val - x1);
    let r;
    if(plotView[0].mainisokey1==='nas_6_14um') {
      if(y==-1) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class 00`
      }
      else if(y>=0 && y<=12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class ${y.toFixed(0)}`
      }
      else if(y>12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class > 12`
      }
    } else if(plotView[0].mainisokey1==='sae_4um') {
      if(y==-2) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class 000`
      }
      else if(y==-1) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class 00`
      }
      else if(y>=0 && y<=12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class ${y.toFixed(0)}`
      }
      else if(y>12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class > 12`
      }
    } else {
      r=`${Number(y).toFixed(+plotView[0].dashsize)}`
    }
    return r;
  }

  useEffect(() => {
    //func()
    d3.select(`#main_plot_${plot_id}`)
      .selectAll("g g text")
      .attr("fill", `${plotView[0].minorticks1}`)
      .style("font-weight", 600);
    var svg = d3.select(`#main_plot_${plot_id}_1`);
    if (plotKeys.length - 0 > 0) {
      var sibling = svg.selectAll("g text").filter(function (d, i, list) {
        return i === list.length - 1;
      });
      sibling
        .attr("y", height / 1.7)
        .text(`${plotView[0].keytext1} : ${mapValues_(lastRecordData[plotKeys[0]])}`)
        .style("font-size", "18px")
        .style("font-weight", 600);
    }

    svg = d3.select(`#main_plot_${plot_id}_2`);
    if (plotKeys.length - 1 > 0) {
      sibling = svg.selectAll("g text").filter(function (d, i, list) {
        return i === list.length - 1;
      });
      sibling
        .attr("y", height / 1.7)
        .text(`${plotView[0].keytext2} : ${mapValues_(lastRecordData[plotKeys[1]])}`)
        .style("font-size", "18px")
        .style("font-weight", 600);
    }

    svg = d3.select(`#main_plot_${plot_id}_3`);
    if (plotKeys.length - 2 > 0) {
      sibling = svg.selectAll("g text").filter(function (d, i, list) {
        return i === list.length - 1;
      });
      sibling
        .attr("y", height / 1.7)
        .text(`${plotView[0].keytext3} : ${mapValues_(lastRecordData[plotKeys[2]])}`)
        .style("font-size", "18px")
        .style("font-weight", 600);
    }

    svg = d3.select(`#main_plot_${plot_id}_4`);
    if (plotKeys.length - 3 > 0) {
      sibling = svg.selectAll("g text").filter(function (d, i, list) {
        return i === list.length - 1;
      });
      sibling
        .attr("y", height / 1.7)
        .text(`${plotView[0].keytext4} : ${mapValues_(lastRecordData[plotKeys[3]])}`)
        .style("font-size", "18px")
        .style("font-weight", 600);
    }

    if (plotData) {
      setActPlotData([]);
      // if(update===1)
      setActPlotData(plotData);
      // console.log(plotView[0])
      // setActPlotData_(plotData);
      // console.log(plotData)
      //if(firstUpdate===1) {
      //console.log('Update')
      //if(storeData.firstUpdate===0) {
      //dispatch(updateFirstPlot(1))
      //console.log('PLOYT')
      //createGraph();
      //}
      //}
      // setUpdate(1)
      // console.log(update)
    } else if (validData === "0") {
      createEmptyGraph();
    }

    if (actPlotData && update === 1) {
      // setFirstUpdate(1);
      if (plotData === actPlotData) {
        // console.log('EQUAL')
      }
      //setUpdate(0)
      //if(storeData.firstPlot===1) {
      //if(storeData.firstPlot===1) {
      if (actPlotData.length > 0 && validData === "1") {
        // console.log(validData);
        //createGraph();
      } else if (validData === "0") {
        // console.log(plot_id)
        // console.log("empty");
        createEmptyGraph();
      }
      //}
      // dispatch(updateFirstPlot(0))
      //}
      // toolRef.current.click()
      // setTooltipAllow(true)
    }
    // else if(firstUpdate===1) {
    //   console.log('firstUpdate')
    //   //if(storeData.firstUpdate===0) {
    //     //dispatch(updateFirstPlot(1))
    //     //console.log('PLOYT')
    //     createGraph();
    //   //}
    // }
    // setUpdate(1)
    // if(plotData) {
    //   if(plotData.length===actPlotData.length) {
    //     //   console.log('fsomfosdm')
    //        createGraph();
    //     }
    // }
    updateFetch.current = 1;
    // console.log(plotData[0].datedevice);
  }, [
    width,
    height,
    currentZoomState,
    tooltipAllow,
    lastUpdated,
    plotData,
    update,
    actPlotData,
    validData,
    plotView,
    legBox,
  ]);

  useEffect(() => {
    if (latestData && actPlotData) {
      if (latestData.length > 0) {
        // console.log(latestData)
        // console.log(latestData)
        setActPlotData([...actPlotData, ...latestData]);
        setFirstUpdate(0);
      } else {
        setLastUpdated(latestDate);
      }
      // if(latestDate) {
      //   // console.log(latestDate)
      //   setLastUpdated(latestDate)
      // }
    }
  }, [latestData, latestDate]);

  useEffect(() => {
    if (updateRef.current === false) {
      // setUpdate(1)
      // setFirstUpdate(1)
      //setTimeout(() => {
      setFirstUpdate(1);
      setUpdate(1);
      if (plotKeys.length > 1) {
        d3.select(`#main_plot_${plot_id}_Key_1`)
          .style("border", `3px solid ${plotView[0].tooltipheadcolor}`)
          //.style("opacity", 1)
          .style("border-radius", "5px");
      }
      if (latestDate) {
        setLastUpdated(latestDate);
      }
      // console.log(plotView[0])
      // setActPlotData([]);
      // setActPlotData(storeData.plotFirstData)
      // console.log('FirstUpdate')
      // console.log(actPlotData)
      //createGraph()
      //}, 5000);
      return () => {
        updateRef.current = true;
        // d3.select(`#main_plot_${plot_id}`).remove()
        // dispatch(updateFirstPlot(1));
      };
    }
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
    // $('#plot').remove()
  };

  const handleDatePicker = (value, states) => {
    let str = 0;
    let newDate = new Date(value[0]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1
    }-${newDate.getDate()}`;
    setFromDate(str);
    console.log(fromDate);
    newDate = new Date(value[1]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1
    }-${newDate.getDate()}`;
    // console.log(str);
    setToDate(str);
    console.log(toDate);
  };

  const handleZoomReset = (e) => {
    console.log(e);
    setCurrentZoomState(resetScalex);
    // rstZoomRef.current.call(d3.zoomIdentity)
    // zoomedScalex.domain(newXscale.domain())
  };

  const onDragStart = (e) => {
    // e.preventDefault();
    console.log(e.target.id);
    console.log("start");
    // console.log(i)
  };
  const onDragEnter = (e) => {
    // e.preventDefault();
    console.log(e.target.className.baseVal);
    console.log("enter");
    // console.log(i)
  };
  const onDragEnd = (e) => {
    // e.preventDefault();
    console.log(e.target.id);
    console.log("end");
    // console.log(i)
  };
  const handleTooltipAllow = (e) => {
    // if(tooltipAllow===false) setTooltipAllow(true)
    setTooltipAllow(e.target.checked);
    console.log(e.target.checked);
  };

  let gaugeStyle = {
    width: width,
    // height: height,
    background: `${plotView[0].contbgcolor}`,
    display: "flex",
    flexDirection: "row",
    zIndex: 5,
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    // justifyItem:'space-between',
    // transform: `translateY(${width/60}px)`,
  };
  let onlyGauge = {
    width: `${width / 2}`,
  };
  let optionStyle = {
    // transform: `translateY(-${height - 2}px)`,
    fontSize: "27px",
    marginRight: "18px",
    textAlign: "center",
    color: `${plotView[0].titlecolor}`,
    fontWeight: 600,
    marginBottom: "10px",
  };
  let optionStyle_ = {
    // transform: `translateY(-66px)`,
    fontSize: "18px",
    marginRight: "18px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    width: width,
    justifyItems: "center",
    justifyContent: "space-evenly",
    alignItems: "center",
    fontWeight: 600,
    flexWrap: "wrap",
    marginBottom: "15px",
  };
  let avg_min_max = {
    color: `${plotView[0].misccolor}`,
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "15px",
  };
  let selectTag = {
    width: "90px",
    height: "33px",
    transform: `translateY(-10px)`,
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    fontSize: "14px",
  };
  // marginBottom: "10px",
  let bottomStyle = {
    display: "flex",
    justifyContent: "center",
    transform: `translateY(-${0.04 * height}px)`,
    alignItems: "center",
    justifyItems: "space-between",
  };
  let titleFont = {
    fontSize: "21px",
    transform: `translateY(8px)`,
    display: "flex",
    justifyContent: "center",
  };
  let titleItems = {
    margin: "0px 20px",
    justifyItem: "space-around",
  };
  let value1 = {
    fontSize: "16px",
    transform: `translateY(-${height + 30}px)`,
    width: width,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 600,
    // position:'relative'
  };
  let value2 = {
    fontSize: "16px",
    transform: `translateY(-${height / 1.84}px)`,
    width: width,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 600,
    // position:'relative'
  };

  const handleKeyChange = (e) => {
    console.log(`main_plot_${plot_id}_${e.target.selectedOptions[0].text}`);
    setCurrKey(`${e.target.value}`);
    d3.select(`#main_plot_${plot_id}_Key_1`)
      .style("border", "none")
      // .style("opacity", 1)
      .style("color", `${plotView[0].legisotextcolor11}`);
    d3.select(`#main_plot_${plot_id}_Key_2`)
      .style("border", "none")
      // .style("opacity", 1)
      .style("color", `${plotView[0].legisotextcolor12}`);
    d3.select(`#main_plot_${plot_id}_Key_3`)
      .style("border", "none")
      // .style("opacity", 1)
      .style("color", `${plotView[0].legisotextcolor13}`);
    d3.select(`#main_plot_${plot_id}_Key_4`)
      .style("border", "none")
      // .style("opacity", 1)
      .style("color", `${plotView[0].legisotextcolor14}`);
    d3.select(`#main_plot_${plot_id}_${e.target.selectedOptions[0].text}`)
      .style("border", `3px solid ${plotView[0].tooltipheadcolor}`)
      // .style("opacity", 1)
      .style("border-radius", "5px");
  };

  return (
    // <div className="plot_container">
    <>
      {/* <div id={`plot_1_${plot_id}`} style={plot} draggable={true}> */}
      <div
        id={`main_plot_${plot_id}`}
        style={{
          background: `${plotView[0].contbgcolor}`,
          width: width,
          borderRadius: "10px",
          // marginBottom: "10px",
        }}
      >
        <div style={optionStyle}>{plotView[0].graphtitle}</div>
        <div style={gaugeStyle}>
          {update === 1 && plotKeys.length - 0 > 0 ? (
            <div id={`main_plot_${plot_id}_1`} style={{marginLeft:'15px'}}>
              <Speedometer
              value={mapValues(lastRecordData[plotKeys[0]])>=plotView[0].lowmap?mapValues(lastRecordData[plotKeys[0]]):plotView[0].lowmap}
                // value={mapValues(lastRecordData[plotKeys[0]])}
                fontFamily="squada-one"
                width={370}
                height={390}
                min={plotView[0].lowmap}
                max={plotView[0].highmap}
              >
                <Background color={plotView[0].plotbgcolor} opacity={1} />
                <Arc color={plotView[0].innercircle1} arcWidth={5} />
                <Needle
                  color={plotView[0].needlecolor1}
                  circleColor={plotView[0].needlecircle1}
                />
                <Progress color={plotView[0].needlestrokecolor1} arcWidth={8} />
                <Marks
                  lineColor={plotView[0].majorticks1}
                  step={plotView[0].legisoper11>0?((plotView[0].highmap/plotView[0].legisoper11) <= 50 ?plotView[0].legisoper11:plotView[0].highmap/plotView[0].legisoper11) : plotView[0].highmap}
                  fontSize={15}
                  numbersRadius={23}
                />
                <Indicator
                  color={plotView[0].currenttext1}
                  fontSize={28}
                  suffix={"hsj"}
                />
                <DangerPath
                  angle={plotView[0].legisoper12}
                  color={plotView[0].needlecirclestroke1}
                  arcWidth={5}
                  offset={7}
                ></DangerPath>
              </Speedometer>
            </div>
          ) : (
            ""
          )}
          {update === 1 && plotKeys.length - 1 > 0 ? (
            <div id={`main_plot_${plot_id}_2`} style={{marginLeft:'15px'}}>
              <Speedometer
                value={mapValues(lastRecordData[plotKeys[1]])>=plotView[0].lowmap?mapValues(lastRecordData[plotKeys[1]]):plotView[0].lowmap}
                fontFamily="squada-one"
                width={370}
                height={390}
                min={plotView[0].lowmap}
                max={plotView[0].highmap}
              >
                <Background color={plotView[0].plotbgcolor} opacity={1} />
                <Arc color={plotView[0].innercircle1} arcWidth={5} />
                <Needle
                  color={plotView[0].needlecolor1}
                  circleColor={plotView[0].needlecircle1}
                />
                <Progress color={plotView[0].needlestrokecolor1} arcWidth={8} />
                <Marks
                  lineColor={plotView[0].majorticks1}
                  step={plotView[0].legisoper11>0?((plotView[0].highmap/plotView[0].legisoper11) <= 50 ?plotView[0].legisoper11:plotView[0].highmap/plotView[0].legisoper11) : plotView[0].highmap}
                  fontSize={15}
                  numbersRadius={23}
                />
                <Indicator
                  color={plotView[0].currenttext1}
                  fontSize={28}
                  suffix={"hsj"}
                />
                <DangerPath
                  angle={plotView[0].legisoper12}
                  color={plotView[0].needlecirclestroke1}
                  arcWidth={5}
                  offset={7}
                ></DangerPath>
              </Speedometer>
            </div>
          ) : (
            ""
          )}
          {update === 1 && plotKeys.length - 2 > 0 ? (
            <div id={`main_plot_${plot_id}_3`} style={{marginLeft:'15px'}}>
              <Speedometer
                value={mapValues(lastRecordData[plotKeys[2]])>=plotView[0].lowmap?mapValues(lastRecordData[plotKeys[2]]):plotView[0].lowmap}
                fontFamily="squada-one"
                width={370}
                height={390}
                min={plotView[0].lowmap}
                max={plotView[0].highmap}
              >
                <Background color={plotView[0].plotbgcolor} opacity={1} />
                <Arc color={plotView[0].innercircle1} arcWidth={5} />
                <Needle
                  color={plotView[0].needlecolor1}
                  circleColor={plotView[0].needlecircle1}
                />
                <Progress color={plotView[0].needlestrokecolor1} arcWidth={8} />
                <Marks
                  lineColor={plotView[0].majorticks1}
                  step={plotView[0].legisoper11>0?((plotView[0].highmap/plotView[0].legisoper11) <= 50 ?plotView[0].legisoper11:plotView[0].highmap/plotView[0].legisoper11) : plotView[0].highmap}
                  fontSize={15}
                  numbersRadius={23}
                />
                <Indicator
                  color={plotView[0].currenttext1}
                  fontSize={28}
                  suffix={"hsj"}
                />
                <DangerPath
                  angle={plotView[0].legisoper12}
                  color={plotView[0].needlecirclestroke1}
                  arcWidth={5}
                  offset={7}
                ></DangerPath>
              </Speedometer>
            </div>
          ) : (
            ""
          )}
          {update === 1 && plotKeys.length - 3 > 0 ? (
            <div id={`main_plot_${plot_id}_4`} style={{marginLeft:'15px'}}>
              <Speedometer
                value={mapValues(lastRecordData[plotKeys[3]])>=plotView[0].lowmap?mapValues(lastRecordData[plotKeys[3]]):plotView[0].lowmap}
                fontFamily="squada-one"
                width={370}
                height={390}
                min={plotView[0].lowmap}
                max={plotView[0].highmap}
              >
                <Background color={plotView[0].plotbgcolor} opacity={1} />
                <Arc color={plotView[0].innercircle1} arcWidth={5} />
                <Needle
                  color={plotView[0].needlecolor1}
                  circleColor={plotView[0].needlecircle1}
                />
                <Progress color={plotView[0].needlestrokecolor1} arcWidth={8} />
                <Marks
                  lineColor={plotView[0].majorticks1}
                  step={plotView[0].legisoper11>0?((plotView[0].highmap/plotView[0].legisoper11) <= 50 ?plotView[0].legisoper11:plotView[0].highmap/plotView[0].legisoper11) : plotView[0].highmap}
                  fontSize={15}
                  numbersRadius={23}
                />
                <Indicator
                  color={plotView[0].currenttext1}
                  fontSize={28}
                  suffix={"hsj"}
                />
                <DangerPath
                  angle={plotView[0].legisoper12}
                  color={plotView[0].needlecirclestroke1}
                  arcWidth={5}
                  offset={7}
                ></DangerPath>
              </Speedometer>
            </div>
          ) : (
            ""
          )}
        </div>
        <div style={optionStyle_}>
          <div style={{ color: `${plotView[0].misccolor}` }}>
            {`Last Updated:${lastUpdated}`}
          </div>
          <DateRangePicker
            // className="datepicker1"
            placement="auto"
            onChange={datePicker}
            popupstyle={{
              // position: "relative",
              marginRight: "-50px",
            }}
            onOpen={() =>
              handleOk(`dateFrom${plot_id}`, `dateTrom${plot_id}`, plot_id)
            }
            id={`dateFrom${plot_id}`}
            size={"md"}
            // style={{fontSize:'8px'}}
          ></DateRangePicker>
        </div>
        <div style={optionStyle_}>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 0 > 0
              ? `${plotView[0].keytext1}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[0]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 0 > 0
              ? `${plotView[0].keytext1}_Max : ${mapValues_(d3.max(
                  actPlotData.map((e) => {
                    return (e[plotKeys[0]]);
                  })
                ))}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 0 > 0
              ? `${plotView[0].keytext1}_Min : ${mapValues_(d3.min(
                  actPlotData.map((e) => {
                    return (e[plotKeys[0]]);
                  })
                ))}`
              : ""}
          </div>

          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 1 > 0
              ? `${plotView[0].keytext2}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[1]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 1 > 0
              ? `${plotView[0].keytext2}_Max : ${mapValues_(d3.max(
                  actPlotData.map((e) => {
                    return (e[plotKeys[1]]);
                  })
                ))}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 1 > 0
              ? `${plotView[0].keytext2}_Min : ${mapValues_(d3.min(
                  actPlotData.map((e) => {
                    return (e[plotKeys[1]]);
                  })
                ))}`
              : ""}
          </div>

          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 2 > 0
              ? `${plotView[0].keytext3}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[2]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 2 > 0
              ? `${plotView[0].keytext3}_Max : ${mapValues_(d3.max(
                  actPlotData.map((e) => {
                    return (e[plotKeys[2]]);
                  })
                ))}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 2 > 0
              ? `${plotView[0].keytext3}_Min : ${mapValues_(d3.min(
                  actPlotData.map((e) => {
                    return (e[plotKeys[2]]);
                  })
                ))}`
              : ""}
          </div>

          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 3 > 0
              ? `${plotView[0].keytext4}_Avg : ${d3
                  .mean(
                    actPlotData.map((e) => {
                      return mapValues(e[plotKeys[3]]);
                    })
                  )
                  .toFixed(2)}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 3 > 0
              ? `${plotView[0].keytext4}_Max : ${mapValues_(d3.max(
                  actPlotData.map((e) => {
                    return (e[plotKeys[3]]);
                  })
                ))}`
              : ""}
          </div>
          <div style={avg_min_max}>
            {update === 1 && actPlotData && plotKeys.length - 3 > 0
              ? `${plotView[0].keytext4}_Min : ${mapValues_(d3.min(
                  actPlotData.map((e) => {
                    return (e[plotKeys[3]]);
                  })
                ))}`
              : ""}
          </div>
        </div>
      </div>
      {/* <select
          className="form-select"
          aria-label="Default select example"
          style={selectTag}
          onChange={handleKeyChange}
        >
          {plotKeys.length - 0 > 0 ? (
            <option id={`option_${plot_id}_1`} value={plotView[0].keyiso1}>
              {"Key_1"}
            </option>
          ) : (
            ""
          )}
          {plotKeys.length - 1 > 0 ? (
            <option id={`option_${plot_id}_2`} value={plotView[0].keyiso2}>
              {"Key_2"}
            </option>
          ) : (
            ""
          )}
          {plotKeys.length - 2 > 0 ? (
            <option id={`option_${plot_id}_3`} value={plotView[0].keyiso3}>
              {"Key_3"}
            </option>
          ) : (
            ""
          )}
          {plotKeys.length - 3 > 0 ? (
            <option id={`option_${plot_id}_4`} value={plotView[0].keyiso4}>
              {"Key_4"}
            </option>
          ) : (
            ""
          )}
        </select> */}
      {/* <div style={value1}>
        {plotKeys.length - 0 > 0 ? (
          <div
            id={`main_plot_${plot_id}_Key_1`}
            style={{
              color: `${plotView[0].legisotextcolor11}`,
              paddingRight: "3px",
              paddingLeft: "3px",
              marginLeft: "12px",
            }}
          >
            {" "}
            <span>
              {plotView[0].keytext1} : {lastRecordData[plotKeys[0]]}
            </span>{" "}
          </div>
        ) : (
          ""
        )}
        {plotKeys.length - 1 > 0 ? (
          <div
            id={`main_plot_${plot_id}_Key_2`}
            style={{
              color: `${plotView[0].legisotextcolor12}`,
              paddingRight: "3px",
              paddingLeft: "3px",
              marginRight: "12px",
            }}
          >
            <span>
              {plotView[0].keytext2} : {lastRecordData[plotKeys[1]]}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div style={value2}>
        {plotKeys.length - 2 > 0 ? (
          <div
            id={`main_plot_${plot_id}_Key_3`}
            style={{
              color: `${plotView[0].legisotextcolor13}`,
              paddingRight: "3px",
              paddingLeft: "3px",
              marginLeft: "12px",
            }}
          >
            {" "}
            <span>
              {plotView[0].keytext3} : {lastRecordData[plotKeys[2]]}
            </span>{" "}
          </div>
        ) : (
          ""
        )}
        {plotKeys.length - 3 > 0 ? (
          <div
            id={`main_plot_${plot_id}_Key_4`}
            style={{
              color: `${plotView[0].legisotextcolor14}`,
              paddingRight: "3px",
              paddingLeft: "3px",
              marginRight: "12px",
            }}
          >
            <span>
              {plotView[0].keytext4} : {lastRecordData[plotKeys[3]]}
            </span>
          </div>
        ) : (
          ""
        )}
      </div> */}
    </>
  );
};
export default SpeedoGroupPlot;

//style={{marginTop:'20px'}} in dashboard
