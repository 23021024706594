import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashContext from "../../context/dash/dashContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addImei, createUsers, getUsers } from "../help";

import { useDispatch, useSelector } from "react-redux";
import {
  addImeiR,
  updateCards,
  addCards,
} from "../../redux/features/postSlice";

const AddImei = (props) => {
  const context = useContext(DashContext);
  const { showAlert } = context;
  const storeData = useSelector((state) => {
    return state.app;
  });
  const dispatch = useDispatch();
  const { user } = props;
  const ref = useRef(null);
  const refClose = useRef(null);
  const addMutation = useMutation(addImei);
  const [imei, setImei] = useState({
    duser: user,
    dimei: "",
    dlocation: "",
    dactive: true,
    dequipment: "",
  });
  const onChange = (e) => {
    setImei({ ...imei, [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };
  const handleAddDeviceClick = () => {
    setImei({
      duser: user,
      dimei: "",
      dlocation: "",
      dactive: true,
      dequipment: "",
    });
    ref.current.click();
  };
  const handleAddImei = () => {
    ref.current.click();
    c_user(imei);
  };
  const queryClient = useQueryClient();
  const c_user = async (user) => {
    await addMutation.mutate(user, {
      onSuccess: (e) => {
        console.log(e);
        if (e.success == true) {
          dispatch(addCards(1));
          showAlert("Device Created", "success");
        } else {
          showAlert("Device Can't Be Created", "danger");
        }
        queryClient.invalidateQueries(["imei"]);
      },
      onError: () => {
        showAlert("Device Can't Be Created", "danger");
      },
    });
  };
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary mx-2"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal4"
        ref={ref}
        onClick={handleAddDeviceClick}
      >
        Add Device
      </button>
      <div
        className="modal fade"
        id="exampleModal4"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Device Details
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="dimei" className="form-label">
                  Device Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dimei"
                  name="dimei"
                  onChange={onChange}
                  value={imei.dimei}
                  maxLength={16}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="dlocation" className="form-label">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dlocation"
                  name="dlocation"
                  onChange={onChange}
                  value={imei.dlocation}
                  maxLength={25}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="dequipment" className="form-label">
                  Equipment
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="dequipment"
                  name="dequipment"
                  onChange={onChange}
                  value={imei.dequipment}
                />
              </div>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  id="inputGroupSelect02"
                  onChange={onChange}
                  value={imei.dactive}
                  name="dactive"
                >
                  <option value={true}>Active</option>
                  <option value={false}>Deactive</option>
                </select>
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect02"
                >
                  Status
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddImei}
              >
                Save Device
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddImei;
