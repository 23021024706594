import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DashContext from "../../context/dash/dashContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Route, Link, Routes, useParams } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const TableVal = (props) => {
  const context = useContext(DashContext);
  const { showAlert, parse_date } = context;
  useEffect(() => {}, []);

  const assignCurrentUser = (e) => {
    localStorage.setItem("companyName", e.companyname);
    localStorage.setItem("customerName", e.customername);
    localStorage.setItem('userId',e._id)
      localStorage.setItem('userName',e.name)
    // console.log(e)
  };

  const { user, updateUser, index, deleteUser } = props;
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{user.customername}</td>
        <td>{user.customerno}</td>
        <td onClick={() => assignCurrentUser(user)}>
          <Link to={`/devices/${user._id}/${user.name}`}>{user.name}</Link>
        </td>
        <td style={{ display: "flex", alignItems: "center" }}>
          <input
            type="password"
            id={`show_password_${index + 1}`}
            className="form-control"
            aria-labelledby="passwordHelpBlock"
            disabled
            value={user.passwords}
            style={{ textAlign: "center" }}
          />
          <VisibilityIcon
            style={{ marginLeft: "3px", cursor: "pointer" }}
            onClick={props.togglePassword}
            id={`vis_${index + 1}`}
          ></VisibilityIcon>
        </td>
        <td>{user.companyname}</td>
        <td id={`company_address_${index + 1}`}>
          {user.companyaddress.length > 16
            ? `${user.companyaddress.substring(0, 16)}...`
            : user.companyaddress}
          {/* <ReactTooltip
        anchorId={`company_address_${index+1}`}
        place="bottom"
        content={user.companyaddress}
      /> */}
        </td>
        <td>{user.active ? "active" : "deactive"}</td>
        <td>{user.date}</td>
        <td>
          <EditIcon
            className="mx-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              updateUser(user);
            }}
          ></EditIcon>
          <DeleteIcon
            className="mx-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteUser(user);
            }}
          ></DeleteIcon>
        </td>
      </tr>
    </>
  );
};

export default TableVal;
