import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./loginForm.scss";

const LoginForm = (props) => {
  const [state, setState] = useState({ name: "", password: "" });
  let history = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch("http://gtswiman.electrowaretech.com:8000/api/auth/login", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ name: state.name, password: state.password }),
    });
    const json = await response.json();
    //console.log(json);
    if (json.success == true) {
      console.log("Access Granted");
      localStorage.setItem("token", json.token);
      localStorage.setItem("loginType",json.loginType);
      localStorage.setItem("loginId",json.loginId);
      localStorage.setItem("loginName",json.loginName);
      props.showAlert("Logged In", "success");
      if(localStorage.getItem("loginType")==='User') {
        history(`/devices/${localStorage.getItem("loginId")}/${localStorage.getItem("loginName")}`);
      }
      else {
        history('/');
      }
    } else {
      console.log("Access Denied");
      props.showAlert("Invalid Credentials", "danger");
    }
  };
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    //console.log(e.target.name);
  };

  return (
    // <div className="container LoginForm">
    <section className="vh-100 gradient-custom">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card bg-dark text-white"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 text-center">
                <form onSubmit={handleLogin}>
                  <div className="mb-md-5 mt-md-4 pb-5">
                    <h2 className="fw-bold mb-2 text-uppercase">
                      GTS Wiman Particle Counter
                    </h2>
                    <p className="text-white-50 mb-5">
                      Please enter your login and password!
                    </p>

                    <div className="form-outline form-white mb-4">
                      <input
                        type="text"
                        id="typeEmailX"
                        name="name"
                        value={state.name}
                        onChange={onChange}
                        className="form-control form-control-lg"
                      />
                      <label className="form-label" htmlFor="typeEmailX">
                        User Name
                      </label>
                    </div>

                    <div className="form-outline form-white mb-4">
                      <input
                        type="password"
                        id="typePasswordX"
                        name="password"
                        value={state.password}
                        onChange={onChange}
                        className="form-control form-control-lg"
                      />
                      <label className="form-label" htmlFor="typePasswordX">
                        Password
                      </label>
                    </div>

                    <button
                      className="btn btn-outline-light btn-lg px-5"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                  <div></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    /* </div> */
  );
};

export default LoginForm;
