import {
  Route,
  Link,
  Routes,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";

import "./imei.scss";
import DashContext from "../../context/dash/dashContext";

import {
  getImei,
  addImei,
  updateImei,
  deletImei,
  getImei_,
  getImeiSearch,
} from "../help";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ImeiItem from "./ImeiItem";
import AddImei from "./AddImei";
import { useDispatch, useSelector } from "react-redux";
import {
  addImeiR,
  updateCards,
  addCards,
} from "../../redux/features/postSlice";

import ReactPaginate from "react-paginate";

import 'fa-icons';

import SearchIcon from '@mui/icons-material/Search';

const Imei = () => {
  const storeData = useSelector((state) => {
    return state.app;
  });
  const dispatch = useDispatch();
  let location = useLocation();
  let history = useNavigate();
  const params = useParams();
  const context = useContext(DashContext);
  const { showAlert } = context;
  const [imei, setImei] = useState({});
  const [dimei, setdImei] = useState({});
  const ref = useRef(null);
  const refClose = useRef(null);
  const refPage = useRef(null);
  const refPage1 = useRef(1);
  const addMutation = useMutation(getImeiSearch);
  const addMutation_ = useMutation(getImeiSearch);
  const createMutation = useMutation(addImei);
  const deleteMutation = useMutation(deletImei);
  const updateMutation = useMutation(updateImei);
  const queryClient = useQueryClient();

  const currentPage = useRef();
  const limit = useRef();
  const pageCount = useRef();
  const totalImei = useRef();
  const searchImei = useRef();
  const activeRef = useRef();
  const visitedOnce = useRef(1);
  const userIdRef = useRef(params.userId);

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;

  const { status, data, isFetching } = useQuery(
    ["imei"],
    () =>
      getImeiSearch({
        user: params.userId,
        currentPage: 1,
        limit: 5,
        searc: "",
      }),
    {
      onSuccess: (e) => {
        //console.log(e)
        if (storeData.cardUpdate == 1) {
          dispatch(updateCards(0));
          pageCount.current = e.pageCount;
          totalImei.current = e.totalImei;
          dispatch(addImeiR(e.result));
          // console.log('new fetch')
        }
        if (storeData.cardAdd == 1) {
          dispatch(addCards(0));
          let obj = {};
          obj.user = params.userId;
          obj.currentPage = currentPage.current;
          obj.limit = limit.current;
          obj.searc = searchImei.current;
          c_user_3(obj);
        }
        //console.log(storeData)
        //settime()
      },
    }
  );

  const c_user = async (user) => {
    await addMutation.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        let obj = {};
        obj.user = params.userId;
        obj.currentPage = currentPage.current;
        obj.limit = limit.current;
        obj.searc = searchImei.current;
        c_user_3(obj);
      },
      onError: () => {},
    });
    //queryClient.invalidateQueries(["imei"]);
  };
  const c_user_1 = async (user) => {
    await updateMutation.mutate(user, {
      onSuccess: (e) => {
        if (e.success === true) {
          showAlert("Device has Been Updated", "success");
          let obj = {};
          obj.user = params.userId;
          obj.currentPage = currentPage.current;
          obj.limit = limit.current;
          //searchImei.current='';
          obj.searc = searchImei.current;
          // init_obj = {
          //   user: params.userId,
          //   currentPage: 1,
          //   limit: 5,
          //   search:''
          // };
          //console.log(obj)
          //dispatch(updateCards(1))
          c_user_3(obj);
        } else {
          showAlert("Device Can't Be Updated", "danger");
        }
      },
      onError: () => {
        showAlert("Device Can't Be Updated", "danger");
      },
    });
    //queryClient.invalidateQueries(["imei"]);
  };
  const c_user_2 = async (user) => {
    await deleteMutation.mutate(user, {
      onSuccess: (e) => {
        if (e.success === true) {
          showAlert("Device has Been Deleted", "success");
          let obj = {};
          obj.user = params.userId;
          obj.currentPage = currentPage.current;
          obj.limit = limit.current;
          obj.searc = searchImei.current;
          //console.log(obj)
          // refPage.current=0;
          c_user_3(obj);
        } else {
          showAlert("Device Can't Be Deleted", "danger");
        }
      },
      onError: () => {
        showAlert("Device Can't Be Deleted", "danger");
      },
    });
    //queryClient.invalidateQueries(["imei"]);
  };

  const c_user_3 = async (user) => {
    await addMutation_.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        let obj = {};
        obj.user = params.userId;
        if (!e.result.length && e.totalImei > 0) {
          //if(currentPage.current!=1) {
          currentPage.current = currentPage.current - 1;
          obj.currentPage = currentPage.current - 1;
          //}
          //else {
          //obj.currentPage=currentPage.current;
          //}
          obj.limit = limit.current;
          obj.searc = "";
          //c_user(obj);
        } else {
          pageCount.current = e.pageCount;
          totalImei.current = e.totalImei;
          dispatch(addImeiR(e.result));
        }
      },
      onError: () => {
        //console.log('error3');
      },
    });
    // queryClient.invalidateQueries(["imei"]);
  };

  // useEffect(() => {
  //   if (storeData.imei.length) {
  //     //console.log(imei)
  //   }
  //   //console.log('matched')
  // });
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/");
    } else {
      if (refPage1.current !== 2) {
        refPage1.current = 2;
        currentPage.current = 1;
        limit.current = 5;
        pageCount.current = 5;
        totalImei.current = 5;
        // refPage.current = 0;
        searchImei.current = "";
        activeRef.current = 0;
        visitedOnce.current = 1;
        userIdRef.current = params.userId;
        dispatch(updateCards(1));
      } else {
        let obj = {
          user: params.userId,
          currentPage: 1,
          limit: 5,
          searc: "",
        };
        c_user_3(obj)
      }
    }
  }, [location]);
  const updateImeiClick = (imei) => {
    console.log(imei);
    setImei({
      ...imei,
      eimei: imei.imei,
      elocation: imei.location,
      eequipment: imei.equipment,
      eactive: imei.active,
    });
    ref.current.click();
    // setNote({id:note._id,etitle:note.title,edescription:note.description,etag:note.tag})
  };
  const deleteImeiClick = (imei) => {
    console.log(imei);
    setdImei({ imei: imei });
    refClose.current.click();
    // setNote({id:note._id,etitle:note.title,edescription:note.description,etag:note.tag})
  };
  const handleUpdateImei = () => {
    // console.log(imei);
    // setImei({
    //   ...imei,
    //   eimei: imei.imei,
    //   elocation: imei.location,
    // });
    ref.current.click();
    c_user_1(imei);
    // setNote({id:note._id,etitle:note.title,edescription:note.description,etag:note.tag})
  };
  const handleDeleteImei = () => {
    // console.log(imei);
    // setImei({
    //   ...imei,
    //   eimei: imei.imei,
    //   elocation: imei.location,
    // });
    refClose.current.click();
    c_user_2({ imei: dimei.imei, user: params.userId });
    // setNote({id:note._id,etitle:note.title,edescription:note.description,etag:note.tag})
  };
  const onChange = (e) => {
    setImei({ ...imei, [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };
  const handlePageClick = (e) => {
    let obj = {};
    currentPage.current = e.selected + 1;
    // setCurrentPage(e.selected+1)
    obj.user = params.userId;
    obj.currentPage = currentPage.current;
    obj.limit = limit.current;
    obj.searc = searchImei.current;
    c_user_3(obj);
    // console.log(e);
  };
  const handleSearch = (e) => {
    let obj = {};
    currentPage.current = 1;
    // setCurrentPage(e.selected+1)
    obj.user = params.userId;
    obj.currentPage = currentPage.current;
    obj.limit = limit.current;
    obj.searc = document.getElementById("imei_search").value;
    searchImei.current = obj.searc;
    c_user_3(obj);
    //console.log(document.getElementById('imei_search').value)
  };
  const handleSearchChange = (e) => {
    //console.log(e.target.value)
    //console.log(e.target.value.length)
    activeRef.current = 0;
    if (!document.getElementById("imei_search").value) {
      let obj = {};
      currentPage.current = 1;
      // setCurrentPage(e.selected+1)
      obj.user = params.userId;
      obj.currentPage = currentPage.current;
      obj.limit = limit.current;
      obj.searc = document.getElementById("imei_search").value;
      searchImei.current = obj.searc;
      c_user_3(obj);
    }
    // console.log($('.page-item ,.active').css("background-color", "yellow"))
    // refPage.current.click();
  };
  const handlePageClick_ = (e) => {
    //console.log(e)
  };

  return (
    <>
      <div>
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal6"
          ref={refClose}
          style={{ display: "none" }}
        >
          Launch demo modal
        </button>

        <div
          className="modal fade"
          id="exampleModal6"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Are You Sure You Want to Delete Device ?
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">{dimei.imei}</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleDeleteImei}
                >
                  Delete Device
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal5"
          ref={ref}
          style={{ display: "none" }}
        >
          Launch demo modal
        </button>

        <div
          className="modal fade"
          id="exampleModal5"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Edit Device {imei.imei}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="eimei" className="form-label">
                    Device Id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="eimei"
                    name="eimei"
                    onChange={onChange}
                    value={imei.eimei ? imei.eimei : ""}
                    maxLength={16}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="elocation" className="form-label">
                    Location
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="elocation"
                    name="elocation"
                    onChange={onChange}
                    value={imei.elocation ? imei.elocation : ""}
                    maxLength={25}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="eequipment" className="form-label">
                    Equipment
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="eequipment"
                    name="eequipment"
                    onChange={onChange}
                    value={imei.eequipment ? imei.eequipment : ""}
                    maxLength={25}
                  />
                </div>
                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    id="inputGroupSelect03"
                    onChange={onChange}
                    value={imei.eactive ? imei.eactive : false}
                    name="eactive"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Deactive</option>
                  </select>
                  <label
                    className="input-group-text"
                    htmlFor="inputGroupSelect03"
                  >
                    Status
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdateImei}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>

        <h1
          style={{ textAlign: "center" }}
        >{`Device Lists For User ${params.user}`}</h1>
        <div className="row my-3">
          <div className="container text-center">
            {totalImei.current === 0 ? (
              <h1 style={{ textAlign: "center" }}>No Devices To Display</h1>
            ) : (
              <div>
                <h3>{`Device Count: ${totalImei.current}`}</h3>
                <h5>{`Page No: ${currentPage.current}`}</h5>
              </div>
            )}
          </div>
          <div className="add_search_div">
            {(localStorage.getItem('loginType')==='Admin')?<AddImei user={params.userId}></AddImei>:''}
            <div style={{ position: "absolute", right: "10px" }}>
              <input
                type="text"
                placeholder="Search.."
                name="search"
                id="imei_search"
                onChange={handleSearchChange}
              />
              <button onClick={handleSearch}>
                <SearchIcon></SearchIcon>
              </button>
            </div>
          </div>
          <div className="cards">
            {storeData.imei && storeData.imei.length
              ? storeData.imei.map((note, index) => {
                  return (
                    <ImeiItem
                      key={note._id}
                      imei={note}
                      updateImeiClick={updateImeiClick}
                      deleteImeiClick={deleteImeiClick}
                      user={params.user}
                    ></ImeiItem>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
      <div
        style={{
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <footer ref={refPage}>
          <ReactPaginate
            activePage={activeRef.current}
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount.current ? pageCount.current : 1}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            forcePage={activeRef.current}
            // onPageChange={this.handlePageClick}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            onClick={handlePageClick_}
          />
        </footer>
      </div>
    </>
  );
};

export default Imei;
