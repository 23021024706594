import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import DashContext from "../../context/dash/dashContext";
import "./table.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableVal from "./TableVal";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns, data } from "./Data.js";

import { useDispatch, useSelector } from "react-redux";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createUsers, getUsers, getUsers_ } from "../help";
import { addUserR } from "../../redux/features/postSlice";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Tables = (props) => {
  let [state, setState] = useState();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => {
    return state.app;
  });
  const { status, data, isFetching } = useQuery(["users"], getUsers, {
    onSuccess: (e) => {
      //console.log(e)
      setState((prevCount) => e);
      rec_data = e;
      dispatch(addUserR(e));
      //console.log(storeData)
      //settime()
    },
  });
  const togglePassword = (e) => {
    let val = e.target.children;
    let id = 0;
    if (val.length > 0) {
      //console.log(e.target.id);
      id = e.target.id;
    } else {
      //console.log(e.target.parentElement.id);
      id = e.target.parentElement.id;
    }
    if (id) {
      let l = id.split("_");
      let cond = document.getElementById(`show_password_${l[1]}`).type;
      if (cond === "password")
        document.getElementById(`show_password_${l[1]}`).type = "text";
      else document.getElementById(`show_password_${l[1]}`).type = "password";
      // console.log(cond)
    }
  };
  const addMutation = useMutation(getUsers_);
  const queryClient = useQueryClient();
  const c_user = async (user) => {
    addMutation.mutate(user, {
      onSuccess: (e) => {
        // queryClient.invalidateQueries(['users'])
        //console.log(e)
        setState(e);
        rec_data = e;
        dispatch(addUserR(e));
        localStorage.setItem("count", e.length);
        localStorage.setItem("updateTable", "YES");
        //settime()
        // console.log(rec_data)
        // console.log(state)
        // $('#example').DataTable().destroy();
        // $(document).ready(function () {
        //   setTimeout(function () {
        //     table = $("#example").DataTable({
        //       pagingType: "full_numbers",
        //       //   pageLength: 5,
        //       processing: true,
        //       bDestroy: true,
        //       dom: "lBfrtip",
        //       buttons: ["copy", "csv", "excel", "pdf", "print"],
        //       orderCellsTop: true,
        //       fixedHeader: true,
        //       //   sPaginationType: "full_numbers",
        //       aaSorting: [[0, "asc"]],
        //       iDisplayLength: 5,
        //       aLengthMenu: [
        //         [5, 10, 25, 50, 100, -1],
        //         [5, 10, 25, 50, 100, "All"],
        //       ],
        //     });
        //   console.log(state)
        //   }, 5000);
        // });
      },
    });
  };
  let table = 0;
  let rec_data = 0;
  const settime = () => {
    setTimeout(() => {
      dispStoreData();
    }, 3000);
  };
  function dispStoreData() {
    if (localStorage.getItem("updateTable") === "YES") {
      localStorage.removeItem("updateTable");
      //console.log(storeData.posts)
      // $('#example').DataTable().destroy();
      var divs = 0;
      let res = 0;
      // var divs = $( "tr" );
      // $('tr').removeClass('odd');
      // $('tr').removeClass('even');
      // $('td').removeClass('sorting_1');
      // $('#example').DataTable().destroy();
      //console.log(divs[divs.length-1])
      //console.log(storeData.posts[storeData.posts.length-1])
      divs = $("tr");
      //if(localStorage.getItem('operation')==='DEL') {
      // $('#example').DataTable().destroy();
      //}
      if (localStorage.getItem("operation") === "ADD") {
        res = divs[divs.length - 1];
      }
      //console.log(divs.length)
      //console.log(storeData.posts.length)
      let i = 0;
      if (localStorage.getItem("operation") === "ADD") {
        $("#example").DataTable().destroy();
        // $('tr').remove()
        $("tbody").append(res);
      } else if (localStorage.getItem("operation") === "DEL") {
        $("#example").DataTable().destroy();
        divs = $("tr");
        //console.log(divs)
        for (i = divs.length - 1; i > storeData.posts.length; i--) {
          divs[i].remove();
        }
        divs = $("tr td:first-child");
        let j = 0;
        $("tr").each(function () {
          //console.log('bdausbduas')
          $(this).find("td:first-child").text(`${j}`);
          j += 1;
        });
        // for(i=0;i<storeData.posts.length-1;i++) {
        //  console.log(divs[i].text())
        // }
      }
      //if(divs.length>=storeData.posts.length) {
      //console.log('nxusnxusn')
      // for(i=divs.length-1;i>storeData.posts.length;i--) {
      //   divs[i].remove();
      // }
      localStorage.removeItem("operation");
      // $('#example').DataTable().destroy();
      $(document).ready(function () {
        setTimeout(function () {
          table = $("#example").DataTable({
            pagingType: "full_numbers",
            //   pageLength: 5,
            processing: true,
            bDestroy: true,
            dom: "lBfrtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            orderCellsTop: true,
            fixedHeader: true,
            //   sPaginationType: "full_numbers",
            aaSorting: [[0, "asc"]],
            iDisplayLength: 5,
            aLengthMenu: [
              [5, 10, 25, 50, 100, -1],
              [5, 10, 25, 50, 100, "All"],
            ],
          });
        }, 500);
      });
      //}
      //console.log(divs)
      // $(document).ready(function () {
      //   setTimeout(function () {
      //     table = $("#example").DataTable({
      //       pagingType: "full_numbers",
      //       //   pageLength: 5,
      //       processing: true,
      //       bDestroy: true,
      //       dom: "lBfrtip",
      //       buttons: ["copy", "csv", "excel", "pdf", "print"],
      //       orderCellsTop: true,
      //       fixedHeader: true,
      //       //   sPaginationType: "full_numbers",
      //       aaSorting: [[0, "asc"]],
      //       iDisplayLength: 5,
      //       aLengthMenu: [
      //         [5, 10, 25, 50, 100, -1],
      //         [5, 10, 25, 50, 100, "All"],
      //       ],
      //     });
      //   }, 5000);
      // });
    }
  }
  function func() {
    //setTimeout(() => {
    // getAllUsers();
    // dispatch(getPosts({id:'budsuduwbduwbduw'}));
    //}, 3000);
    // setTimeout(() => {
    //     //$("#example").DataTable().draw()
    // }, 1000);
    // c_user('user')
    // c_user('user')
    c_user("user");
    // console.log(data)
    //console.log("table cmsacmasoim")
  }
  const context = useContext(DashContext);
  const { users, getAllUsers, setUsers } = context;
  let location = useLocation();
  let history = useNavigate();
  const tableData = {
    columns,
    data,
  };
  // setInterval(() => {
  //   if(localStorage.getItem('userLenght')===posts.length) {
  //     if(localStorage.getItem("TableUpdate") === "Yes") {
  //       if(posts.length>0) {
  //         localStorage.removeItem("TableUpdate")
  //         // console.log(posts)
  //       }
  //     }
  //   }
  //   else {
  //     if(localStorage.getItem("TableUpdate") === "Yes") {
  //       // dispatch(getPosts({id:'budsuduwbduwbduw'}));
  //     }
  //   }
  // }, 5000);
  useEffect(() => {
    //initialize datatable
    // $('table').remove();
    $(document).ready(function () {
      setTimeout(function () {
        table = $("#example").DataTable({
          pagingType: "full_numbers",
          //   pageLength: 5,
          processing: true,
          bDestroy: true,
          dom: "lBfrtip",
          buttons: ["copy", "csv", "excel", "pdf", "print"],
          orderCellsTop: true,
          fixedHeader: true,
          //   sPaginationType: "full_numbers",
          aaSorting: [[0, "asc"]],
          iDisplayLength: 5,
          aLengthMenu: [
            [5, 10, 25, 50, 100, -1],
            [5, 10, 25, 50, 100, "All"],
          ],
        });
        //    console.log(table)
      }, 1000);
    });
    if (localStorage.getItem("loginType") === "Admin") {
      //console.log("table Update");
      // setState(data)
      // console.log(state)
      //getAllUsers()
      // dispatch(getPosts({id:'budsuduwbduwbduw'}));
      //console.log(posts)
    }

    // if(localStorage.getItem('tableUpdate')==='Yes') {
    //     localStorage.removeItem('tableUpdate')
    //     $('#example').DataTable().ajax.reload();
    //     console.log('table Update')
    // }
  }, [location]);
  useEffect(() => {
    //console.log(storeData.posts)
    if (storeData.posts.length == localStorage.getItem("count")) {
      //console.log('matched')
      dispStoreData();
    }
    // console.log(localStorage.getItem('count'))
  });
  return (
    <>
      <table id="example" className="table display table-hover table-bordered">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Name</th>
            <th>Number</th>
            <th>User</th>
            <th>Password</th>
            <th>Company</th>
            <th>Address</th>
            <th>Status</th>
            <th>Modified</th>
            <th>Edit/Remove</th>
          </tr>
        </thead>
        <tbody id="userData">
          {status === "success" && storeData.posts ? (
            storeData.posts.map((e, index) => {
              return (
                <TableVal
                  key={e._id}
                  user={e}
                  index={index}
                  updateUser={props.updateUser}
                  deleteUser={props.deleteUser}
                  togglePassword={togglePassword}
                ></TableVal>
                // <tr key={index}>
                //   <td>{index + 1}</td>
                //   <td>{e.name}</td>
                //   <td>{e.companyName}</td>
                //   <td>{e.date}</td>
                //   <td>{e.active ? "active" : "deactive"}</td>
                //   <td>
                //     <EditIcon className="mx-2" style={{cursor:'pointer'}} onClick={props.handleUserEdit} id={e._id}></EditIcon>
                //     <DeleteIcon className="mx-2" style={{cursor:'pointer'}} onClick={props.handleUserDelete} id={e._id}></DeleteIcon>
                //     <i className="fa-duotone fa-user-pen"></i>
                //   </td>
                // </tr>
              );
            })
          ) : (
            <tr>
              <td>{"No table"}</td>
            </tr>
          )}
          {localStorage.getItem("TableUpdate") === "Yes"
            ? (localStorage.removeItem("TableUpdate"),
              // console.log("tableValUpdate"),
              // console.log(posts),
              func())
            : ""}
        </tbody>
      </table>
      {status === "success" && storeData.posts
        ? storeData.posts.map((e, index) => {
            return (
              <ReactTooltip
                key={e._id}
                anchorId={`company_address_${index + 1}`}
                place="top"
                content={e.companyaddress}
              />
            );
          })
        : ""}
    </>
  );
};

export default Tables;
