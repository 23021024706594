import { createSlice} from "@reduxjs/toolkit";

// export const getPosts=createAsyncThunk("posts/getPosts",async ({id})=> {
//     console.log(id)
//     return await fetch("http://localhost:5000/api/auth/getuser/",{
//         method: "POST",
//           headers: {
//             "Content-type": "application/json",
//             "auth-token":`${localStorage.getItem('token')}`
//           },
//         //   body: JSON.stringify({
//         //     title: 'foo',
//         //     body: 'bar',
//         //     userId: 1,
//         //   }),
//     })
//         .then((res)=>res.json());
//         //.then((json) => console.log(json));
// })
// export const delPosts=createAsyncThunk("posts/getPosts",async ({id})=> {
//     console.log(id)
//     return await fetch("http://localhost:5000/api/auth/deleteuser/",{
//         method: "POST",
//           headers: {
//             "Content-type": "application/json",
//             "auth-token":`${localStorage.getItem('token')}`
//           },
//           body: JSON.stringify({name:id})
//         //   body: JSON.stringify({
//         //     title: 'foo',
//         //     body: 'bar',
//         //     userId: 1,
//         //   }),
//     })
//         .then((res)=>res.json())
//         // .then((json) => {console.log(json)});
// })
//   //DELETE A NOTE
// //   const deleteUser_ = async (name) => {
// //     const url = `${host}api/auth/deleteuser`;
// //     const response = await fetch(url, {
// //       method: "POST",
// //       headers: {
// //         "Content-type": "application/json",
// //         "auth-token": `${localStorage.getItem("token")}`,
// //       },
// //       body: JSON.stringify({name:name})
// //     });
// //     const json = await response.json();
// //     if(json.success==true) {
// //       dispatch(getPosts({id:'budsuduwbduwbduw'}));
// //       showAlert("User Deleted","success")
// //       console.log('Delete')
// //       console.log(posts)
// //     }
// //     else showAlert("User Can't Be Deleted","danger")
// //     // getAllUsers();
// //     //const newNotes = users.filter((note) => note.name !== name);
// //     //setUsers(users);
// //   };


const postSlice=createSlice(({
    name:"posts",
    initialState: {
        posts:[],
        imei:[],
        imeiData:[],
        progSeq:[],
        progSeq_:[],
        plotData:[],
        plotLatestData:[],
        plotLatestDate:[],
        plotFirstData:[],
        plotView:[],
        plotKeys:[],
        validData:[],
        lastRecordDate:0,
        lastRecordData:[],
        cardUpdate: 0,
        cardAdd: 0,
        seqUpdate: 0,
        firstPlot:0,
        dashObj:{},
        sensors:[],
    },
    reducers: {
        addUserR(state,action) {
            state.posts=[]
            state.posts=action.payload
            //console.log(state.posts)
        },
        addImeiR(state,action) {
            state.imei=[]
            state.imei=action.payload
            //console.log(state.posts)
        },
        addImeiDataR(state,action) {
            state.imeiData=[]
            state.imeiData=action.payload
            //console.log(state.posts)
        },
        updateCards(state, action) {
            state.cardUpdate = 0;
            state.cardUpdate = action.payload;
            //console.log(state.posts)
        },
        addCards(state, action) {
            state.cardAdd = 0;
            state.cardAdd = action.payload;
            //console.log(state.posts)
        },
        addSeqProg(state,action) {
            state.progSeq=[]
            state.progSeq=action.payload
            //console.log(state.posts)
        },
        addSeqProg_(state,action) {
            state.progSeq_=[]
            state.progSeq_=action.payload
            //console.log(state.posts)
        },
        addSensors(state,action) {
            state.sensors=[]
            state.sensors=action.payload
            //console.log(state.posts)
        },
        addPlotData(state,action) {
            state.plotData=[]
            state.plotData=action.payload
            //console.log(state.posts)
        },
        addDashObj(state,action) {
            state.dashObj={}
            state.dashObj=action.payload
            //console.log(state.posts)
        },
        addPlotLatestData(state,action) {
            state.plotLatestData=[]
            state.plotLatestData=action.payload
            //console.log(state.posts)
        },
        addPlotLatestDate(state,action) {
            state.plotLatestDate=[]
            state.plotLatestDate=action.payload
            //console.log(state.posts)
        },
        addPlotFirstData(state,action) {
            state.plotFirstData=[]
            state.plotFirstData=action.payload
            //console.log(state.posts)
        },
        addPlotKeys(state,action) {
            state.plotKeys=[]
            state.plotKeys=action.payload
            //console.log(state.posts)
        },
        addValidData(state,action) {
            state.validData=[]
            state.validData=action.payload
            //console.log(state.posts)
        },
        addPlotView(state,action) {
            state.plotView=[]
            state.plotView=action.payload
            //console.log(state.posts)
        },
        addLastRecordDate(state,action) {
            state.lastRecordDate=[]
            state.lastRecordDate=action.payload
            //console.log(state.posts)
        },
        addLastRecordData(state,action) {
            state.lastRecordData=[]
            state.lastRecordData=action.payload
            //console.log(state.posts)
        },
        updateSeqProgams(state, action) {
            state.seqUpdate = 0;
            state.seqUpdate = action.payload;
            //console.log(state.posts)
        },
        updateFirstPlot(state, action) {
            state.firstPlot = 0;
            state.firstPlot = action.payload;
            //console.log(state.posts)
        },
    }
}))


export default postSlice.reducer;

export const {addUserR} =postSlice.actions;

export const {addImeiR} =postSlice.actions;

export const {addImeiDataR} =postSlice.actions;

export const { updateCards } = postSlice.actions;

export const { addCards } = postSlice.actions;

export const { addSeqProg } = postSlice.actions;

export const { addSeqProg_ } = postSlice.actions;

export const { addPlotData } = postSlice.actions;

export const { addPlotLatestData } = postSlice.actions;

export const { addPlotLatestDate } = postSlice.actions;

export const { addPlotFirstData } = postSlice.actions;

export const { addPlotKeys } = postSlice.actions;

export const { addDashObj } = postSlice.actions;

export const { addValidData } = postSlice.actions;

export const { addPlotView } = postSlice.actions;

export const { addLastRecordDate } = postSlice.actions;

export const { addLastRecordData } = postSlice.actions;

export const { updateSeqProgams } = postSlice.actions;

export const { updateFirstPlot } = postSlice.actions;

export const { addSensors } = postSlice.actions;
