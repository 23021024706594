import {
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, {
  useContext,
  useEffect,
  useState,
} from "react";

import {
  getPlotData,
  getInitialData,
  updatePlotDim_,
} from "../../components/help";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSeqProgams,
  addSeqProg,
  addPlotData,
  addPlotKeys,
  updateFirstPlot,
  addPlotFirstData,
  addPlotLatestData,
  addPlotLatestDate,
  addLastRecordDate,
  addLastRecordData,
  addValidData,
  addPlotView,
  addDashObj,
  addSensors,
} from "../../redux/features/postSlice";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./imeidata.scss";

import Resizable from "react-resizable-box";
import LinePlot from "../../components/d3svg/LinePlot";
import LineScatterPlot from "../../components/d3svg/LineScatterPlot";
import AreaPlot from "../../components/d3svg/AreaPlot";
import ScatterPlot from "../../components/d3svg/ScatterPlot";
import LollipopPlot from "../../components/d3svg/LollipopPlot";
import SpeedoPlot from "../../components/d3svg/SpeedoPlot";
import SemiGaugePlot from "../../components/d3svg/SemiGaugePlot";
import SemiSpeedoPlot from "../../components/d3svg/SemiSpeedoPlot";
import ProgressPlot from "../../components/d3svg/ProgressPlot";

import DashContext from "../../context/dash/dashContext";

import {RgbaColorPicker } from "react-colorful";

const PlotSize = () => {
  const storeData = useSelector((state) => {
    return state.app;
  });
  const params = useParams();
  let imeiId = localStorage.getItem("imeiId");

  const updateMutation = useMutation(updatePlotDim_);

  const context = useContext(DashContext);
  const { showAlert } = context;

  const [plotDim, setPlotDim] = useState({
    plot_1_width: 600,
    plot_1_height: 400,
    plot_2_width: 600,
    plot_2_height: 400,
    plot_3_width: 600,
    plot_3_height: 400,
    plot_4_width: 600,
    plot_4_height: 400,
    plot_5_width: 600,
    plot_5_height: 400,
    plot_6_width: 600,
    plot_6_height: 400,
    plot_7_width: 600,
    plot_7_height: 400,
    plot_8_width: 600,
    plot_8_height: 400,
  });

  const [marginTB, setMarginTB] = useState();
  const [marginLR, setMarginLR] = useState();

  const [dashBG, setDashBG] = useState();

  const [dateFromTo, setDateFromTo] = useState({
    dateFrom1: "",
    dateTo1: "",
    dateFrom2: "",
    dateTo2: "",
    dateFrom3: "",
    dateTo3: "",
    dateFrom4: "",
    dateTo4: "",
    dateFrom5: "",
    dateTo5: "",
    dateFrom6: "",
    dateTo6: "",
    dateFrom7: "",
    dateTo7: "",
    dateFrom8: "",
    dateTo8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  const [plotSelected, setPlotSelected] = useState();

  const [fetchData, setFetchData] = useState({
    imei: imeiId,
    dateFrom1: "",
    dateTo1: "",
    plot1: "",
    key1: [],
    dateFrom2: "",
    dateTo2: "",
    plot2: "",
    key2: [],
    dateFrom3: "",
    dateTo3: "",
    plot3: "",
    key3: [],
    dateFrom4: "",
    dateTo4: "",
    plot4: "",
    key4: [],
    dateFrom5: "",
    dateTo5: "",
    plot5: "",
    key5: [],
    dateFrom6: "",
    dateTo6: "",
    plot6: "",
    key6: [],
    dateFrom7: "",
    dateTo7: "",
    plot7: "",
    key7: [],
    dateFrom8: "",
    dateTo8: "",
    plot8: "",
    key8: [],
    lastRecordRec1: "",
    lastRecordRec2: "",
    lastRecordRec3: "",
    lastRecordRec4: "",
    lastRecordRec5: "",
    lastRecordRec6: "",
    lastRecordRec7: "",
    lastRecordRec8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  let location = useLocation();
  let history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/");
    } else {
      dispatch(updateSeqProgams(1));
    }
  }, [location]);
  // useEffect(()=>{
  //   if(fetchData) {
  //     if(fetchData.length>0) {
  //       //console.log(fetchData)
  //     }
  //   }
  // },[fetchData])

  const { status, data, isFetching } = useQuery(
    ["progseq"],
    () =>
      getInitialData({
        imei: imeiId,
      }),
    {
      onSuccess: (e) => {
        // //console.log(e)
        if (storeData.seqUpdate == 1) {
          let newNote = {
            imei: imeiId,
            dateFrom1: "",
            dateTo1: "",
            plot1: "",
            key1: [],
            dateFrom2: "",
            dateTo2: "",
            plot2: "",
            key2: [],
            dateFrom3: "",
            dateTo3: "",
            plot3: "",
            key3: [],
            dateFrom4: "",
            dateTo4: "",
            plot4: "",
            key4: [],
            dateFrom5: "",
            dateTo5: "",
            plot5: "",
            key5: [],
            dateFrom6: "",
            dateTo6: "",
            plot6: "",
            key6: [],
            dateFrom7: "",
            dateTo7: "",
            plot7: "",
            key7: [],
            dateFrom8: "",
            dateTo8: "",
            plot8: "",
            key8: [],
            lastRecordRec1: "",
            lastRecordRec2: "",
            lastRecordRec3: "",
            lastRecordRec4: "",
            lastRecordRec5: "",
            lastRecordRec6: "",
            lastRecordRec7: "",
            lastRecordRec8: "",
            lastFrom1: "",
            lastTo1: "",
            lastFrom2: "",
            lastTo2: "",
            lastFrom3: "",
            lastTo3: "",
            lastFrom4: "",
            lastTo4: "",
            lastFrom5: "",
            lastTo5: "",
            lastFrom6: "",
            lastTo6: "",
            lastFrom7: "",
            lastTo7: "",
            lastFrom8: "",
            lastTo8: "",
          };
          dispatch(updateSeqProgams(0));
          dispatch(updateFirstPlot(0));
          let tempProg = [];
          let tempKey = e.keys;
          let newDim = {};
          let newPlot = e.plotView;
          let i = 0;
          var j = 0;
            // //console.log(hexAToRGBA(e.seqProg[0]["dashbg"]))
          setMarginLR(e.seqProg[0]["marginLR"]);
          setMarginTB(e.seqProg[0]["marginTB"]);
          setDashBG(hexAToRGBA(e.seqProg[0]["dashbg"]));
          //   [`${r[3]}_width`]: Math.round(z.width)
          //   [`${r[3]}_height`]: Math.round(z.height)
          for (i = 0; i < 8; i++) {
            let recProg = e.seqProg.map((e) => {
              if (e["ainplot"] === Number(i + 1)) {
                newNote[`plot${i + 1}`] = "ainplot";
                // //console.log(tempKey['ainplot']);
                newNote[`key${i + 1}`] = tempKey["ainplot"];
                newDim[`plot_${i + 1}_width`] = newPlot["ainplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["ainplot"][0].dash_h;
                return { plot: "ainplot", progseq: `${i + 1}` };
              } else if (e["digplot"] === Number(i + 1)) {
                newNote[`plot${i + 1}`] = "digplot";
                newNote[`key${i + 1}`] = tempKey["digplot"];
                newDim[`plot_${i + 1}_width`] = newPlot["digplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["digplot"][0].dash_h;
                return { plot: "digplot", progseq: `${i + 1}` };
              } else if (e["isoplot"] === Number(i + 1)) {
                newNote[`key${i + 1}`] = tempKey["isoplot"];
                newNote[`plot${i + 1}`] = "isoplot";
                newDim[`plot_${i + 1}_width`] = newPlot["isoplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["isoplot"][0].dash_h;
                return { plot: "isoplot", progseq: `${i + 1}` };
              } else if (e["nasplot"] === Number(i + 1)) {
                newNote[`key${i + 1}`] = tempKey["nasplot"];
                newNote[`plot${i + 1}`] = "nasplot";
                newDim[`plot_${i + 1}_width`] = newPlot["nasplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["nasplot"][0].dash_h;
                return { plot: "nasplot", progseq: `${i + 1}` };
              } else if (e["pulseplot"] === Number(i + 1)) {
                newNote[`key${i + 1}`] = tempKey["pulseplot"];
                newNote[`plot${i + 1}`] = "pulseplot";
                newDim[`plot_${i + 1}_width`] = newPlot["pulseplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["pulseplot"][0].dash_h;
                return { plot: "pulseplot", progseq: `${i + 1}` };
              } else if (e["saeplot"] === Number(i + 1)) {
                newNote[`key${i + 1}`] = tempKey["saeplot"];
                newNote[`plot${i + 1}`] = "saeplot";
                newDim[`plot_${i + 1}_width`] = newPlot["saeplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["saeplot"][0].dash_h;
                return { plot: "saeplot", progseq: `${i + 1}` };
              } else if (e["sigplot"] === Number(i + 1)) {
                newNote[`key${i + 1}`] = tempKey["sigplot"];
                newNote[`plot${i + 1}`] = "sigplot";
                newDim[`plot_${i + 1}_width`] = newPlot["sigplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["sigplot"][0].dash_h;
                return { plot: "sigplot", progseq: `${i + 1}` };
              } else if (e["tempplot"] === Number(i + 1)) {
                newNote[`key${i + 1}`] = tempKey["tempplot"];
                newNote[`plot${i + 1}`] = "tempplot";
                newDim[`plot_${i + 1}_width`] = newPlot["tempplot"][0].dash_w;
                newDim[`plot_${i + 1}_height`] = newPlot["tempplot"][0].dash_h;
                return { plot: "tempplot", progseq: `${i + 1}` };
              }
            });
            if (recProg[0] !== undefined) {
              // //console.log(recProg)
              tempProg[j] = recProg;
              j += 1;
            }
          }
          dispatch(addSeqProg(tempProg));
          dispatch(addPlotData(e.data));
          dispatch(addPlotFirstData(e.data));
          dispatch(addPlotKeys(e.keys));
          dispatch(addLastRecordDate(e.lastRecordDate));
          dispatch(addValidData(e.validData));
          dispatch(addPlotView(e.plotView));
          dispatch(addLastRecordData(e.lastRecordValues));
          dispatch(addSensors(e.sensors));
          dispatch(addDashObj(newNote));
          //console.log(tempProg);
          setFetchData(newNote);
          setPlotDim(newDim);
          // //console.log(newNote);
        }
        //settime()
      },
    }
  );

  const c_user = async (user) => {
    await updateMutation.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        if (e.success == true) {
          showAlert("Plot Updated", "success");
        } else {
          showAlert("Plot Can't Be Updated", "danger");
        }
        // queryClient.invalidateQueries(["progseq"]);
      },
      onError: () => {
        showAlert("Plot Can't Be Updated", "danger");
      },
    });
  };

  function dummyFetch(f) {}
  const { data1 } = useQuery(
    ["product"],
    () => {
      if (storeData.dashObj.imei) {
        return getPlotData(storeData.dashObj);
      } else {
        return getPlotData(fetchData);
      }
    },
    {
      onSuccess: (e) => {
        let i = 0;
        let j = 0;
        // //console.log(storeData.progSeq[1][0].plot)
        let newNote = {
          imei: imeiId,
          dateFrom1: "",
          dateTo1: "",
          plot1: "",
          key1: [],
          dateFrom2: "",
          dateTo2: "",
          plot2: "",
          key2: [],
          dateFrom3: "",
          dateTo3: "",
          plot3: "",
          key3: [],
          dateFrom4: "",
          dateTo4: "",
          plot4: "",
          key4: [],
          dateFrom5: "",
          dateTo5: "",
          plot5: "",
          key5: [],
          dateFrom6: "",
          dateTo6: "",
          plot6: "",
          key6: [],
          dateFrom7: "",
          dateTo7: "",
          plot7: "",
          key7: [],
          dateFrom8: "",
          dateTo8: "",
          plot8: "",
          key8: [],
          lastRecordRec1: "",
          lastRecordRec2: "",
          lastRecordRec3: "",
          lastRecordRec4: "",
          lastRecordRec5: "",
          lastRecordRec6: "",
          lastRecordRec7: "",
          lastRecordRec8: "",
          lastFrom1: "",
          lastTo1: "",
          lastFrom2: "",
          lastTo2: "",
          lastFrom3: "",
          lastTo3: "",
          lastFrom4: "",
          lastTo4: "",
          lastFrom5: "",
          lastTo5: "",
          lastFrom6: "",
          lastTo6: "",
          lastFrom7: "",
          lastTo7: "",
          lastFrom8: "",
          lastTo8: "",
        };
        dispatch(addPlotData(e.data));
        // //console.log(e.latestData['ainplot'].length)
        dispatch(addValidData(e.validData));
        dispatch(addPlotLatestData(e.latestData));
        dispatch(addPlotLatestDate(e.latestDate));
        dispatch(addLastRecordDate(e.lastRecordDate));
        dispatch(addLastRecordData(e.lastRecordValues));
        if (storeData.progSeq) {
          if (storeData.progSeq.length > 0) {
            for (i = 0; i < 8; i++) {
              newNote[`lastFrom${i + 1}`] = fetchData[`lastFrom${i + 1}`];
              newNote[`lastTo${i + 1}`] = fetchData[`lastTo${i + 1}`];
              newNote[`plot${i + 1}`] = fetchData[`plot${i + 1}`];
              newNote[`key${i + 1}`] = fetchData[`key${i + 1}`];

              newNote[`lastFrom${i + 1}`] =
                storeData.dashObj[`lastFrom${i + 1}`];
              newNote[`lastTo${i + 1}`] = storeData.dashObj[`lastTo${i + 1}`];
              newNote[`plot${i + 1}`] = storeData.dashObj[`plot${i + 1}`];
              newNote[`key${i + 1}`] = storeData.dashObj[`key${i + 1}`];
            }
            if (e.latestDate) {
              // //console.log(e.latestDate)
              for (i = 0; i < storeData.progSeq.length; i++) {
                if (e.latestDate[storeData.progSeq[i][0].plot]) {
                  newNote[`lastRecordRec${i + 1}`] =
                    e.latestDate[storeData.progSeq[i][0].plot];
                } else {
                  newNote[`lastRecordRec${i + 1}`] =
                    fetchData[`lastRecordRec${i + 1}`];
                }
              }
            } else {
              for (i = 0; i < storeData.progSeq.length; i++) {
                newNote[`lastRecordRec${i + 1}`] =
                  fetchData[`lastRecordRec${i + 1}`];
              }
            }
          }
        }
        if (storeData.dashObj.imei && e.imei) {
          // //console.log(storeData.dashObj)
          dispatch(addDashObj(newNote));
        }
        if (fetchData.imei && e.imei) {
          setFetchData(newNote);
        }
        // //console.log(storeData.dashObj.imei);
        // //console.log(e);
        // //console.log(newNote);
      },
      refetchIntervalInBackground: true,
      refetchInterval: 4000,
    }
  );

  const handleDragEnd = (results, i, j) => {
    // //console.log(results, i, j.className[2]);
    // //console.log(results.clientX);
    let r = j.className.split(" ");
    let k = document.getElementsByClassName(r[3]);
    let z = k[0].getBoundingClientRect();
    // //console.log(Math.round(z.width));
    // //console.log(Math.round(z.height));
    setPlotDim({ width: Math.round(z.width), height: Math.round(z.height) });

    setPlotDim({
      ...plotDim,
      [`${r[3]}_width`]: Math.round(z.width),
      [`${r[3]}_height`]: Math.round(z.height),
    });
    // //console.log(r[3]);
  };

  const handleDatePicker = (value, states) => {
    let str = 0;
    let str_ = 0;
    let newDate = new Date(value[0]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // setFromDate(str)
    // //console.log(str);
    newDate = new Date(value[1]);
    str_ = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // //console.log(str);
    // setToDate(str)
    // //console.log(str);
    ////console.log(value);
    ////console.log(states);
    ////console.log(plotSelected);
    setDateFromTo({
      ...dateFromTo,
      [`dateFrom${plotSelected}`]: str,
      [`dateTo${plotSelected}`]: str_,
      [`lastFrom${plotSelected}`]: str,
      [`lastTo${plotSelected}`]: str_,
    });

    if (str.length > 2 && str_.length > 2) {
      let newNote = {
        imei: "",
        dateFrom1: "",
        dateTo1: "",
        plot1: "",
        key1: [],
        dateFrom2: "",
        dateTo2: "",
        plot2: "",
        key2: [],
        dateFrom3: "",
        dateTo3: "",
        plot3: "",
        key3: [],
        dateFrom4: "",
        dateTo4: "",
        plot4: "",
        key4: [],
        dateFrom5: "",
        dateTo5: "",
        plot5: "",
        key5: [],
        dateFrom6: "",
        dateTo6: "",
        plot6: "",
        key6: [],
        dateFrom7: "",
        dateTo7: "",
        plot7: "",
        key7: [],
        dateFrom8: "",
        dateTo8: "",
        plot8: "",
        key8: [],
        lastRecordRec1: "",
        lastRecordRec2: "",
        lastRecordRec3: "",
        lastRecordRec4: "",
        lastRecordRec5: "",
        lastRecordRec6: "",
        lastRecordRec7: "",
        lastRecordRec8: "",
        lastFrom1: "",
        lastTo1: "",
        lastFrom2: "",
        lastTo2: "",
        lastFrom3: "",
        lastTo3: "",
        lastFrom4: "",
        lastTo4: "",
        lastFrom5: "",
        lastTo5: "",
        lastFrom6: "",
        lastTo6: "",
        lastFrom7: "",
        lastTo7: "",
        lastFrom8: "",
        lastTo8: "",
      };
      let i = 0;
      let j = 0;
      newNote.imei = imeiId;
      for (i = 0; i < 8; i++) {
        if (i === plotSelected - 1) {
          newNote[`dateFrom${i + 1}`] = str;
          newNote[`dateTo${i + 1}`] = str_;
          newNote[`lastFrom${i + 1}`] = str;
          newNote[`lastTo${i + 1}`] = str_;
        } else {
          newNote[`dateFrom${i + 1}`] = dateFromTo[`dateFrom${i + 1}`];
          newNote[`dateTo${i + 1}`] = dateFromTo[`dateTo${i + 1}`];
          newNote[`lastFrom${i + 1}`] = dateFromTo[`lastFrom${i + 1}`];
          newNote[`lastTo${i + 1}`] = dateFromTo[`lastTo${i + 1}`];
        }
      }
      if (storeData.progSeq[0]) {
        newNote.plot1 = storeData.progSeq[0][0].plot;
        newNote.key1 = storeData.plotKeys[`${storeData.progSeq[0][0].plot}`];
      }
      if (storeData.progSeq[1]) {
        newNote.plot2 = storeData.progSeq[1][0].plot;
        newNote.key2 = storeData.plotKeys[`${storeData.progSeq[1][0].plot}`];
      }
      if (storeData.progSeq[2]) {
        newNote.plot3 = storeData.progSeq[2][0].plot;
        newNote.key3 = storeData.plotKeys[`${storeData.progSeq[2][0].plot}`];
      }
      if (storeData.progSeq[3]) {
        newNote.plot4 = storeData.progSeq[3][0].plot;
        newNote.key4 = storeData.plotKeys[`${storeData.progSeq[3][0].plot}`];
      }
      if (storeData.progSeq[4]) {
        newNote.plot5 = storeData.progSeq[4][0].plot;
        newNote.key5 = storeData.plotKeys[`${storeData.progSeq[4][0].plot}`];
      }
      if (storeData.progSeq[5]) {
        newNote.plot6 = storeData.progSeq[5][0].plot;
        newNote.key6 = storeData.plotKeys[`${storeData.progSeq[5][0].plot}`];
      }
      if (storeData.progSeq[6]) {
        newNote.plot7 = storeData.progSeq[6][0].plot;
        newNote.key7 = storeData.plotKeys[`${storeData.progSeq[6][0].plot}`];
      }
      if (storeData.progSeq[7]) {
        newNote.plot8 = storeData.progSeq[7][0].plot;
        newNote.key8 = storeData.plotKeys[`${storeData.progSeq[7][0].plot}`];
      }
      dispatch(addDashObj(newNote));
      setFetchData(newNote);

      //console.log(newNote);
    }
  };

  const handleOk = (f, t, p) => {
    //console.log(dateFromTo[`dateFrom${p}`]);
    //console.log(dateFromTo[`dateTo${p}`]);
    setPlotSelected(p);
  };

  function addDummyJson(keys) {
    let i = 0;
    let j = 0;
    let l = {};
    for (j = 0; j < keys.length; j++) {
      l[keys[j]] = 0;
    }
    l["datedevice"] = "2020-9-13";
    //console.log(l);
    return l;
  }

  const updatePlotDim = (e) => {
    let i = 0;
    let j = 0;
    let dimObj = {};
    //console.log(storeData.progSeq.length);
    for (i = 0; i < storeData.progSeq.length; i++) {
      for (j = 0; j < storeData.progSeq.length; j++) {
        if (storeData.progSeq[j][0].progseq == Number(i + 1)) {
          dimObj[`${storeData.progSeq[j][0].plot}`] = {
            [`width`]: plotDim[`plot_${i + 1}_width`],
            [`height`]: plotDim[`plot_${i + 1}_height`],
          };
          break;
        }
      }
    }
    dimObj["imei"] = imeiId;
    dimObj[`marginTB`] = marginTB;
    dimObj[`marginLR`] = marginLR;
    dimObj['dashbg'] = RGBAToHexA(dashBG.r,dashBG.g,dashBG.b,dashBG.a)
    //console.log(dimObj);
    c_user(dimObj);
  };

  const setMarginTopBott = (e) => {
    setMarginTB(e.target.value);
  };
  const setMarginLeftRight = (e) => {
    setMarginLR(e.target.value);
  };

  function RGBAToHexA(r, g, b, a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;

    return "#" + r + g + b + a;
  }
  function hexAToRGBA(h) {
    let r = 0,
      g = 0,
      b = 0,
      a = 1,
      d = 0;
    // //console.log(h.length);
    if (h.length === 7) {
      r = Number("0x" + h[1] + h[2]);
      g = Number("0x" + h[3] + h[4]);
      b = Number("0x" + h[5] + h[6]);
      d = 0;
    } else if (h.length === 9) {
      r = Number("0x" + h[1] + h[2]);
      g = Number("0x" + h[3] + h[4]);
      b = Number("0x" + h[5] + h[6]);
      a = "0x" + h[7] + h[8];
      a = +(a / 255).toFixed(3);
      d = 1;
    }
    if (d === 1) {
      return { r: r, g: g, b: b, a: a };
    }
    return { r: r, g: g, b: b, a: 1 };
  }
  const changeDashBgColor = (e) => {
    setDashBG(e);
  };
  let collapse_plot_misc = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "200px",
    marginBottom: "300px",
  };

  return (
    <>
      <div id={`dash_bg`} style={{ background: `${dashBG  ? `rgba(${dashBG.r},${dashBG.g},${dashBG.b},${dashBG.a})`: "rgba(255,255,255)"}` }}>
        <h2
          style={{ textAlign: "center" }}
          className="my-3"
          id={`imei6_${imeiId}`}
        >
          {`Device : ${params.imei}`}{" "}
          {localStorage.getItem("loginType") === "Admin"
            ? `User: ${params.user}`
            : `Location: ${localStorage.getItem("imeiLocation")}`}
        </h2>
        <h3
          style={{ textAlign: "center" }}
          className="my-3"
          id={`imei7_${imeiId}`}
        >
          {localStorage.getItem("imeiEquipment")
            ? `Equipment: ${localStorage.getItem("imeiEquipment")}`
            : ""}
        </h3>
        <h4
          style={{ textAlign: "center" }}
          className="my-3"
          id={`imei7_${imeiId}_2`}
        >
          {"Plot Size"}
        </h4>
        <div className="mb-1 d-flex flex-row align-items-center justify-content-center flex-wrap">
          <button className="btn btn-primary my-1" onClick={updatePlotDim}>
            Save Dimensions
          </button>
          <label
            htmlFor="margin_tb"
            className="form-label mx-2"
            style={{ fontSize: "18px" }}
          >
            Margin TB :
          </label>
          <input
            type="number"
            className="form-control mx-2"
            id="margin_tb"
            name="margin_tb"
            onChange={setMarginTopBott}
            value={marginTB?marginTB:1}
            min={1}
            style={{ width: "120px", textAlign: "center", height: "35px" }}
          />

          <label
            htmlFor="margin_lr"
            className="form-label mx-2"
            style={{ fontSize: "18px" }}
          >
            Margin LR :
          </label>
          <input
            type="number"
            className="form-control mx-2"
            id="margin_lr"
            name="margin_lr"
            onChange={setMarginLeftRight}
            value={marginLR?marginLR:1}
            min={1}
            style={{ width: "120px", textAlign: "center", height: "35px" }}
          />
          <div
            style={{
              background: `${
                dashBG
                  ? `rgba(${dashBG.r},${dashBG.g},${dashBG.b},${dashBG.a})`
                  : "rgba(255,255,255)"
              }`,
            }}
            className=" mx-2"
          >
            <p className={`options_plot_misc`}>
              <button
                className={`btn btn-primary btn-sm bottom_plot_elements options_button_misc`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapseExample_misc`}
                aria-expanded="false"
                aria-controls="collapseExample"
                style={{
                  background: `${
                    dashBG
                      ? `rgba(${dashBG.r},${dashBG.g},${dashBG.b},${dashBG.a})`
                      : "rgba(255,255,255)"
                  }`,
                }}
              >
                Color
              </button>
            </p>
          </div>
          <div
            className="collapse"
            id={`collapseExample_misc`}
            style={collapse_plot_misc}
          >
            <div className={`card card-body`}>
              <RgbaColorPicker
                color={dashBG}
                onChange={changeDashBgColor}
              />
            </div>
          </div>
        </div>
        <div
          className="mainContainer"
          id={`main_Plot`}
          style={{ width: "100%", padding: "10px" }}
        >
          {/* { plot: "isoplot", progseq: `${i + 1}` } */}
          {storeData.progSeq && storeData.progSeq.length
            ? storeData.progSeq.map((prog, i) => {
                let r = 0;
                let h = 0;
                let l = 0;
                let q = 0;
                let r1 = 0;
                // //console.log(prog[0].plot);
                // //console.log(PROPS_)
                // //console.log(storeData.plotView[`${prog[0].plot}`])
                // //console.log(`${prog[0].plot}:`,storeData.validData[`${prog[0].plot}`])
                // /
                if (storeData.plotData[`${prog[0].plot}`]) {
                  if (storeData.plotData[`${prog[0].plot}`].length > 0)
                    r = storeData.plotData[`${prog[0].plot}`];
                } else {
                  // r=addDummyJson(storeData.plotKeys[`${prog[0].plot}`])
                  r = {};
                }
                if (storeData.plotLatestData[`${prog[0].plot}`]) {
                  if (storeData.plotLatestData[`${prog[0].plot}`].length > 0) {
                    q = storeData.plotLatestData[`${prog[0].plot}`];
                  } else {
                    q = {};
                  }
                } else {
                  q = {};
                }
                if (storeData.lastRecordData[`${prog[0].plot}`]) {
                  if (storeData.lastRecordData[`${prog[0].plot}`]) {
                    r1 = storeData.lastRecordData[`${prog[0].plot}`];
                  } else {
                    r1 = {};
                  }
                } else {
                  r1 = {};
                }
                // //console.log(storeData.lastRecordData[`${prog[0].plot}`])
                if (i === 0) {
                  h = 30;
                  l = -1;
                } else if (i === 1) {
                  h = 2;
                  l = -1;
                }
                let PROPS_ = {
                  plot_id: i + 1,
                  title: "Title",
                  width: plotDim[`plot_${i + 1}_width`],
                  height: plotDim[`plot_${i + 1}_height`],
                  imei: imeiId,
                  datePicker: handleDatePicker,
                  handleOk: handleOk,
                  plotData: r,
                  plotKeys: storeData.plotKeys[`${prog[0].plot}`],
                  plot_: prog[0].plot,
                  highLimit: h,
                  lowLimit: l,
                  latestData: q,
                  latestDate: storeData.lastRecordDate["datedevice"],
                  lastRecordData: storeData.lastRecordData[`${prog[0].plot}`],
                  validData: storeData.validData[`${prog[0].plot}`],
                  plotView: storeData.plotView[`${prog[0].plot}`],
                  plotType: `${prog[0].plot}`,
                };
                // //console.log(storeData.plotView[`${prog[0].plot}`][0].graphtype)
                switch (storeData.plotView[`${prog[0].plot}`][0].graphtype) {
                  case "LineScatter":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <LineScatterPlot {...PROPS_}></LineScatterPlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "Line":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <LinePlot {...PROPS_}></LinePlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "Scatter":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <ScatterPlot {...PROPS_}></ScatterPlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "Lollipop":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <LollipopPlot {...PROPS_}></LollipopPlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "Area":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <AreaPlot {...PROPS_}></AreaPlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "SemiGauge":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <SemiGaugePlot {...PROPS_}></SemiGaugePlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "SemiSpeedo":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <SemiSpeedoPlot {...PROPS_}></SemiSpeedoPlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "Speedo":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <SpeedoPlot {...PROPS_}></SpeedoPlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                  case "Progress":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotDim[`plot_${i + 1}_width`]}
                        height={plotDim[`plot_${i + 1}_height`]}
                        minWidth={450}
                        minHeight={300}
                        key={prog[0].plot}
                        onResize={handleDragEnd}
                        id={i}
                        style={{ margin: `${marginTB}px ${marginLR}px` }}
                      >
                        <ProgressPlot {...PROPS_}></ProgressPlot>
                        {/* {prog[0].plot} */}
                      </Resizable>
                    );
                    break;
                }
              })
            : ""}
        </div>
      </div>

      {localStorage.getItem("loginType") === "Admin" ? (
        <ReactTooltip
          anchorId={`imei6_${imeiId}`}
          place="top"
          content={`Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} Location: ${localStorage.getItem("imeiLocation")}`}
          multiline={true}
          html={`Device Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} 
                 Company: ${localStorage.getItem("companyName")}<br />
                 Customer: ${localStorage.getItem("customerName")}`}
          //localStorage.getItem('imeiLocation')
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PlotSize;
