import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashContext from "../../context/dash/dashContext";
import { useMutation, useQuery,useQueryClient } from '@tanstack/react-query';
import { createUsers,getUsers } from "../help";

const AddUser = () => {
  const{status,data,isFetching}=useQuery(['users'],getUsers)
  const addMutation=useMutation(createUsers)
    let location = useLocation();
    let history = useNavigate();
    const context=useContext(DashContext)
    const {addUser,getAllUsers,users,showAlert}=context
  const ref = useRef(null);
  const refClose = useRef(null);
  const [user, setUser] = useState({
    name: "",
    customername: "",
    companyname: "",
    customerno: "",
    companyaddress: "",
    active: true,
    password: "",
  });
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };
  const updateUser = () => {
    setUser({
      customername: "",
      name: "",
      password: "",
      active: true,
      companyname: "",
      customerno: "",
      companyaddress: "",
    });
  };
  const queryClient=useQueryClient()
  const c_user=async(user)=>{
    await addMutation.mutate(user,{
      onSuccess:(e)=>{
        //console.log(e)
        if(e.success==true) {
          showAlert("User Created","success")
        }
        else {
          showAlert("User Can't Be Created","danger")
        }
        queryClient.invalidateQueries(['users'])
    },
    onError:()=>{
      showAlert("User Can't Be Created","danger")
    }
    })
  }
  const handleCreateUser=()=>{
    ref.current.click();
    //console.log(user);
    // addUser(user);
    c_user(user)
    // getAllUsers();
    localStorage.setItem('TableUpdate','Yes');
    localStorage.setItem('operation','ADD');
  }
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary my-3 mx-3"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal1"
        onClick={updateUser}
        ref={ref}
      >
        Add User
      </button>
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add User
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
            <form action="" className="my-3">
                <div className="mb-3">
                  <label htmlFor="name1" className="form-label">
                    User Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name1"
                    name="name"
                    onChange={onChange}
                    value={user.name}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password1"
                    name="password"
                    onChange={onChange}
                    value={user.password}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="companyName1" className="form-label">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyname1"
                    name="companyname"
                    onChange={onChange}
                    value={user.companyname}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="companyaddress1" className="form-label">
                    Company Address
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="companyaddress1"
                    name="companyaddress"
                    onChange={onChange}
                    value={user.companyaddress}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="customername1" className="form-label">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="customername1"
                    name="customername"
                    onChange={onChange}
                    value={user.customername}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="customerno1" className="form-label">
                    Customer No
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="customerno1"
                    name="customerno"
                    onChange={onChange}
                    value={user.customerno}
                  />
                </div>
                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    id="inputGroupSelect01"
                    onChange={onChange}
                    value={user.active}
                    name="active"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Deactive</option>
                  </select>
                  <label
                    className="input-group-text"
                    htmlFor="inputGroupSelect01"
                  >
                    Status
                  </label>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary"
              onClick={handleCreateUser}
              >
                Create User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
