import React from 'react'
import PersonIcon from "@mui/icons-material/Person";

const User = () => {
  return (
    <>
      <PersonIcon
        className="mx-2"
        // style={{ cursor: "pointer" }}
        // onClick={handleAdminSettings}
      ></PersonIcon>
      <div className="badge bg-primary text-wrap" style={{ width: "6rem" }}>
        {localStorage.getItem('loginName')}
      </div>
    </>
  )
}

export default User
