import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import "./navbar.scss";
import Admin from "../admin/Admin";
import User from "../user/User";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const storeData = useSelector((state) => {
    return state.app;
  });
  let location = useLocation();
  let history = useNavigate();
  const [loca, setLoca] = useState(" ");
  useEffect(() => {
    // console.log(location.pathname);
    setLoca(location.pathname);
  }, [location]);
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    // console.log("Logged out");
    history("/");
  };
  const handleUserSettings = (e) => {
    e.preventDefault();
    // history("/dummy");
  };

  return (
    <>
      {localStorage.getItem("token") ? (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              Wiman
            </Link>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {localStorage.getItem("loginType") === "Admin" ? (
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        location.pathname === "/" ? "active" : ""
                      }`}
                      aria-current="page"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li className="nav-item">
                  {loca.search("devices") !== -1 ||
                  loca.search("dashboard") !== -1 ||
                  loca.search("ainplot") !== -1 ||
                  loca.search("digplot") !== -1 ||
                  loca.search("nasplot") !== -1 ||
                  loca.search("isoplot") !== -1 ||
                  loca.search("saeplot") !== -1 ||
                  loca.search("sigplot") !== -1 ||
                  loca.search("tempplot") !== -1 ||
                  loca.search("pulseplot") !== -1 ||
                  loca.search("ainsetting") !== -1 ||
                  loca.search("digsetting") !== -1 ||
                  loca.search("nassetting") !== -1 ||
                  loca.search("isosetting") !== -1 ||
                  loca.search("saesetting") !== -1 ||
                  loca.search("sigsetting") !== -1 ||
                  loca.search("tempsetting") !== -1 ||
                  loca.search("pulsesetting") !== -1 ||
                  loca.search("plotsequence") !== -1 ||
                  loca.search("plotsize") !== -1 ? (
                    <Link
                      className={`nav-link ${
                        location.pathname ===
                        `/devices/${localStorage.getItem(
                          "userId"
                        )}/${localStorage.getItem("userName")}`
                          ? "active"
                          : ""
                      }`}
                      to={`/devices/${localStorage.getItem(
                        "userId"
                      )}/${localStorage.getItem("userName")}`}
                    >
                      Devices
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
                <li className="nav-item">
                  {loca.search("dashboard") !== -1 ||
                  loca.search("ainplot") !== -1 ||
                  loca.search("digplot") !== -1 ||
                  loca.search("nasplot") !== -1 ||
                  loca.search("isoplot") !== -1 ||
                  loca.search("saeplot") !== -1 ||
                  loca.search("sigplot") !== -1 ||
                  loca.search("tempplot") !== -1 ||
                  loca.search("pulseplot") !== -1 ||
                  loca.search("ainsetting") !== -1 ||
                  loca.search("digsetting") !== -1 ||
                  loca.search("nassetting") !== -1 ||
                  loca.search("isosetting") !== -1 ||
                  loca.search("saesetting") !== -1 ||
                  loca.search("sigsetting") !== -1 ||
                  loca.search("tempsetting") !== -1 ||
                  loca.search("pulsesetting") !== -1 ||
                  loca.search("plotsequence") !== -1 ||
                  loca.search("plotsize") !== -1 ? (
                    <Link
                      className={`nav-link ${
                        location.pathname ===
                        `/dashboard/${localStorage.getItem(
                          "userId"
                        )}/${localStorage.getItem(
                          "userName"
                        )}/${localStorage.getItem("imeiNo")}`
                          ? "active"
                          : ""
                      }`}
                      to={`/dashboard/${localStorage.getItem(
                        "userId"
                      )}/${localStorage.getItem(
                        "userName"
                      )}/${localStorage.getItem("imeiNo")}`}
                    >
                      Dashboard
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
                {loca.search("dashboard") !== -1 ||
                loca.search("ainplot") !== -1 ||
                loca.search("digplot") !== -1 ||
                loca.search("nasplot") !== -1 ||
                loca.search("isoplot") !== -1 ||
                loca.search("saeplot") !== -1 ||
                loca.search("sigplot") !== -1 ||
                loca.search("tempplot") !== -1 ||
                loca.search("pulseplot") !== -1 ||
                loca.search("ainsetting") !== -1 ||
                loca.search("digsetting") !== -1 ||
                loca.search("nassetting") !== -1 ||
                loca.search("isosetting") !== -1 ||
                loca.search("saesetting") !== -1 ||
                loca.search("sigsetting") !== -1 ||
                loca.search("tempsetting") !== -1 ||
                loca.search("pulsesetting") !== -1 ||
                loca.search("plotsequence") !== -1 ||
                loca.search("plotsize") !== -1 ? (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sensors
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {storeData.progSeq
                        ? storeData.progSeq.map((prog, i) => {
                            // console.log(prog[0].plot);
                            return (
                              <Link
                                key={i}
                                className={`nav-link ${
                                  location.pathname ===
                                  `/${prog[0].plot}/${localStorage.getItem(
                                    "userId"
                                  )}/${localStorage.getItem(
                                    "userName"
                                  )}/${localStorage.getItem("imeiNo")}`
                                    ? "active"
                                    : ""
                                }`}
                                to={`/${prog[0].plot}/${localStorage.getItem(
                                  "userId"
                                )}/${localStorage.getItem(
                                  "userName"
                                )}/${localStorage.getItem("imeiNo")}`}
                              >
                                {storeData.sensors[`${prog[0].plot}`]}
                              </Link>
                            );
                          })
                        : ""}
                    </div>
                  </li>
                ) : (
                  ""
                )}

                {loca.search("dashboard") !== -1 ||
                loca.search("ainplot") !== -1 ||
                loca.search("digplot") !== -1 ||
                loca.search("nasplot") !== -1 ||
                loca.search("isoplot") !== -1 ||
                loca.search("saeplot") !== -1 ||
                loca.search("sigplot") !== -1 ||
                loca.search("tempplot") !== -1 ||
                loca.search("pulseplot") !== -1 ||
                loca.search("ainsetting") !== -1 ||
                loca.search("digsetting") !== -1 ||
                loca.search("nassetting") !== -1 ||
                loca.search("isosetting") !== -1 ||
                loca.search("saesetting") !== -1 ||
                loca.search("sigsetting") !== -1 ||
                loca.search("tempsetting") !== -1 ||
                loca.search("pulsesetting") !== -1 ||
                loca.search("plotsequence") !== -1 ||
                loca.search("plotsize") !== -1 ? (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Settings
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {storeData.progSeq
                        ? storeData.progSeq.map((prog, i) => {
                            // console.log(prog[0].plot);
                            return (
                              <Link
                                key={i}
                                className={`nav-link ${
                                  location.pathname ===
                                  `/${
                                    prog[0].plot
                                  }setting/${localStorage.getItem(
                                    "userId"
                                  )}/${localStorage.getItem(
                                    "userName"
                                  )}/${localStorage.getItem("imeiNo")}`
                                    ? "active"
                                    : ""
                                }`}
                                to={`/${
                                  prog[0].plot
                                }setting/${localStorage.getItem(
                                  "userId"
                                )}/${localStorage.getItem(
                                  "userName"
                                )}/${localStorage.getItem("imeiNo")}`}
                              >
                                {storeData.sensors[`${prog[0].plot}`]}
                              </Link>
                            );
                          })
                        : ""}
                      <Link
                        className={`nav-link ${
                          location.pathname ===
                          `/plotsequence/${localStorage.getItem(
                            "userId"
                          )}/${localStorage.getItem(
                            "userName"
                          )}/${localStorage.getItem("imeiNo")}`
                            ? "active"
                            : ""
                        }`}
                        to={`/plotsequence/${localStorage.getItem(
                          "userId"
                        )}/${localStorage.getItem(
                          "userName"
                        )}/${localStorage.getItem("imeiNo")}`}
                      >
                        Plot Sequence
                      </Link>
                      <Link
                        className={`nav-link ${
                          location.pathname ===
                          `/plotsize/${localStorage.getItem(
                            "userId"
                          )}/${localStorage.getItem(
                            "userName"
                          )}/${localStorage.getItem("imeiNo")}`
                            ? "active"
                            : ""
                        }`}
                        to={`/plotsize/${localStorage.getItem(
                          "userId"
                        )}/${localStorage.getItem(
                          "userName"
                        )}/${localStorage.getItem("imeiNo")}`}
                      >
                        Plot Size
                      </Link>
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {/* <li className="nav-item">
                  <Link className={`nav-link ${location.pathname==="/about"?"active":""}`} to="/about">
                    About
                  </Link>
                </li> */}
              </ul>
              <button className="btn btn-primary mx-2" onClick={handleLogout}>
                Logout
              </button>
              {localStorage.getItem("loginType") === "Admin" ? (
                <Admin></Admin>
              ) : (
                <User></User>
              )}
            </div>
          </div>
        </nav>
      ) : (
        <></>
      )}
    </>
  );
};

export default Navbar;
