import React from 'react'
import './about.scss'

const About = () => {
  return (
    <div className='about'>
      About
    </div>
  )
}

export default About;
