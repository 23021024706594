import {
  Route,
  Link,
  Routes,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from "react";

import {
  getInitialData,
  updateAin,
} from "../../components/help";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSeqProgams,
  addSeqProg,
  addPlotData,
  addPlotKeys,
  updateFirstPlot,
  addPlotFirstData,
  addLastRecordDate,
  addLastRecordData,
  addValidData,
  addPlotView,
  addDashObj,
  addSensors,
} from "../../redux/features/postSlice";

import $ from "jquery";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./imeidata.scss";

import Resizable from "react-resizable-box";
import LineScatterPlot from "../../components/d3svg/LineScatterPlot";
import AreaPlot from "../../components/d3svg/AreaPlot";
import ScatterPlot from "../../components/d3svg/ScatterPlot";
import LollipopPlot from "../../components/d3svg/LollipopPlot";

import { useReactToPrint } from "react-to-print";

import ProgressPlot from "../../components/d3svg/ProgressPlot";
import SpeedoPlot from "../../components/d3svg/SpeedoPlot";
import SemiSpeedoPlot from "../../components/d3svg/SemiSpeedoPlot";
import SemiGaugePlot from "../../components/d3svg/SemiGaugePlot";

import LinePlot from "../../components/d3svg/LinePlot";
import ProgressPulsePara from "./ProgressPulsePara";
import NonSpeedoPulsePara from "./NonSpeedoPulsePara";

import DashContext from "../../context/dash/dashContext";

const AinSettingPlot = () => {
  const updateMutation=useMutation(updateAin)
  const context = useContext(DashContext);
  const { showAlert } = context;
  const storeData = useSelector((state) => {
    return state.app;
  });

  const params = useParams();
  let imeiId = localStorage.getItem("imeiId");

  let location = useLocation();
  let history = useNavigate();
  const dispatch = useDispatch();

  const updateRef = useRef(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/");
    } else {
      dispatch(updateSeqProgams(1));
    }
  }, [location]);

  useEffect(() => {
    setPlotWidth(window.innerWidth);
    setPlotHeight(window.innerHeight);
    // //console.log(window.innerHeight, window.innerWidth);
    if (updateRef.current == true) {
      $("#example_line").DataTable().destroy();
      $(document).ready(function () {
        setTimeout(function () {
          $("#example_line").DataTable({
            pagingType: "full_numbers",
            //   pageLength: 5,
            processing: true,
            bDestroy: true,
            dom: "lBfrtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            orderCellsTop: true,
            fixedHeader: true,
            //   sPaginationType: "full_numbers",
            aaSorting: [[0, "asc"]],
            iDisplayLength: 5,
            aLengthMenu: [
              [5, 10, 25, 50, 100, -1],
              [5, 10, 25, 50, 100, "All"],
            ],
          });
          //    //console.log(table)
        }, 2000);
      });
    }
  }, [window.innerHeight, window.innerWidth]);

  useEffect(() => {
    if (updateRef.current === false) {
      $(document).ready(function () {
        setTimeout(function () {
          $("#example_line").DataTable({
            pagingType: "full_numbers",
            //   pageLength: 5,
            processing: true,
            bDestroy: true,
            dom: "lBfrtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            orderCellsTop: true,
            fixedHeader: true,
            //   sPaginationType: "full_numbers",
            aaSorting: [[0, "asc"]],
            iDisplayLength: 5,
            aLengthMenu: [
              [5, 10, 25, 50, 100, -1],
              [5, 10, 25, 50, 100, "All"],
            ],
          });
          //    //console.log(table)
        }, 1000);
      });
    }
    return () => {
      updateRef.current = true;
      // d3.select(`#main_plot_${plot_id}`).remove()
      // dispatch(updateFirstPlot(1));
    };
  }, []);

  const queryClient = useQueryClient();

  const [plotSelected, setPlotSelected] = useState(1);

  const [plotType, setPlotType] = useState();

  const [sensorPlot, setSensorPlot] = useState(`ainplot`);

  const [actPlot, setActPlot] = useState([1]);

  const [actPlotView, setActPlotView] = useState({});
  const [actKeys, setActKeys] = useState({});

  const [titleColor, setTitleColor] = useState({});
  const [miscColor, setMiscColor] = useState({});
  const [contColor, setContColor] = useState({});
  const [markColor, setMarkColor] = useState({});
  const [pathColor, setPathColor] = useState({});
  const [dangerColor, setDangerColor] = useState({});
  const [trailColor, setTrailColor] = useState({});
  const [valueColor, setValueColor] = useState({});
  const [plotColor, setPlotColor] = useState({});
  const [marksColor, setMarksColor] = useState({});
  const [needlePinColor, setNeedlePinColor] = useState({});
  const [needleCircleColor, setNeedleCircleColor] = useState({});

  const [legIsoTextColor11, setLegIsoTextColor11] = useState({});
  const [legIsoTextColor12, setLegIsoTextColor12] = useState({});
  const [legIsoTextColor13, setLegIsoTextColor13] = useState({});
  const [legIsoTextColor14, setLegIsoTextColor14] = useState({});
  const [keyAllow, setKeyAllow] = useState({});

  const [plotWidth, setPlotWidth] = useState(window.innerWidth);
  const [plotHeight, setPlotHeight] = useState(window.innerHeight);

  const [settingUpdate, setSettingUpdate] = useState(0);

  const [segColor1, setSegColor1] = useState({});
  const [segColor2, setSegColor2] = useState({});
  const [segColor3, setSegColor3] = useState({});
  const [segColor4, setSegColor4] = useState({});
  const [segColor5, setSegColor5] = useState({});

  const [stemColor, setStemColor] = useState({});

  const [xAxisColor, setXAxisColor] = useState({});
  const [yAxisColor, setYAxisColor] = useState({});

  const [xLabelColor, setXLabelColor] = useState({});
  const [yLabelColor, setYLabelColor] = useState({});

  const [gridColor, setGridColor] = useState({});

  const [legIsoColor11, setLegIsoColor11] = useState({});
  const [legIsoColor12, setLegIsoColor12] = useState({});
  const [legIsoColor13, setLegIsoColor13] = useState({});
  const [legIsoColor14, setLegIsoColor14] = useState({});

  const [tooltipBgColor, setTooltipBgColor] = useState({});
  const [tooltipDataColor, setTooltipDataColor] = useState({});
  const [tooltipHeadColor, setTooltipHeadColor] = useState({});

  const [fetchData, setFetchData] = useState({
    imei: imeiId,
    dateFrom1: "",
    dateTo1: "",
    plot1: "",
    key1: [],
    dateFrom2: "",
    dateTo2: "",
    plot2: "",
    key2: [],
    dateFrom3: "",
    dateTo3: "",
    plot3: "",
    key3: [],
    dateFrom4: "",
    dateTo4: "",
    plot4: "",
    key4: [],
    dateFrom5: "",
    dateTo5: "",
    plot5: "",
    key5: [],
    dateFrom6: "",
    dateTo6: "",
    plot6: "",
    key6: [],
    dateFrom7: "",
    dateTo7: "",
    plot7: "",
    key7: [],
    dateFrom8: "",
    dateTo8: "",
    plot8: "",
    key8: [],
    lastRecordRec1: "",
    lastRecordRec2: "",
    lastRecordRec3: "",
    lastRecordRec4: "",
    lastRecordRec5: "",
    lastRecordRec6: "",
    lastRecordRec7: "",
    lastRecordRec8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  const [dateFromTo, setDateFromTo] = useState({
    dateFrom1: "",
    dateTo1: "",
    dateFrom2: "",
    dateTo2: "",
    dateFrom3: "",
    dateTo3: "",
    dateFrom4: "",
    dateTo4: "",
    dateFrom5: "",
    dateTo5: "",
    dateFrom6: "",
    dateTo6: "",
    dateFrom7: "",
    dateTo7: "",
    dateFrom8: "",
    dateTo8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  const c_user = async (user) => {
    await updateMutation.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        if (e.success == true) {
          showAlert("Plot Updated", "success");
        } else {
          showAlert("Plot Can't Be Updated", "danger");
        }
        queryClient.invalidateQueries(["progseq"]);
      },
      onError: () => {
        showAlert("Plot Can't Be Updated", "danger");
      },
    });
  };

  const { status, data, isFetching } = useQuery(
    ["progseq"],
    () =>
      getInitialData({
        imei: imeiId,
      }),
    {
      onSuccess: (e) => {
        // //console.log(e)
        if (storeData.seqUpdate == 1) {
          let newNote = {
            imei: imeiId,
            dateFrom1: "",
            dateTo1: "",
            plot1: "",
            key1: [],
            dateFrom2: "",
            dateTo2: "",
            plot2: "",
            key2: [],
            dateFrom3: "",
            dateTo3: "",
            plot3: "",
            key3: [],
            dateFrom4: "",
            dateTo4: "",
            plot4: "",
            key4: [],
            dateFrom5: "",
            dateTo5: "",
            plot5: "",
            key5: [],
            dateFrom6: "",
            dateTo6: "",
            plot6: "",
            key6: [],
            dateFrom7: "",
            dateTo7: "",
            plot7: "",
            key7: [],
            dateFrom8: "",
            dateTo8: "",
            plot8: "",
            key8: [],
            lastRecordRec1: "",
            lastRecordRec2: "",
            lastRecordRec3: "",
            lastRecordRec4: "",
            lastRecordRec5: "",
            lastRecordRec6: "",
            lastRecordRec7: "",
            lastRecordRec8: "",
            lastFrom1: "",
            lastTo1: "",
            lastFrom2: "",
            lastTo2: "",
            lastFrom3: "",
            lastTo3: "",
            lastFrom4: "",
            lastTo4: "",
            lastFrom5: "",
            lastTo5: "",
            lastFrom6: "",
            lastTo6: "",
            lastFrom7: "",
            lastTo7: "",
            lastFrom8: "",
            lastTo8: "",
          };
          dispatch(updateSeqProgams(0));
          dispatch(updateFirstPlot(0));
          let tempProg = [];
          let tempKey = e.keys;
          let i = 0;
          var j = 0;
          for (i = 0; i < 8; i++) {
            let recProg = e.seqProg.map((e) => {
              if (e["ainplot"] === Number(i + 1)) {
                setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "ainplot";
                // //console.log(tempKey['ainplot']);
                newNote[`key${i + 1}`] = tempKey["ainplot"];
                return { plot: "ainplot", progseq: `${i + 1}` };
              } else if (e["digplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "digplot";
                newNote[`key${i + 1}`] = tempKey["digplot"];
                return { plot: "digplot", progseq: `${i + 1}` };
              } else if (e["isoplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["isoplot"];
                newNote[`plot${i + 1}`] = "isoplot";
                return { plot: "isoplot", progseq: `${i + 1}` };
              } else if (e["nasplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["nasplot"];
                newNote[`plot${i + 1}`] = "nasplot";
                return { plot: "nasplot", progseq: `${i + 1}` };
              } else if (e["pulseplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["pulseplot"];
                newNote[`plot${i + 1}`] = "pulseplot";
                return { plot: "pulseplot", progseq: `${i + 1}` };
              } else if (e["saeplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["saeplot"];
                newNote[`plot${i + 1}`] = "saeplot";
                return { plot: "saeplot", progseq: `${i + 1}` };
              } else if (e["sigplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["sigplot"];
                newNote[`plot${i + 1}`] = "sigplot";
                return { plot: "sigplot", progseq: `${i + 1}` };
              } else if (e["tempplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["tempplot"];
                newNote[`plot${i + 1}`] = "tempplot";
                return { plot: "tempplot", progseq: `${i + 1}` };
              }
            });
            if (recProg[0] !== undefined) {
              // //console.log(recProg)
              tempProg[j] = recProg;
              j += 1;
            }
          }
          // //console.log(e.validData['ainplot']);
          dispatch(addSeqProg(tempProg));
          dispatch(addPlotData(e.data));
          dispatch(addPlotFirstData(e.data));
          dispatch(addPlotKeys(e.keys));
          dispatch(addLastRecordDate(e.lastRecordDate));
          dispatch(addValidData(e.validData));
          dispatch(addPlotView(e.plotView));
          reArrangeKeys(e.plotView[`${sensorPlot}`][0]);
          dispatch(addLastRecordData(e.lastRecordValues));
          dispatch(addSensors(e.sensors));
          dispatch(addDashObj(newNote));
          setActPlotView(e.plotView[`${sensorPlot}`][0]);
          setTitleColor(hexAToRGBA(e.plotView[`${sensorPlot}`][0].titlecolor));
          setMiscColor(hexAToRGBA(e.plotView[`${sensorPlot}`][0].misccolor));
          setLegIsoTextColor11(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisotextcolor11)
          );
          setLegIsoTextColor12(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisotextcolor12)
          );
          setContColor(hexAToRGBA(e.plotView[`${sensorPlot}`][0].contbgcolor));
          setMarkColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].tooltipheadcolor)
          );
          setPathColor(hexAToRGBA(e.plotView[`${sensorPlot}`][0].innercircle1));
          setTrailColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].outercircle1)
          );
          setDangerColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].needlecirclestroke1)
          );
          setValueColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].currenttext1)
          );
          setPlotColor(hexAToRGBA(e.plotView[`${sensorPlot}`][0].plotbgcolor));
          setMarksColor(hexAToRGBA(e.plotView[`${sensorPlot}`][0].majorticks1));
          setNeedlePinColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].needlecolor1)
          );
          setNeedleCircleColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].needlecircle1)
          );

          setSegColor1(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].needlestrokecolor1)
          );
          setSegColor2(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].needlecirclestroke1)
          );
          setSegColor3(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisocolor13)
          );
          setSegColor4(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisocolor14)
          );
          setSegColor5(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].needlestrokecolor1)
          );
          setStemColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].linestrokecolor)
          );
          setXAxisColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].xaxislinecolor)
          );
          setYAxisColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].yaxislinecolor)
          );
          setLegIsoColor11(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisocolor11)
          );
          setLegIsoColor12(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisocolor12)
          );
          setLegIsoColor13(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisocolor13)
          );
          setLegIsoColor14(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].legisocolor14)
          );
          setGridColor(hexAToRGBA(e.plotView[`${sensorPlot}`][0].isomisc1));

          setTooltipBgColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].tooltipbgcolor)
          );
          setTooltipDataColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].tooltipdatacolor)
          );
          setTooltipHeadColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].tooltipheadcolor)
          );

          setXLabelColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].xlabelcolor)
          );
          setYLabelColor(
            hexAToRGBA(e.plotView[`${sensorPlot}`][0].ylabelcolor)
          );
          // //console.log(hexAToRGBA(e.plotView[`${sensorPlot}`][0].titlecolor))
          setFetchData(newNote);
          setPlotType(e.plotView[`${sensorPlot}`][0].graphtype);
          setKeyAllow({
            keyallow1: e.plotView[`${sensorPlot}`][0].keyallow1,
          });
          // //console.log(e.plotView['ainplot'][0].graphtype)
        }
        //settime()
      },
    }
  );
  function reArrangeKeys(plotView) {
    let i = 0;
    let obj = [];
    let j = 0;
    for (i = 0; i < 1; i++) {
      if (plotView[`keyallow${i + 1}`] === true) {
        obj[j] = plotView[`keyiso${i + 1}`];
        j = j + 1;
      }
    }
    setActKeys(obj);
  }
  function reArrangeKeys_(plotView, key, checked) {
    let i = 0;
    let obj = [];
    let arrObj = {};
    let j = 0;
    let k = 0;
    let l = 0;
    let m = [-1, -1, -1, -1];
    let n = 0;
    let objCopy = plotView;
    let allow = {};
    // //console.log(key)
    for (i = 0; i < 1; i++) {
      if (keyAllow[`keyallow${i + 1}`] === true && key !== `keyallow${i + 1}`) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`keytext${j + 1}`] = objCopy[`keytext${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === true) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`keytext${j + 1}`] = objCopy[`keytext${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === false) {
        l = i;
        m[i] = i;
        // n = n + 1;
      } else {
        m[i] = i;
        // n = n + 1;
      }
    }
    for (k = 0; k < 1; k++) {
      if (m[k] === k) {
        // //console.log(k);
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${k + 1}`];
        arrObj[`keytext${j + 1}`] = objCopy[`keytext${k + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${k + 1}`];
        allow[`keyallow${j + 1}`] = false;
        j = j + 1;
      }
    }
    // if (checked === false) {
    //   arrObj[`legisotextcolor14`] = plotView[`legisotextcolor1${l + 1}`];
    //   arrObj[`keytext4`] = plotView[`keytext${l + 1}`];
    //   arrObj[`keyiso4`] = plotView[`keyiso${l + 1}`];
    // }
    // for(k=j;k<4;k++) {
    //   arrObj[`keytext${k+1}`]=plotView[`keytext${k+1}`]
    // }
    setActPlotView({
      ...actPlotView,
      [`keytext1`]: arrObj[`keytext1`],

      [`legisotextcolor11`]: arrObj[`legisotextcolor11`],

      [`keyiso1`]: arrObj[`keyiso1`],

      [key]: checked,
    });
    setKeyAllow({
      ["keyallow1"]: allow.keyallow1,
    });
    // //console.log(allow);
    setActKeys(obj);
    setSettingUpdate(1);
    setLegIsoTextColor11(hexAToRGBA(arrObj[`legisotextcolor11`]));
    setTimeout(() => {
      setSettingUpdate(0);
    }, 1000);
  }

  function reArrangeKeys_1(plotView, key, checked) {
    let i = 0;
    let obj = [];
    let arrObj = {};
    let j = 0;
    let k = 0;
    let l = 0;
    let m = [-1, -1, -1, -1];
    let n = 0;
    let objCopy = plotView;
    let allow = {};
    // //console.log(key)
    for (i = 0; i < 1; i++) {
      if (keyAllow[`keyallow${i + 1}`] === true && key !== `keyallow${i + 1}`) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`legisotext1${j + 1}`] = objCopy[`legisotext1${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`legisocolor1${j + 1}`] = objCopy[`legisocolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === true) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`legisotext1${j + 1}`] = objCopy[`legisotext1${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`legisocolor1${j + 1}`] = objCopy[`legisocolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === false) {
        l = i;
        m[i] = i;
        // n = n + 1;
      } else {
        m[i] = i;
        // n = n + 1;
      }
    }
    for (k = 0; k < 1; k++) {
      if (m[k] === k) {
        // //console.log(k);
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${k + 1}`];
        arrObj[`legisocolor1${j + 1}`] = objCopy[`legisocolor1${k + 1}`];
        arrObj[`legisotext1${j + 1}`] = objCopy[`legisotext1${k + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${k + 1}`];
        allow[`keyallow${j + 1}`] = false;
        j = j + 1;
      }
    }
    // if (checked === false) {
    //   arrObj[`legisotextcolor14`] = plotView[`legisotextcolor1${l + 1}`];
    //   arrObj[`keytext4`] = plotView[`keytext${l + 1}`];
    //   arrObj[`keyiso4`] = plotView[`keyiso${l + 1}`];
    // }
    // for(k=j;k<4;k++) {
    //   arrObj[`keytext${k+1}`]=plotView[`keytext${k+1}`]
    // }
    setActPlotView({
      ...actPlotView,
      [`legisotext11`]: arrObj[`legisotext11`],

      [`legisotextcolor11`]: arrObj[`legisotextcolor11`],

      [`legisocolor11`]: arrObj[`legisocolor11`],

      [`keyiso1`]: arrObj[`keyiso1`],
      [key]: checked,
    });
    setKeyAllow({
      ["keyallow1"]: allow.keyallow1,
    });
    // //console.log(allow);
    setActKeys(obj);
    setSettingUpdate(1);
    setLegIsoTextColor11(hexAToRGBA(arrObj[`legisotextcolor11`]));

    setLegIsoColor11(hexAToRGBA(arrObj[`legisocolor11`]));
    // setTimeout(() => {
    //   setSettingUpdate(0);
    // }, 1000);
  }

  const handleDatePicker = (value, states) => {
    let str = 0;
    let str_ = 0;
    let newDate = new Date(value[0]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // setFromDate(str)
    // //console.log(str);
    newDate = new Date(value[1]);
    str_ = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // //console.log(str);
    // setToDate(str)
    // //console.log(str);
    ////console.log(value);
    ////console.log(states);
    ////console.log(plotSelected);
    setDateFromTo({
      ...dateFromTo,
      [`dateFrom${plotSelected}`]: str,
      [`dateTo${plotSelected}`]: str_,
      [`lastFrom${plotSelected}`]: str,
      [`lastTo${plotSelected}`]: str_,
    });

    if (str.length > 2 && str_.length > 2) {
      let newNote = {
        imei: "",
        dateFrom1: "",
        dateTo1: "",
        plot1: "",
        key1: [],
        dateFrom2: "",
        dateTo2: "",
        plot2: "",
        key2: [],
        dateFrom3: "",
        dateTo3: "",
        plot3: "",
        key3: [],
        dateFrom4: "",
        dateTo4: "",
        plot4: "",
        key4: [],
        dateFrom5: "",
        dateTo5: "",
        plot5: "",
        key5: [],
        dateFrom6: "",
        dateTo6: "",
        plot6: "",
        key6: [],
        dateFrom7: "",
        dateTo7: "",
        plot7: "",
        key7: [],
        dateFrom8: "",
        dateTo8: "",
        plot8: "",
        key8: [],
        lastRecordRec1: "",
        lastRecordRec2: "",
        lastRecordRec3: "",
        lastRecordRec4: "",
        lastRecordRec5: "",
        lastRecordRec6: "",
        lastRecordRec7: "",
        lastRecordRec8: "",
        lastFrom1: "",
        lastTo1: "",
        lastFrom2: "",
        lastTo2: "",
        lastFrom3: "",
        lastTo3: "",
        lastFrom4: "",
        lastTo4: "",
        lastFrom5: "",
        lastTo5: "",
        lastFrom6: "",
        lastTo6: "",
        lastFrom7: "",
        lastTo7: "",
        lastFrom8: "",
        lastTo8: "",
      };
      let i = 0;
      let j = 0;
      newNote.imei = imeiId;
      for (i = 0; i < 8; i++) {
        if (i === plotSelected - 1) {
          newNote[`dateFrom${i + 1}`] = str;
          newNote[`dateTo${i + 1}`] = str_;
          newNote[`lastFrom${i + 1}`] = str;
          newNote[`lastTo${i + 1}`] = str_;
        } else {
          newNote[`dateFrom${i + 1}`] = dateFromTo[`dateFrom${i + 1}`];
          newNote[`dateTo${i + 1}`] = dateFromTo[`dateTo${i + 1}`];
          newNote[`lastFrom${i + 1}`] = dateFromTo[`lastFrom${i + 1}`];
          newNote[`lastTo${i + 1}`] = dateFromTo[`lastTo${i + 1}`];
        }
      }
      if (storeData.progSeq[0]) {
        newNote.plot1 = storeData.progSeq[0][0].plot;
        newNote.key1 = storeData.plotKeys[`${storeData.progSeq[0][0].plot}`];
      }
      if (storeData.progSeq[1]) {
        newNote.plot2 = storeData.progSeq[1][0].plot;
        newNote.key2 = storeData.plotKeys[`${storeData.progSeq[1][0].plot}`];
      }
      if (storeData.progSeq[2]) {
        newNote.plot3 = storeData.progSeq[2][0].plot;
        newNote.key3 = storeData.plotKeys[`${storeData.progSeq[2][0].plot}`];
      }
      if (storeData.progSeq[3]) {
        newNote.plot4 = storeData.progSeq[3][0].plot;
        newNote.key4 = storeData.plotKeys[`${storeData.progSeq[3][0].plot}`];
      }
      if (storeData.progSeq[4]) {
        newNote.plot5 = storeData.progSeq[4][0].plot;
        newNote.key5 = storeData.plotKeys[`${storeData.progSeq[4][0].plot}`];
      }
      if (storeData.progSeq[5]) {
        newNote.plot6 = storeData.progSeq[5][0].plot;
        newNote.key6 = storeData.plotKeys[`${storeData.progSeq[5][0].plot}`];
      }
      if (storeData.progSeq[6]) {
        newNote.plot7 = storeData.progSeq[6][0].plot;
        newNote.key7 = storeData.plotKeys[`${storeData.progSeq[6][0].plot}`];
      }
      if (storeData.progSeq[7]) {
        newNote.plot8 = storeData.progSeq[7][0].plot;
        newNote.key8 = storeData.plotKeys[`${storeData.progSeq[7][0].plot}`];
      }
      dispatch(addDashObj(newNote));
      setFetchData(newNote);

      //console.log(newNote);
    }
  };

  const handleOk = (f, t, p) => {
    //console.log(dateFromTo[`dateFrom${p}`]);
    //console.log(dateFromTo[`dateTo${p}`]);
    //console.log(p);
    // setPlotSelected(p);
  };

  const onChange = (e) => {
    // //console.log(e.target.value);
    // //console.log(e.target.name);
    // setActPlotView({
    //   ...actPlotView[`${sensorPlot}`][0],
    //   [`${e.target.name}`]: e.target.value,
    // });
    //let r = { [`${e.target.name}`]: e.target.value };
    setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.value });
    // //console.log(storeData.lastRecordDate["datedevice"]);
  };
  const onChecked = (e) => {
    // //console.log(e.target.name)
    reArrangeKeys_(actPlotView, e.target.name, e.target.checked);
    // setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.checked });
  };
  const onChecked_ = (e) => {
    // //console.log(e.target.name)
    reArrangeKeys_1(actPlotView, e.target.name, e.target.checked);
    // setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.checked });
  };
  const onPopup = (e) => {
    setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.checked });
  };
  const changeTitleColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`titlecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTitleColor(e);
  };
  const changeMiscColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`misccolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setMiscColor(e);
  };
  const changeLegIsoTextColor11 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor11`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor11(e);
  };
  const changeLegIsoTextColor12 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor12`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor12(e);
  };
  const changeLegIsoTextColor13 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor13`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor13(e);
  };
  const changeLegIsoTextColor14 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor14`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor14(e);
  };

  const changeLegIsoColor11 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor11`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor11(e);
  };
  const changeLegIsoColor12 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor12`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor12(e);
  };
  const changeLegIsoColor13 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor13`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor13(e);
  };
  const changeLegIsoColor14 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor14`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor14(e);
  };

  const changeContColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`contbgcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setContColor(e);
  };
  const changeMarkColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipheadcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setMarkColor(e);
    setSettingUpdate(1);
    setTimeout(() => {
      setSettingUpdate(0);
    }, 1000);
  };
  const changePathColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`innercircle1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setPathColor(e);
  };
  const changeTrailColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`outercircle1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTrailColor(e);
  };
  const changeDangerColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecirclestroke1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setDangerColor(e);
  };
  const changeValueColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`currenttext1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setValueColor(e);
  };
  const changePlotColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`plotbgcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setPlotColor(e);
  };
  const changeMarksColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`majorticks1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
      [`minorticks1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setMarksColor(e);
  };
  const changeNeedlePinColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecolor1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setNeedlePinColor(e);
  };
  const changeNeedleCircleColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecircle1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setNeedleCircleColor(e);
  };

  const changeSegColor1 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlestrokecolor1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor1(e);
  };
  const changeSegColor2 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecirclestroke1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor2(e);
  };
  const changeSegColor3 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor13`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor3(e);
  };
  const changeSegColor4 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor14`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor4(e);
  };
  const changeSegColor5 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlestrokecolor1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor5(e);
  };
  const changeStemColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`linestrokecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setStemColor(e);
  };
  const changeXAxisColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`xaxislinecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setXAxisColor(e);
  };
  const changeYAxisColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`yaxislinecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setYAxisColor(e);
  };
  const changeGridColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`isomisc1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setGridColor(e);
  };

  const changeTooltipBgColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipbgcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTooltipBgColor(e);
  };
  const changeTooltipDataColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipdatacolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTooltipDataColor(e);
  };
  const changeTooltipHeadColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipheadcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTooltipHeadColor(e);
  };

  const changeXLabelColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`xlabelcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setXLabelColor(e);
  };
  const changeYLabelColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`ylabelcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setYLabelColor(e);
  };
  const savePlotView = (e) => {
    // //console.log('ncfiodsnciodsncio')
    //console.log(actPlotView);
    c_user(actPlotView)
  };

  const setTitleColorFn = () => {
    setActPlotView({
      ...actPlotView,
      [`titlecolor`]: RGBAToHexA(
        titleColor.r,
        titleColor.g,
        titleColor.b,
        titleColor.a
      ),
    });
    // //console.log(RGBAToHexA(titleColor.r,titleColor.g,titleColor.b,titleColor.a))
  };
  function RGBAToHexA(r, g, b, a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;

    return "#" + r + g + b + a;
  }
  function hexAToRGBA(h) {
    let r = 0,
      g = 0,
      b = 0,
      a = 1,
      d = 0;
    // //console.log(h.length);
    if (h.length === 7) {
      r = Number("0x" + h[1] + h[2]);
      g = Number("0x" + h[3] + h[4]);
      b = Number("0x" + h[5] + h[6]);
      d = 0;
    } else if (h.length === 9) {
      r = Number("0x" + h[1] + h[2]);
      g = Number("0x" + h[3] + h[4]);
      b = Number("0x" + h[5] + h[6]);
      a = "0x" + h[7] + h[8];
      a = +(a / 255).toFixed(3);
      d = 1;
    }
    if (d === 1) {
      return { r: r, g: g, b: b, a: a };
    }
    return { r: r, g: g, b: b, a: 1 };
  }
  // //console.log(hexAToRGBA('#ffabcd'))

  $(`options_plot_1`).css("marginRight", "50px");
  // //console.log($(`options_plot_1`));

  

  return (
    <>
      <div>
        <h2
          style={{ textAlign: "center" }}
          className="my-2"
          id={`imei6_${imeiId}`}
        >
          {`Device : ${params.imei}`}{" "}
          {localStorage.getItem("loginType") === "Admin"
            ? `User: ${params.user}`
            : `Location: ${localStorage.getItem("imeiLocation")}`}
        </h2>
        <h3 style={{ textAlign: "center" }} className="" id={`plot6_${imeiId}`}>
          {"Analog Plot Settings"}
        </h3>
        <h4 style={{ textAlign: "center" }} className="" id={`plot7_${imeiId}`}>
          {localStorage.getItem("imeiEquipment")
            ? `Equipment: ${localStorage.getItem("imeiEquipment")}`
            : ""}
        </h4>
        <div
          className="mainContainer"
          id={`main_Plot`}
          style={{ width: "100%", padding: "10px" }}
        >
          {storeData.progSeq &&
          storeData.progSeq.length &&
          actPlotView &&
          storeData.lastRecordDate["datedevice"]
            ? actPlot.map((i) => {
                let r = 0;
                let h = 0;
                let l = 0;
                let q = 0;
                let r1 = 0;
                // //console.log(storeData.lastRecordData[`ainplot`])
                if (storeData.plotData[`${sensorPlot}`]) {
                  if (storeData.plotData[`${sensorPlot}`].length > 0)
                    r = storeData.plotData[`${sensorPlot}`];
                } else {
                  // r=addDummyJson(storeData.plotKeys[`${prog[0].plot}`])
                  r = {};
                }
                if (storeData.plotLatestData[`${sensorPlot}`]) {
                  if (storeData.plotLatestData[`${sensorPlot}`].length > 0) {
                    q = storeData.plotLatestData[`${sensorPlot}`];
                  } else {
                    q = {};
                  }
                } else {
                  q = {};
                }
                if (storeData.lastRecordData[`${sensorPlot}`]) {
                  if (storeData.lastRecordData[`${sensorPlot}`]) {
                    r1 = storeData.lastRecordData[`${sensorPlot}`];
                  } else {
                    r1 = {};
                  }
                } else {
                  r1 = {};
                }
                // //console.log(keyAllow)
                let PROPS_ = {
                  plot_id: plotSelected,
                  title: "Title",
                  width: 700,
                  height: 500,
                  imei: imeiId,
                  datePicker: handleDatePicker,
                  handleOk: handleOk,
                  plotData: r,
                  // plotKeys: storeData.plotKeys[`${sensorPlot}`],
                  plotKeys: actKeys,
                  plot_: `${sensorPlot}`,
                  latestData: q,
                  latestDate: storeData.lastRecordDate["datedevice"],
                  lastRecordData: storeData.lastRecordData[`${sensorPlot}`],
                  validData: storeData.validData[`${sensorPlot}`],
                  plotView: [actPlotView],
                  plotType: `${sensorPlot}`,
                  lastUpdated: storeData.lastRecordDate["datedevice"],
                  setting: settingUpdate,
                };
                // setSettingUpdate(0)
                // //console.log(actKeys)
                switch (actPlotView.graphtype) {
                  case "Speedo":
                    PROPS_["latestData"] = "455";
                    // PROPS_['lastUpdated']=storeData.lastRecordDate["datedevice"]
                    // //console.log(PROPS_)
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          height={500}
                          key={`${sensorPlot}_0`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <SpeedoPlot
                            key={`${sensorPlot}_1`}
                            {...PROPS_}
                          ></SpeedoPlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={760}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_1_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <ProgressPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              onChecked={onChecked}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></ProgressPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "LineScatter":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_2`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <LineScatterPlot
                            key={`${sensorPlot}_3`}
                            {...PROPS_}
                          ></LineScatterPlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={850}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_3_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <NonSpeedoPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              legIsoColor11={legIsoColor11}
                              legIsoColor12={legIsoColor12}
                              legIsoColor13={legIsoColor13}
                              legIsoColor14={legIsoColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              stemColor={stemColor}
                              xAxisColor={xAxisColor}
                              yAxisColor={yAxisColor}
                              gridColor={gridColor}
                              tooltipBgColor={tooltipBgColor}
                              tooltipDataColor={tooltipDataColor}
                              tooltipHeadColor={tooltipHeadColor}
                              xLabelColor={xLabelColor}
                              yLabelColor={yLabelColor}
                              changeLegIsoColor11={changeLegIsoColor11}
                              changeLegIsoColor12={changeLegIsoColor12}
                              changeLegIsoColor13={changeLegIsoColor13}
                              changeLegIsoColor14={changeLegIsoColor14}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              changeStemColor={changeStemColor}
                              changeXAxisColor={changeXAxisColor}
                              changeYAxisColor={changeYAxisColor}
                              changeGridColor={changeGridColor}
                              changeTooltipBgColor={changeTooltipBgColor}
                              changeTooltipDataColor={changeTooltipDataColor}
                              changeTooltipHeadColor={changeTooltipHeadColor}
                              changeXLabelColor={changeXLabelColor}
                              changeYLabelColor={changeYLabelColor}
                              onChecked={onChecked_}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></NonSpeedoPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "Line":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_4`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <LinePlot
                            key={`${sensorPlot}_5`}
                            {...PROPS_}
                          ></LinePlot>
                          {/* <PlotTable  key={`${sensorPlot}_6`} {...PROPS_}></PlotTable> */}
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={850}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_5_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <NonSpeedoPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              legIsoColor11={legIsoColor11}
                              legIsoColor12={legIsoColor12}
                              legIsoColor13={legIsoColor13}
                              legIsoColor14={legIsoColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              stemColor={stemColor}
                              xAxisColor={xAxisColor}
                              yAxisColor={yAxisColor}
                              gridColor={gridColor}
                              tooltipBgColor={tooltipBgColor}
                              tooltipDataColor={tooltipDataColor}
                              tooltipHeadColor={tooltipHeadColor}
                              xLabelColor={xLabelColor}
                              yLabelColor={yLabelColor}
                              changeLegIsoColor11={changeLegIsoColor11}
                              changeLegIsoColor12={changeLegIsoColor12}
                              changeLegIsoColor13={changeLegIsoColor13}
                              changeLegIsoColor14={changeLegIsoColor14}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              changeStemColor={changeStemColor}
                              changeXAxisColor={changeXAxisColor}
                              changeYAxisColor={changeYAxisColor}
                              changeGridColor={changeGridColor}
                              changeTooltipBgColor={changeTooltipBgColor}
                              changeTooltipDataColor={changeTooltipDataColor}
                              changeTooltipHeadColor={changeTooltipHeadColor}
                              changeXLabelColor={changeXLabelColor}
                              changeYLabelColor={changeYLabelColor}
                              onChecked={onChecked_}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></NonSpeedoPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "Scatter":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_7`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <ScatterPlot
                            key={`${sensorPlot}_8`}
                            {...PROPS_}
                          ></ScatterPlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={850}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_8_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <NonSpeedoPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              legIsoColor11={legIsoColor11}
                              legIsoColor12={legIsoColor12}
                              legIsoColor13={legIsoColor13}
                              legIsoColor14={legIsoColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              stemColor={stemColor}
                              xAxisColor={xAxisColor}
                              yAxisColor={yAxisColor}
                              gridColor={gridColor}
                              tooltipBgColor={tooltipBgColor}
                              tooltipDataColor={tooltipDataColor}
                              tooltipHeadColor={tooltipHeadColor}
                              xLabelColor={xLabelColor}
                              yLabelColor={yLabelColor}
                              changeLegIsoColor11={changeLegIsoColor11}
                              changeLegIsoColor12={changeLegIsoColor12}
                              changeLegIsoColor13={changeLegIsoColor13}
                              changeLegIsoColor14={changeLegIsoColor14}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              changeStemColor={changeStemColor}
                              changeXAxisColor={changeXAxisColor}
                              changeYAxisColor={changeYAxisColor}
                              changeGridColor={changeGridColor}
                              changeTooltipBgColor={changeTooltipBgColor}
                              changeTooltipDataColor={changeTooltipDataColor}
                              changeTooltipHeadColor={changeTooltipHeadColor}
                              changeXLabelColor={changeXLabelColor}
                              changeYLabelColor={changeYLabelColor}
                              onChecked={onChecked_}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></NonSpeedoPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "Lollipop":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_9`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <LollipopPlot
                            key={`${sensorPlot}_10`}
                            {...PROPS_}
                          ></LollipopPlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={850}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_10_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <NonSpeedoPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              legIsoColor11={legIsoColor11}
                              legIsoColor12={legIsoColor12}
                              legIsoColor13={legIsoColor13}
                              legIsoColor14={legIsoColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              stemColor={stemColor}
                              xAxisColor={xAxisColor}
                              yAxisColor={yAxisColor}
                              gridColor={gridColor}
                              tooltipBgColor={tooltipBgColor}
                              tooltipDataColor={tooltipDataColor}
                              tooltipHeadColor={tooltipHeadColor}
                              xLabelColor={xLabelColor}
                              yLabelColor={yLabelColor}
                              changeLegIsoColor11={changeLegIsoColor11}
                              changeLegIsoColor12={changeLegIsoColor12}
                              changeLegIsoColor13={changeLegIsoColor13}
                              changeLegIsoColor14={changeLegIsoColor14}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              changeStemColor={changeStemColor}
                              changeXAxisColor={changeXAxisColor}
                              changeYAxisColor={changeYAxisColor}
                              changeGridColor={changeGridColor}
                              changeTooltipBgColor={changeTooltipBgColor}
                              changeTooltipDataColor={changeTooltipDataColor}
                              changeTooltipHeadColor={changeTooltipHeadColor}
                              changeXLabelColor={changeXLabelColor}
                              changeYLabelColor={changeYLabelColor}
                              onChecked={onChecked_}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></NonSpeedoPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "Area":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_11`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <AreaPlot
                            key={`${sensorPlot}_12`}
                            {...PROPS_}
                          ></AreaPlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={850}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_12_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <NonSpeedoPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              legIsoColor11={legIsoColor11}
                              legIsoColor12={legIsoColor12}
                              legIsoColor13={legIsoColor13}
                              legIsoColor14={legIsoColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              stemColor={stemColor}
                              xAxisColor={xAxisColor}
                              yAxisColor={yAxisColor}
                              gridColor={gridColor}
                              tooltipBgColor={tooltipBgColor}
                              tooltipDataColor={tooltipDataColor}
                              tooltipHeadColor={tooltipHeadColor}
                              xLabelColor={xLabelColor}
                              yLabelColor={yLabelColor}
                              changeLegIsoColor11={changeLegIsoColor11}
                              changeLegIsoColor12={changeLegIsoColor12}
                              changeLegIsoColor13={changeLegIsoColor13}
                              changeLegIsoColor14={changeLegIsoColor14}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              changeStemColor={changeStemColor}
                              changeXAxisColor={changeXAxisColor}
                              changeYAxisColor={changeYAxisColor}
                              changeGridColor={changeGridColor}
                              changeTooltipBgColor={changeTooltipBgColor}
                              changeTooltipDataColor={changeTooltipDataColor}
                              changeTooltipHeadColor={changeTooltipHeadColor}
                              changeXLabelColor={changeXLabelColor}
                              changeYLabelColor={changeYLabelColor}
                              onChecked={onChecked_}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></NonSpeedoPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "SemiGauge":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          height={500}
                          key={`${sensorPlot}_13`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <SemiGaugePlot
                            key={`${sensorPlot}_14`}
                            {...PROPS_}
                          ></SemiGaugePlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={760}
                          height={500}
                          key={`${sensorPlot}_14_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <ProgressPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              onChecked={onChecked}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></ProgressPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "SemiSpeedo":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          height={500}
                          key={`${sensorPlot}_15`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <SemiSpeedoPlot
                            key={`${sensorPlot}_16`}
                            {...PROPS_}
                          ></SemiSpeedoPlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={760}
                          height={500}
                          key={`${sensorPlot}_16_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <ProgressPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              onChecked={onChecked}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></ProgressPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  case "Progress":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={700}
                          height={500}
                          key={`${sensorPlot}_17`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <ProgressPlot
                            key={`${sensorPlot}_18`}
                            {...PROPS_}
                          ></ProgressPlot>
                        </Resizable>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={760}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_17_1`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          {actPlotView ? (
                            <ProgressPulsePara
                              plotView={actPlotView ? actPlotView : ""}
                              plotType={
                                actPlotView ? actPlotView.graphtype : ""
                              }
                              onChange={onChange}
                              onPopup={onPopup}
                              changeTitleColor={changeTitleColor}
                              changeMiscColor={changeMiscColor}
                              setTitleColorFn={setTitleColorFn}
                              titleColor={titleColor}
                              miscColor={miscColor}
                              legIsoTextColor11={legIsoTextColor11}
                              legIsoTextColor12={legIsoTextColor12}
                              legIsoTextColor13={legIsoTextColor13}
                              legIsoTextColor14={legIsoTextColor14}
                              contColor={contColor}
                              markColor={markColor}
                              pathColor={pathColor}
                              trailColor={trailColor}
                              valueColor={valueColor}
                              plotColor={plotColor}
                              marksColor={marksColor}
                              needlePinColor={needlePinColor}
                              needleCircleColor={needleCircleColor}
                              dangerColor={dangerColor}
                              segColor1={segColor1}
                              segColor2={segColor2}
                              segColor3={segColor3}
                              segColor4={segColor4}
                              segColor5={segColor5}
                              changeLegIsoTextColor11={changeLegIsoTextColor11}
                              changeLegIsoTextColor12={changeLegIsoTextColor12}
                              changeLegIsoTextColor13={changeLegIsoTextColor13}
                              changeLegIsoTextColor14={changeLegIsoTextColor14}
                              changeContColor={changeContColor}
                              changeMarkColor={changeMarkColor}
                              changePathColor={changePathColor}
                              changeTrailColor={changeTrailColor}
                              changeValueColor={changeValueColor}
                              changePlotColor={changePlotColor}
                              changeMarksColor={changeMarksColor}
                              changeNeedlePinColor={changeNeedlePinColor}
                              changeNeedleCircleColor={changeNeedleCircleColor}
                              changeDangerColor={changeDangerColor}
                              changeSegColor1={changeSegColor1}
                              changeSegColor2={changeSegColor2}
                              changeSegColor3={changeSegColor3}
                              changeSegColor4={changeSegColor4}
                              changeSegColor5={changeSegColor5}
                              onChecked={onChecked}
                              keyAllow={keyAllow}
                              savePlotView={savePlotView}
                            ></ProgressPulsePara>
                          ) : (
                            ""
                          )}
                        </Resizable>
                      </>
                    );
                    break;
                  default:
                    break;
                }
                return i;
              })
            : ""}
        </div>
        {/* TAbles */}
      </div>
      {localStorage.getItem("loginType") === "Admin" ? (
        <ReactTooltip
          anchorId={`imei6_${imeiId}`}
          place="top"
          content={`Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} Location: ${localStorage.getItem("imeiLocation")}`}
          multiline={true}
          html={`Device Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} 
                 Company: ${localStorage.getItem("companyName")}<br />
                 Customer: ${localStorage.getItem("customerName")}`}
          //localStorage.getItem('imeiLocation')
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AinSettingPlot;
