import React, { useEffect } from "react";
import {RgbaColorPicker } from "react-colorful";

const NonSpeedoPulsePara = (props) => {
  const {
    plotView,
    plotType,
    onChange,
    onPopup,
    onChecked,
    changeTitleColor,
    changeMiscColor,

    titleColor,
    miscColor,
    legIsoColor11,

    legIsoTextColor11,

    contColor,

    valueColor,
    plotColor,

    stemColor,
    xAxisColor,
    yAxisColor,
    gridColor,

    tooltipBgColor,
    tooltipDataColor,
    tooltipHeadColor,

    changeLegIsoTextColor11,

    changeLegIsoColor11,

    changeContColor,

    changeValueColor,
    changePlotColor,

    xLabelColor,
    yLabelColor,

    changeStemColor,
    changeXAxisColor,
    changeYAxisColor,
    changeGridColor,
    changeXLabelColor,
    changeYLabelColor,

    changeTooltipBgColor,
    changeTooltipDataColor,
    changeTooltipHeadColor,

    keyAllow,

    savePlotView
  } = props;
  useEffect(() => {}, []);

  // //console.log(`rgba(${color.r },${color.g},${color.b},${color.a})`)
  let collapse_plot_title = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "300px",
    marginBottom: "300px",
  };
  let collapse_plot_path = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "430px",
    marginBottom: "300px",
  };
  let collapse_plot_trail = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "500px",
    marginBottom: "300px",
  };
  let collapse_plot_misc = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "200px",
    marginBottom: "300px",
  };
  let collapse_plot_leg = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "200px",
    marginBottom: "300px",
  };
  let collapse_plot_cont = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "400px",
    marginBottom: "300px",
  };
  let collapse_plot_mark = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "500px",
    marginBottom: "300px",
  };
  let collapse_plot_ticks = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "230px",
    marginBottom: "300px",
  };
  let collapse_plot_plot = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "500px",
    marginBottom: "300px",
  };
  let collapse_plot_needle_pin = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "300px",
    marginBottom: "300px",
  };
  let collapse_plot_needle_circle = {
    position: "absolute",
    zIndex: 10,
    marginLeft: "370px",
    marginBottom: "300px",
  };
  return (
    <div className="mb-3 d-flex flex-column align-items-start justify-content-start flex-wrap">
      <button
        // type="submit"
        className="btn btn-primary my-3"
        onClick={savePlotView}
      >
        Save
      </button>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <div className="form-floating my-3" style={{ width: "150px" }}>
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            value={plotView ? plotView.graphtype : "Speedo"}
            name={`graphtype`}
            onChange={onChange}
            style={{ height: "70px" }}
          >
            <option value="Speedo">Speedo</option>
            <option value="SemiSpeedo">Semi Speedo</option>
            <option value="SemiGauge">Semi Gauge</option>
            <option value="Progress">Progress</option>
            <option value="Lollipop">Lollipop</option>
            <option value="Line">Line</option>
            <option value="LineScatter">Line Scatter</option>
            <option value="Scatter">Scatter</option>
            <option value="Area">Area</option>
          </select>
          <label htmlFor="floatingSelect" style={{ fontSize: "20px" }}>
            Plot Type
          </label>
        </div>
        <label
          htmlFor="misc"
          className="form-label mx-3"
          style={{ fontSize: "20px" }}
        >
          Misc :
        </label>
        <div
          style={{
            background: `rgba(${miscColor.r},${miscColor.g},${miscColor.b},${miscColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_misc`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_misc`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_misc`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${miscColor.r},${miscColor.g},${miscColor.b},${miscColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_misc`}
          style={collapse_plot_misc}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={miscColor}
              onChange={changeMiscColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <label
          htmlFor="Container"
          className="form-label mx-3"
          style={{ fontSize: "20px" }}
        >
          Container :
        </label>
        <div
          style={{
            background: `rgba(${contColor.r},${contColor.g},${contColor.b},${contColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_misc`}>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_misc`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_cont`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${contColor.r},${contColor.g},${contColor.b},${contColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_cont`}
          style={collapse_plot_cont}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker color={contColor} onChange={changeContColor} />
          </div>
        </div>
        {plotType === "Lollipop" ? (
          <>
            <label
              htmlFor="Marker"
              className="form-label mx-3"
              style={{ fontSize: "20px" }}
            >
              Stem :
            </label>
            <div
              style={{
                background: `rgba(${stemColor.r},${stemColor.g},${stemColor.b},${stemColor.a})`,
              }}
              className=" mx-2"
            >
              <p className={`options_plot_misc`} /*style={options_plot}*/>
                <button
                  className={`btn btn-primary btn-sm bottom_plot_elements options_button_misc`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseExample_mark`}
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  style={{
                    background: `rgba(${stemColor.r},${stemColor.g},${stemColor.b},${stemColor.a})`,
                  }}
                >
                  Color
                </button>
              </p>
            </div>
            <div
              className="collapse"
              id={`collapseExample_mark`}
              style={collapse_plot_mark}
            >
              <div className={`card card-body`}>
                <RgbaColorPicker
                  color={stemColor}
                  onChange={changeStemColor} /*onMouseUp={setTitleColorFn}*/
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_title"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          {"Title"}
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei_title"
          name="graphtitle"
          onChange={onChange}
          value={plotView.graphtitle}
          maxLength={30}
          style={{ width: "270px", textAlign: "center", height: "35px" }}
        />
        {/* <div style={{ display: "null" }}>
          
        </div> */}
        <div
          style={{
            background:
              plotType !== "SemiGauge"
                ? `rgba(${titleColor.r},${titleColor.g},${titleColor.b},${titleColor.a})`
                : `rgba(${valueColor.r},${valueColor.g},${valueColor.b},${valueColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_title`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background:
                  plotType !== "SemiGauge"
                    ? `rgba(${titleColor.r},${titleColor.g},${titleColor.b},${titleColor.a})`
                    : `rgba(${valueColor.r},${valueColor.g},${valueColor.b},${valueColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_title`}
          style={collapse_plot_title}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={plotType !== "SemiGauge" ? titleColor : valueColor}
              onChange={
                plotType !== "SemiGauge" ? changeTitleColor : changeValueColor
              } /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <label
          htmlFor="dimei_1"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          xAxis :
        </label>
        <div
          style={{
            background: `rgba(${xAxisColor.r},${xAxisColor.g},${xAxisColor.b},${xAxisColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_path`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${xAxisColor.r},${xAxisColor.g},${xAxisColor.b},${xAxisColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_path`}
          style={collapse_plot_path}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={xAxisColor}
              onChange={changeXAxisColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <label
          htmlFor="dimei_2"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          yAxis :
        </label>
        <div
          style={{
            background: `rgba(${yAxisColor.r},${yAxisColor.g},${yAxisColor.b},${yAxisColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_trail`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${yAxisColor.r},${yAxisColor.g},${yAxisColor.b},${yAxisColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_trail`}
          style={collapse_plot_trail}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={yAxisColor}
              onChange={changeYAxisColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_legisotext_1"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Key 1
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei_legisotext_1"
          name="legisotext11"
          onChange={onChange}
          value={plotView.legisotext11}
          maxLength={15}
          style={{ width: "150px", textAlign: "center", height: "35px" }}
        />
        <div
          style={{
            background: `rgba(${legIsoColor11.r},${legIsoColor11.g},${legIsoColor11.b},${legIsoColor11.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_leg_iso_11`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${legIsoColor11.r},${legIsoColor11.g},${legIsoColor11.b},${legIsoColor11.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_leg_iso_11`}
          style={collapse_plot_leg}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={legIsoColor11}
              onChange={changeLegIsoColor11} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <div
          style={{
            background: `rgba(${legIsoTextColor11.r},${legIsoTextColor11.g},${legIsoTextColor11.b},${legIsoTextColor11.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_leg_iso_text_11`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${legIsoTextColor11.r},${legIsoTextColor11.g},${legIsoTextColor11.b},${legIsoTextColor11.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_leg_iso_text_11`}
          style={collapse_plot_leg}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={legIsoTextColor11}
              onChange={changeLegIsoTextColor11} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <input
          className="form-check-input"
          type="checkbox"
          checked={keyAllow.keyallow1}
          // value={plotView.keyallow1}
          id="flexCheckDefault1"
          onChange={onChecked}
          name="keyallow1"
        />
        <label
          htmlFor="dimei_12"
          className="form-label mx-4"
          style={{ fontSize: "18px" }}
        >
          Grid :
        </label>
        <div
          style={{
            background: `rgba(${gridColor.r},${gridColor.g},${gridColor.b},${gridColor.a})`,
          }}
          className=""
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_value`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${gridColor.r},${gridColor.g},${gridColor.b},${gridColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_value`}
          style={collapse_plot_path}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={gridColor}
              onChange={changeGridColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <label
          htmlFor="dimei_1_fixed"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Decimal :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_fixed"
          name="dashsize"
          onChange={onChange}
          value={plotView.dashsize}
          // maxLength={15}
          min={0}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_1_max"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Max :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_max"
          name="highlimit"
          onChange={onChange}
          value={plotView.highlimit}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <label
          htmlFor="dimei_1_min"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Min :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_min"
          name="lowlimit"
          onChange={onChange}
          value={plotView.lowlimit}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_1_map_max"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Map_H :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_map_max"
          name="highmap"
          onChange={onChange}
          value={plotView.highmap}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <label
          htmlFor="dimei_1_map_min"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Map_L :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_map_min"
          name="lowmap"
          onChange={onChange}
          value={plotView.lowmap}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_1_marg_max"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Marg_H :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_marg_max"
          name="highlimitmargin"
          onChange={onChange}
          value={plotView.highlimitmargin}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <label
          htmlFor="dimei_1_marg_min"
          className="form-label mx-2 align-content-center"
          style={{ fontSize: "18px" }}
        >
          Marg_L :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_1_marg_min"
          name="lowlimitmargin"
          onChange={onChange}
          value={plotView.lowlimitmargin}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_popup_h"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Alert_H :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_popup_h"
          name="popup_h"
          onChange={onChange}
          value={plotView.popup_h}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <label
          htmlFor="dimei_popup_l"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Alert_L :
        </label>
        <input
          type="number"
          className="form-control mx-2"
          id="dimei_popup_l"
          name="popup_l"
          onChange={onChange}
          value={plotView.popup_l}
          // maxLength={15}
          style={{ width: "120px", textAlign: "center", height: "35px" }}
        />
        <input
          className="form-check-input"
          type="checkbox"
          checked={plotView.popup_enable}
          // value={plotView.keyallow1}
          id="flexCheckDefault_alert"
          onChange={onPopup}
          name="popup_enable"
          style={{ marginRight: "10px" }}
        />
        <label
          htmlFor="dimei_plot"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          Plot :
        </label>
        <div
          style={{
            background: `rgba(${plotColor.r},${plotColor.g},${plotColor.b},${plotColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_plot`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${plotColor.r},${plotColor.g},${plotColor.b},${plotColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_plot`}
          style={collapse_plot_plot}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={plotColor}
              onChange={changePlotColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_Marks"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          ToolTip :
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei1"
          name="tooltiphead"
          onChange={onChange}
          value={plotView.tooltiphead}
          maxLength={15}
          style={{ width: "150px", textAlign: "center", height: "35px" }}
        />
        <div
          style={{
            background: `rgba(${tooltipHeadColor.r},${tooltipHeadColor.g},${tooltipHeadColor.b},${tooltipHeadColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_marks`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${tooltipHeadColor.r},${tooltipHeadColor.g},${tooltipHeadColor.b},${tooltipHeadColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_marks`}
          style={collapse_plot_ticks}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={tooltipHeadColor}
              onChange={changeTooltipHeadColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <div
          style={{
            background: `rgba(${tooltipDataColor.r},${tooltipDataColor.g},${tooltipDataColor.b},${tooltipDataColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_needle_pin`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${tooltipDataColor.r},${tooltipDataColor.g},${tooltipDataColor.b},${tooltipDataColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_needle_pin`}
          style={collapse_plot_needle_pin}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={tooltipDataColor}
              onChange={changeTooltipDataColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
        <div
          style={{
            background: `rgba(${tooltipBgColor.r},${tooltipBgColor.g},${tooltipBgColor.b},${tooltipBgColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_leg`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_leg`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_needle_circle`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${tooltipBgColor.r},${tooltipBgColor.g},${tooltipBgColor.b},${tooltipBgColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_needle_circle`}
          style={collapse_plot_needle_circle}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={tooltipBgColor}
              onChange={changeTooltipBgColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_x_title"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          {"X Label"}
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei_x_title"
          name="xaxistitle"
          onChange={onChange}
          value={plotView.xaxistitle}
          maxLength={30}
          style={{ width: "270px", textAlign: "center", height: "35px" }}
        />
        {/* <div style={{ display: "null" }}>
          
        </div> */}
        <div
          style={{
            background: `rgba(${xLabelColor.r},${xLabelColor.g},${xLabelColor.b},${xLabelColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_x_label`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${xLabelColor.r},${xLabelColor.g},${xLabelColor.b},${xLabelColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_x_label`}
          style={collapse_plot_title}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={xLabelColor}
              onChange={changeXLabelColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
      </div>
      <div className="mb-3 d-flex flex-row align-items-center justify-content-start flex-wrap">
        <label
          htmlFor="dimei_y_title"
          className="form-label mx-2"
          style={{ fontSize: "18px" }}
        >
          {"Y Label"}
        </label>
        <input
          type="text"
          className="form-control mx-2"
          id="dimei_y_title"
          name="yaxistitle"
          onChange={onChange}
          value={plotView.yaxistitle}
          maxLength={30}
          style={{ width: "270px", textAlign: "center", height: "35px" }}
        />
        {/* <div style={{ display: "null" }}>
          
        </div> */}
        <div
          style={{
            background: `rgba(${yLabelColor.r},${yLabelColor.g},${yLabelColor.b},${yLabelColor.a})`,
          }}
          className=" mx-2"
        >
          <p className={`options_plot_title`} /*style={options_plot}*/>
            <button
              className={`btn btn-primary btn-sm bottom_plot_elements options_button_title`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseExample_y_label`}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                background: `rgba(${yLabelColor.r},${yLabelColor.g},${yLabelColor.b},${yLabelColor.a})`,
              }}
            >
              Color
            </button>
          </p>
        </div>
        <div
          className="collapse"
          id={`collapseExample_y_label`}
          style={collapse_plot_title}
        >
          <div className={`card card-body`}>
            <RgbaColorPicker
              color={yLabelColor}
              onChange={changeYLabelColor} /*onMouseUp={setTitleColorFn}*/
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonSpeedoPulsePara;
