import React, { useEffect, useRef, useState, useContext } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAdmin, updateAdmin } from "../help";
import DashContext from "../../context/dash/dashContext";

const Admin = () => {
  const context = useContext(DashContext);
  const { showAlert } = context;
  const [admin, setAdmin] = useState({
    aname: "",
    adate: "",
    apassword: "",
    _id: "",
  });
  const ref = useRef(null);
  const refClose = useRef(null);
  const addMutation = useMutation(getAdmin);
  const updateMutation = useMutation(updateAdmin);
  const queryClient = useQueryClient();
  const c_user = async (user) => {
    await addMutation.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        setAdmin({
          aname: e[0].name,
          adate: e[0].date,
          apassword: "",
          _id: e[0]._id,
        });
        ref.current.click();
      },
      onError: () => {},
    });
  };
  const c_user_1 = async (user) => {
    await updateMutation.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        if (e.success == true) {
          showAlert("Admin Update Suceessfully", "success");
        } else {
          showAlert("Admin Can't Be Updated", "danger");
        }
        // setAdmin({aname:e[0].name,adate:e[0].date,apassword:''})
        // ref.current.click();
      },
      onError: () => {},
    });
  };
  const handleAdminSettings = (e) => {
    e.preventDefault();
    c_user("bnuaibcuasb");
    //console.log(admin)
    //console.log(localStorage.getItem('loginId'))
    // history("/dummy");
  };
  const handleUpdateAdmin = () => {
    ref.current.click();
    // setAdmin({
    //     aname: admin.aname,
    //     apassword: admin.apassword
    //   });
    c_user_1({
      aname: admin.aname,
      apassword: admin.apassword,
      _id: admin._id,
    });
  };

  const onChange = (e) => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };
  useEffect(() => {
    if (admin.length) {
    }
    //console.log('matched')
  });
  return (
    <>
      <PersonIcon
        className="mx-2"
        style={{ cursor: "pointer" }}
        onClick={handleAdminSettings}
      ></PersonIcon>
      <div className="badge bg-primary text-wrap" style={{ width: "6rem" }}>
        {"Admin"}
      </div>
      <button
        style={{ display: "none" }}
        ref={ref}
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal9"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="exampleModal9"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Admin Settings
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form action="" className="my-3">
                <div className="mb-3">
                  <label htmlFor="aname" className="form-label">
                    Admin Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="aname"
                    name="aname"
                    onChange={onChange}
                    value={admin.aname}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="apassword" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="apassword"
                    name="apassword"
                    onChange={onChange}
                    value={admin.apassword}
                    // value={user.name}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="adate" className="form-label">
                    Date
                  </label>
                  <input
                    disabled={true}
                    type="text"
                    className="form-control"
                    id="adate"
                    name="adate"
                    value={admin.adate}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={refClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={admin.aname.length < 1}
                onClick={handleUpdateAdmin}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
