import React, { useContext, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spinner from './Spinner';

import { useDispatch,useSelector } from 'react-redux';
import DashContext from '../context/dash/dashContext';


const Dummy = () => {
  const context = useContext(DashContext);
  const { users, getAllUsers, editUser,deleteUser_,setUsers } = context;
  let location = useLocation();
  let history = useNavigate();
  useEffect(() => {
    // setTimeout(() => {
    //   history("/");
    // }, 1000);
    const interval = setInterval(() => {
      getAllUsers();
      console.log(users);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  // setInterval(() => {
  //   if(localStorage.getItem('userCount')==='ok') {
  //     localStorage.removeItem('userCount')
  //     console.log('OK')
  //   }
  //   else {
  //     console.log(localStorage.getItem('userLength'))
  //     console.log(localStorage.getItem('userCount'))
  //   }
  // }, 1000);
  return (
    <div>
      <Spinner></Spinner>
    </div>
  )
}

export default Dummy
