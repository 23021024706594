
import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import {useSelector } from "react-redux";

import $ from "jquery";
import "react-tooltip/dist/react-tooltip.css";

import "./imeidata.scss";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

const PlotTable = (props) => {
  const [actPlotData, setActPlotData] = useState();
  const [tablePlotData, setTablePlotData] = useState();
  const [update, setUpdate] = useState(0);
  const [lastUpdated, setLastUpdated] = useState();
  const [validUpdated, setValidUpdated] = useState(0);
  const updateRef = useRef(false);
  const {

    width,
    height,

    plotData,
    plotKeys,
    latestData,
    latestDate,
    validData,
    plotView,
    plotType
  } = props;
  let r = 0;
  if (width >= 820) {
    r = height / 8;
  } else if (width >= 625) {
    r = height / 5;
  } else {
    r = height / 4;
  }
  const storeData = useSelector((state) => {
    return state.app;
  });
  useEffect(() => {
    //func()
    if (plotData) {
        if(plotData.length>0) {
            // setActPlotData([]);
            // if(update===1)
            setActPlotData(plotData);
            // //console.log(plotData)
            $("#example_line").DataTable().destroy();
            setTablePlotData(plotData);
            $(document).ready(function () {
                setTimeout(function () {
                  $("#example_line").DataTable({
                    pagingType: "full_numbers",
                    //   pageLength: 5,
                    processing: true,
                    bDestroy: true,
                    dom: "lBfrtip",
                    buttons: ["copy", "csv", "excel", "pdf", "print"],
                    orderCellsTop: true,
                    fixedHeader: true,
                    //   sPaginationType: "full_numbers",
                    aaSorting: [[0, "asc"]],
                    iDisplayLength: 5,
                    aLengthMenu: [
                      [5, 10, 25, 50, 100, -1],
                      [5, 10, 25, 50, 100, "All"],
                    ],
                  });
                  //    //console.log(table)
                }, 50);
              });
        }
        if (validData === "0") {
            $("#example_line").DataTable().destroy();
            if(validUpdated===0) {
                setValidUpdated(1)
            }
        }
        if(validData==='1') {
            if(validUpdated===1) {
                setValidUpdated(0)
            }
        }
      // $("#example_line").DataTable().destroy();
      // setActPlotData_(plotData);
      // //console.log(plotData)
      //if(firstUpdate===1) {
      ////console.log('Update')
      //if(storeData.firstUpdate===0) {
      //dispatch(updateFirstPlot(1))
      ////console.log('PLOYT')
      //createGraph();
      //}
      //}
      // setUpdate(1)
      // //console.log(update)
    } else if (validData === "0") {
      //createEmptyGraph();
    //   //console.log("empty");
      $("#example_line").DataTable().destroy();
      if(validUpdated===0) {
        setValidUpdated(1)
    }
    //   $(document).ready(function () {
    //     setTimeout(function () {
    //       $("#example_line").DataTable({
    //         pagingType: "full_numbers",
    //         //   pageLength: 5,
    //         processing: true,
    //         bDestroy: true,
    //         dom: "lBfrtip",
    //         buttons: ["copy", "csv", "excel", "pdf", "print"],
    //         orderCellsTop: true,
    //         fixedHeader: true,
    //         //   sPaginationType: "full_numbers",
    //         aaSorting: [[0, "asc"]],
    //         iDisplayLength: 5,
    //         aLengthMenu: [
    //           [5, 10, 25, 50, 100, -1],
    //           [5, 10, 25, 50, 100, "All"],
    //         ],
    //       });
    //       //    //console.log(table)
    //     }, 1000);
    //   });
    //   setTablePlotData([])
    }

    if (actPlotData && update === 1) {
      // setFirstUpdate(1);
      if (plotData === actPlotData) {
        // //console.log('EQUAL')
      }
      //setUpdate(0)
      //if(storeData.firstPlot===1) {
      //if(storeData.firstPlot===1) {
      if (actPlotData.length > 0 && validData === "1") {
        if(validUpdated===1) {
            setValidUpdated(0)
        }
        // //console.log(validData);
        //createGraph();
      } else if (validData === "0") {
        // //console.log(plot_id)
        // //console.log("empty");
        //createEmptyGraph();
        $("#example_line").DataTable().destroy();
        // $(document).ready(function () {
        //     setTimeout(function () {
        //       $("#example_line").DataTable({
        //         pagingType: "full_numbers",
        //         //   pageLength: 5,
        //         processing: true,
        //         bDestroy: true,
        //         dom: "lBfrtip",
        //         buttons: ["copy", "csv", "excel", "pdf", "print"],
        //         orderCellsTop: true,
        //         fixedHeader: true,
        //         //   sPaginationType: "full_numbers",
        //         aaSorting: [[0, "asc"]],
        //         iDisplayLength: 5,
        //         aLengthMenu: [
        //           [5, 10, 25, 50, 100, -1],
        //           [5, 10, 25, 50, 100, "All"],
        //         ],
        //       });
        //       //    //console.log(table)
        //     }, 1000);
        //   });
        // setTablePlotData([])
      }
      //}
      // dispatch(updateFirstPlot(0))
      //}
      // toolRef.current.click()
      // setTooltipAllow(true)
    }
    // else if(firstUpdate===1) {
    //   //console.log('firstUpdate')
    //   //if(storeData.firstUpdate===0) {
    //     //dispatch(updateFirstPlot(1))
    //     ////console.log('PLOYT')
    //     createGraph();
    //   //}
    // }
    // setUpdate(1)
    // if(plotData) {
    //   if(plotData.length===actPlotData.length) {
    //     //   //console.log('fsomfosdm')
    //        createGraph();
    //     }
    // }
    //updateFetch.current = 1;
    // //console.log(plotData[0].datedevice);
  }, [
    width,
    height,
    lastUpdated,
    plotData,
    update,
    actPlotData,
    tablePlotData,
    validData,
    plotView,
  ]);

  useEffect(() => {
    if (latestData && actPlotData) {
      if (latestData.length > 0) {
        // //console.log(latestData)
        // //console.log(latestData)
        setActPlotData([...actPlotData, ...latestData]);
        setTablePlotData([...tablePlotData, ...latestData]);
        // $("#example_line").DataTable().destroy();
        // $(document).ready(function () {
        //     setTimeout(function () {
        //       $("#example_line").DataTable({
        //         pagingType: "full_numbers",
        //         //   pageLength: 5,
        //         processing: true,
        //         bDestroy: true,
        //         dom: "lBfrtip",
        //         buttons: ["copy", "csv", "excel", "pdf", "print"],
        //         orderCellsTop: true,
        //         fixedHeader: true,
        //         //   sPaginationType: "full_numbers",
        //         aaSorting: [[0, "asc"]],
        //         iDisplayLength: 5,
        //         aLengthMenu: [
        //           [5, 10, 25, 50, 100, -1],
        //           [5, 10, 25, 50, 100, "All"],
        //         ],
        //       });
        //       //    //console.log(table)
        //     }, 25);
        //   });
      } else {
        setLastUpdated(latestDate);
      }
      // if(latestDate) {
      //   // //console.log(latestDate)
      //   setLastUpdated(latestDate)
      // }
    }
  }, [latestData, latestDate]);

  useEffect(() => {
    if (updateRef.current === false) {
      // setUpdate(1)
      // setFirstUpdate(1)
      //setTimeout(() => {
      //   setFirstUpdate(1);
      setUpdate(1);
      // //console.log(plotView[0])
      // setActPlotData([]);
      // setActPlotData(storeData.plotFirstData)
      // //console.log('FirstUpdate')
      // //console.log(actPlotData)
      //createGraph()
      //}, 5000);
      return () => {
        updateRef.current = true;
        // d3.select(`#main_plot_${plot_id}`).remove()
        // dispatch(updateFirstPlot(1));
      };
    }
  }, []);

  // function mapValues(val) {
  //   let x1 = +plotView[0].lowlimit;
  //   let x2 = +plotView[0].highlimit;
  //   let y1 = +plotView[0].lowmap;
  //   let y2 = +plotView[0].highmap;
  //   let m = (y2 - y1) / (x2 - x1);
  //   let y = y1 + m * (val - x1);
  //   return y.toFixed(plotView[0].dashsize);
  // }
  function mapValues(val) {
    let x1 = +plotView[0].lowlimit;
    let x2 = +plotView[0].highlimit;
    let y1 = +plotView[0].lowmap;
    let y2 = +plotView[0].highmap;
    let m = (y2 - y1) / (x2 - x1);
    let y = y1 + m * (val - x1);
    let r;
    if(plotView[0].mainisokey1==='nas_6_14um') {
      if(y==-1) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class 00`
      }
      else if(y>=0 && y<=12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class ${y.toFixed(0)}`
      }
      else if(y>12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class > 12`
      }
    } else if(plotView[0].mainisokey1==='sae_4um') {
      if(y==-2) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class 000`
      }
      else if(y==-1) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class 00`
      }
      else if(y>=0 && y<=12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class ${y.toFixed(0)}`
      }
      else if(y>12 ) {
        r=`${Number(y).toFixed(+plotView[0].dashsize)} | Class > 12`
      }
    } else {
      r=`${Number(y).toFixed(+plotView[0].dashsize)}`
    }
    return r;
  }

  return (
    <>
      {update == 1 && tablePlotData && validUpdated===0 ? (
        tablePlotData.map((e, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              {update === 1 && plotKeys.length - 0 > 0 ? (
                <td>{mapValues(e[plotKeys[0]])}</td>
              ) : (
                ""
              )}
              {update === 1 && plotKeys.length - 1 > 0 ? (
                <td>{mapValues(e[plotKeys[1]])}</td>
              ) : (
                ""
              )}
              {update === 1 && plotKeys.length - 2 > 0 ? (
                <td>{mapValues(e[plotKeys[2]])}</td>
              ) : (
                ""
              )}
              {update === 1 && plotKeys.length - 3 > 0 ? (
                <td>{mapValues(e[plotKeys[3]])}</td>
              ) : (
                ""
              )}
              {update === 1 && plotKeys.length - 0 > 0 ? (
                <td>{e["datedevice"]}</td>
              ) : (
                ""
              )}
            </tr>
          );
        })
      ) : (
        <tr>
          <td>{"---"}</td>
          {storeData.plotKeys[`${plotType}`] &&
              storeData.plotKeys[`${plotType}`].length - 0 > 0 ?<td>{"---"}</td>:''}
          {storeData.plotKeys[`${plotType}`] &&
              storeData.plotKeys[`${plotType}`].length - 1 > 0 ?<td>{"---"}</td>:''}
          {storeData.plotKeys[`${plotType}`] &&
              storeData.plotKeys[`${plotType}`].length - 2 > 0 ?<td>{"---"}</td>:''}
          {storeData.plotKeys[`${plotType}`] &&
              storeData.plotKeys[`${plotType}`].length - 3 > 0 ?<td>{"---"}</td>:''}
          {storeData.plotKeys[`${plotType}`] &&
              storeData.plotKeys[`${plotType}`].length - 0 > 0 ?<td>{"---"}</td>:''}
        </tr>
      )}
    </>
  );
};

export default PlotTable;
