import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import "./imeiitem.scss";
import { Link } from "react-router-dom";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { useDispatch, useSelector } from "react-redux";

const ImeiItem = (props) => {
  const { imei, updateImeiClick, deleteImeiClick, user } = props;
  return (
    <div className="col-md-2 my-3 mx-4 childCard">
      <div className="card text-bg-dark" style={{ width: "19rem" }}>
        <div className="card-body">
          <div className="d-flex align-items-center text-bg-secondary p-3 ">
            <h5 className="card-title card-img-top" id={`imei_${imei.imei}`}>
              Device No: {imei.imei}
            </h5>
            {
              <EditIcon
                className={`mx-2 ${
                  localStorage.getItem("loginType") === "User"
                    ? "text-secondary"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  localStorage.getItem("loginType") === "Admin"
                    ? updateImeiClick(imei)
                    : ""
                }
              ></EditIcon>
            }
            {
              <DeleteIcon
                className={`mx-2 ${
                  localStorage.getItem("loginType") === "User"
                    ? "text-secondary"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  localStorage.getItem("loginType") === "Admin"
                    ? deleteImeiClick(imei.imei)
                    : ""
                }
              ></DeleteIcon>
            }
          </div>
          <div className="my-4 text-bg-light">
            <div>
              {""}
              <hr />
            </div>
            <div className="card-text mx-1">
              Location: {imei.location}
              <hr></hr>
            </div>
            <div className="card-text mx-1">
              Equipment: {imei.equipment}
              <hr></hr>
            </div>
            <div className="card-text mx-1">
              Status: {imei.active === true ? "Active" : "Inactive"}
              <hr></hr>
            </div>
            <div className="card-text mx-1">
              Updated: {imei.date}
              <hr></hr>
            </div>
            <div className="card-text mx-1">
              Current Status: Not Connected<hr></hr>
            </div>
          </div>
          <Link
            to={`/dashboard/${imei.user}/${user}/${imei.imei}`}
            className="btn btn-primary my-2"
            onClick={() => {
              localStorage.setItem("imeiId", imei._id);
              localStorage.setItem("imeiNo", imei.imei);
              localStorage.setItem("imeiLocation", imei.location);
              localStorage.setItem("imeiEquipment", imei.equipment);
              localStorage.setItem("userId", imei.user);
              localStorage.setItem("userName", user);
            }}
          >
            Data
          </Link>
        </div>
      </div>
      {localStorage.getItem("loginType") === "Admin" ? (
        <ReactTooltip
          anchorId={`imei_${imei.imei}`}
          place="top"
          content={imei._id}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ImeiItem;
