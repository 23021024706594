import {

  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, {
  useContext,
  useEffect,
  useState,
} from "react";

import {
  getInitialData,
  updateSeqProg,
} from "../../components/help";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSeqProgams,
  addSeqProg,
  addSeqProg_,
  addPlotData,
  addPlotKeys,
  updateFirstPlot,
  addPlotFirstData,
  addLastRecordDate,
  addLastRecordData,
  addValidData,
  addPlotView,
  addDashObj,
  addSensors,
} from "../../redux/features/postSlice";

import $ from "jquery";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./imeidata.scss";

import DashContext from "../../context/dash/dashContext";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PlotSeq = () => {
  const updateMutation = useMutation(updateSeqProg);
  const refreshMutation = useMutation(getInitialData);
  const context = useContext(DashContext);
  const { showAlert } = context;
  let storeData = useSelector((state) => {
    return state.app;
  });

  const params = useParams();
  let imeiId = localStorage.getItem("imeiId");

  let location = useLocation();
  let history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/");
    } else {
      dispatch(updateSeqProgams(1));
    }
  }, [location]);

  const queryClient = useQueryClient();

  const [plotSelected, setPlotSelected] = useState(1);

  const [actPlotView, setActPlotView] = useState({});
  const [actKeys, setActKeys] = useState({});

  const [titleColor, setTitleColor] = useState({});
  const [miscColor, setMiscColor] = useState({});
  const [contColor, setContColor] = useState({});
  const [markColor, setMarkColor] = useState({});
  const [pathColor, setPathColor] = useState({});
  const [dangerColor, setDangerColor] = useState({});
  const [trailColor, setTrailColor] = useState({});
  const [valueColor, setValueColor] = useState({});
  const [plotColor, setPlotColor] = useState({});
  const [marksColor, setMarksColor] = useState({});
  const [needlePinColor, setNeedlePinColor] = useState({});
  const [needleCircleColor, setNeedleCircleColor] = useState({});

  const [legIsoTextColor11, setLegIsoTextColor11] = useState({});
  const [legIsoTextColor12, setLegIsoTextColor12] = useState({});
  const [legIsoTextColor13, setLegIsoTextColor13] = useState({});
  const [legIsoTextColor14, setLegIsoTextColor14] = useState({});
  const [keyAllow, setKeyAllow] = useState({});

  const [settingUpdate, setSettingUpdate] = useState(0);

  const [segColor1, setSegColor1] = useState({});
  const [segColor2, setSegColor2] = useState({});
  const [segColor3, setSegColor3] = useState({});
  const [segColor4, setSegColor4] = useState({});
  const [segColor5, setSegColor5] = useState({});

  const [stemColor, setStemColor] = useState({});

  const [xAxisColor, setXAxisColor] = useState({});
  const [yAxisColor, setYAxisColor] = useState({});

  const [xLabelColor, setXLabelColor] = useState({});
  const [yLabelColor, setYLabelColor] = useState({});

  const [gridColor, setGridColor] = useState({});

  const [legIsoColor11, setLegIsoColor11] = useState({});
  const [legIsoColor12, setLegIsoColor12] = useState({});
  const [legIsoColor13, setLegIsoColor13] = useState({});
  const [legIsoColor14, setLegIsoColor14] = useState({});

  const [tooltipBgColor, setTooltipBgColor] = useState({});
  const [tooltipDataColor, setTooltipDataColor] = useState({});
  const [tooltipHeadColor, setTooltipHeadColor] = useState({});

  const [fetchData, setFetchData] = useState({
    imei: imeiId,
    dateFrom1: "",
    dateTo1: "",
    plot1: "",
    key1: [],
    dateFrom2: "",
    dateTo2: "",
    plot2: "",
    key2: [],
    dateFrom3: "",
    dateTo3: "",
    plot3: "",
    key3: [],
    dateFrom4: "",
    dateTo4: "",
    plot4: "",
    key4: [],
    dateFrom5: "",
    dateTo5: "",
    plot5: "",
    key5: [],
    dateFrom6: "",
    dateTo6: "",
    plot6: "",
    key6: [],
    dateFrom7: "",
    dateTo7: "",
    plot7: "",
    key7: [],
    dateFrom8: "",
    dateTo8: "",
    plot8: "",
    key8: [],
    lastRecordRec1: "",
    lastRecordRec2: "",
    lastRecordRec3: "",
    lastRecordRec4: "",
    lastRecordRec5: "",
    lastRecordRec6: "",
    lastRecordRec7: "",
    lastRecordRec8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  const [dateFromTo, setDateFromTo] = useState({
    dateFrom1: "",
    dateTo1: "",
    dateFrom2: "",
    dateTo2: "",
    dateFrom3: "",
    dateTo3: "",
    dateFrom4: "",
    dateTo4: "",
    dateFrom5: "",
    dateTo5: "",
    dateFrom6: "",
    dateTo6: "",
    dateFrom7: "",
    dateTo7: "",
    dateFrom8: "",
    dateTo8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  const c_user = async (user) => {
    await updateMutation.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        if (e.success == true) {
          showAlert("Plot Updated", "success");
          c_user_1({
            imei: imeiId,
          });
        } else {
          showAlert("Plot Can't Be Updated", "danger");
        }
        queryClient.invalidateQueries(["progseq"]);
      },
      onError: () => {
        showAlert("Plot Can't Be Updated", "danger");
      },
    });
  };

  const { status, data, isFetching } = useQuery(
    ["progseq"],
    () =>
      getInitialData({
        imei: imeiId,
      }),
    {
      onSuccess: (e) => {
        // //console.log(e)
        if (storeData.seqUpdate == 1) {
          let newNote = {
            imei: imeiId,
            dateFrom1: "",
            dateTo1: "",
            plot1: "",
            key1: [],
            dateFrom2: "",
            dateTo2: "",
            plot2: "",
            key2: [],
            dateFrom3: "",
            dateTo3: "",
            plot3: "",
            key3: [],
            dateFrom4: "",
            dateTo4: "",
            plot4: "",
            key4: [],
            dateFrom5: "",
            dateTo5: "",
            plot5: "",
            key5: [],
            dateFrom6: "",
            dateTo6: "",
            plot6: "",
            key6: [],
            dateFrom7: "",
            dateTo7: "",
            plot7: "",
            key7: [],
            dateFrom8: "",
            dateTo8: "",
            plot8: "",
            key8: [],
            lastRecordRec1: "",
            lastRecordRec2: "",
            lastRecordRec3: "",
            lastRecordRec4: "",
            lastRecordRec5: "",
            lastRecordRec6: "",
            lastRecordRec7: "",
            lastRecordRec8: "",
            lastFrom1: "",
            lastTo1: "",
            lastFrom2: "",
            lastTo2: "",
            lastFrom3: "",
            lastTo3: "",
            lastFrom4: "",
            lastTo4: "",
            lastFrom5: "",
            lastTo5: "",
            lastFrom6: "",
            lastTo6: "",
            lastFrom7: "",
            lastTo7: "",
            lastFrom8: "",
            lastTo8: "",
          };
          dispatch(updateSeqProgams(0));
          dispatch(updateFirstPlot(0));
          let tempProg = [];
          let tempProg_ = [];
          let tempKey = e.keys;
          let i = 0;
          var j = 0;
          for (i = 0; i < 8; i++) {
            let recProg = e.seqProg.map((e) => {
              if (e["ainplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "ainplot";
                // //console.log(tempKey['ainplot']);
                newNote[`key${i + 1}`] = tempKey["ainplot"];
                return { plot: "ainplot", progseq: `${i + 1}` };
              } else if (e["digplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "digplot";
                newNote[`key${i + 1}`] = tempKey["digplot"];
                return { plot: "digplot", progseq: `${i + 1}` };
              } else if (e["isoplot"] === Number(i + 1)) {
                setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["isoplot"];
                newNote[`plot${i + 1}`] = "isoplot";
                return { plot: "isoplot", progseq: `${i + 1}` };
              } else if (e["nasplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["nasplot"];
                newNote[`plot${i + 1}`] = "nasplot";
                return { plot: "nasplot", progseq: `${i + 1}` };
              } else if (e["pulseplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["pulseplot"];
                newNote[`plot${i + 1}`] = "pulseplot";
                return { plot: "pulseplot", progseq: `${i + 1}` };
              } else if (e["saeplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["saeplot"];
                newNote[`plot${i + 1}`] = "saeplot";
                return { plot: "saeplot", progseq: `${i + 1}` };
              } else if (e["sigplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["sigplot"];
                newNote[`plot${i + 1}`] = "sigplot";
                return { plot: "sigplot", progseq: `${i + 1}` };
              } else if (e["tempplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["tempplot"];
                newNote[`plot${i + 1}`] = "tempplot";
                return { plot: "tempplot", progseq: `${i + 1}` };
              }
            });
            if (recProg[0] !== undefined) {
              // //console.log(recProg)
              tempProg[j] = recProg;
              tempProg_[j] = recProg;
              j += 1;
            }
          }
          // //console.log(imeiId);
          if (e.seqProg[0]["ainplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "ainplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["digplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "digplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["isoplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "isoplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["nasplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "nasplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["pulseplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "pulseplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["saeplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "saeplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["sigplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "sigplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["tempplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "tempplot", progseq: `${0}` }];
            j += 1;
          }

          // //console.log(e.seqProg);
          // //console.log(tempProg);
          // //console.log(tempProg_);
          setFetchData(newNote);
          dispatch(addSeqProg(tempProg));
          dispatch(addSeqProg_(tempProg_));
          dispatch(addPlotData(e.data));
          dispatch(addPlotFirstData(e.data));
          dispatch(addPlotKeys(e.keys));
          dispatch(addLastRecordDate(e.lastRecordDate));
          dispatch(addValidData(e.validData));
          dispatch(addPlotView(e.plotView));
          dispatch(addLastRecordData(e.lastRecordValues));
          dispatch(addSensors(e.sensors));
          dispatch(addDashObj(newNote));
        }
        //settime()
      },
    }
  );
  const c_user_1 = async (user) => {
    await refreshMutation.mutate(user, {
      onSuccess: (e) => {
        //console.log(e);
        if (e.success == true) {
          let newNote = {
            imei: imeiId,
            dateFrom1: "",
            dateTo1: "",
            plot1: "",
            key1: [],
            dateFrom2: "",
            dateTo2: "",
            plot2: "",
            key2: [],
            dateFrom3: "",
            dateTo3: "",
            plot3: "",
            key3: [],
            dateFrom4: "",
            dateTo4: "",
            plot4: "",
            key4: [],
            dateFrom5: "",
            dateTo5: "",
            plot5: "",
            key5: [],
            dateFrom6: "",
            dateTo6: "",
            plot6: "",
            key6: [],
            dateFrom7: "",
            dateTo7: "",
            plot7: "",
            key7: [],
            dateFrom8: "",
            dateTo8: "",
            plot8: "",
            key8: [],
            lastRecordRec1: "",
            lastRecordRec2: "",
            lastRecordRec3: "",
            lastRecordRec4: "",
            lastRecordRec5: "",
            lastRecordRec6: "",
            lastRecordRec7: "",
            lastRecordRec8: "",
            lastFrom1: "",
            lastTo1: "",
            lastFrom2: "",
            lastTo2: "",
            lastFrom3: "",
            lastTo3: "",
            lastFrom4: "",
            lastTo4: "",
            lastFrom5: "",
            lastTo5: "",
            lastFrom6: "",
            lastTo6: "",
            lastFrom7: "",
            lastTo7: "",
            lastFrom8: "",
            lastTo8: "",
          };
          dispatch(updateSeqProgams(0));
          dispatch(updateFirstPlot(0));
          let tempProg = [];
          let tempProg_ = [];
          let tempKey = e.keys;
          let i = 0;
          var j = 0;
          let k = 0;
          for (i = 0; i < 8; i++) {
            let recProg = e.seqProg.map((e) => {
              if (e["ainplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "ainplot";
                // //console.log(tempKey['ainplot']);
                newNote[`key${i + 1}`] = tempKey["ainplot"];
                return { plot: "ainplot", progseq: `${i + 1}` };
              } else if (e["digplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "digplot";
                newNote[`key${i + 1}`] = tempKey["digplot"];
                return { plot: "digplot", progseq: `${i + 1}` };
              } else if (e["isoplot"] === Number(i + 1)) {
                setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["isoplot"];
                newNote[`plot${i + 1}`] = "isoplot";
                return { plot: "isoplot", progseq: `${i + 1}` };
              } else if (e["nasplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["nasplot"];
                newNote[`plot${i + 1}`] = "nasplot";
                return { plot: "nasplot", progseq: `${i + 1}` };
              } else if (e["pulseplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["pulseplot"];
                newNote[`plot${i + 1}`] = "pulseplot";
                return { plot: "pulseplot", progseq: `${i + 1}` };
              } else if (e["saeplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["saeplot"];
                newNote[`plot${i + 1}`] = "saeplot";
                return { plot: "saeplot", progseq: `${i + 1}` };
              } else if (e["sigplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["sigplot"];
                newNote[`plot${i + 1}`] = "sigplot";
                return { plot: "sigplot", progseq: `${i + 1}` };
              } else if (e["tempplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["tempplot"];
                newNote[`plot${i + 1}`] = "tempplot";
                return { plot: "tempplot", progseq: `${i + 1}` };
              }
            });
            if (recProg[0] !== undefined) {
              // //console.log(recProg)
              tempProg[j] = recProg;
              tempProg_[j] = recProg;
              j += 1;
            }
          }
          // //console.log(imeiId);
          if (e.seqProg[0]["ainplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "ainplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["digplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "digplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["isoplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "isoplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["nasplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "nasplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["pulseplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "pulseplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["saeplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "saeplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["sigplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "sigplot", progseq: `${0}` }];
            j += 1;
          }
          if (e.seqProg[0]["tempplot"] === Number(0)) {
            tempProg_[j] = [{ plot: "tempplot", progseq: `${0}` }];
            j += 1;
          }

          // //console.log(e.seqProg);
          setFetchData(newNote);
          dispatch(addSeqProg(tempProg));
          dispatch(addSeqProg_(tempProg_));
          dispatch(addPlotData(e.data));
          dispatch(addPlotFirstData(e.data));
          dispatch(addPlotKeys(e.keys));
          dispatch(addLastRecordDate(e.lastRecordDate));
          dispatch(addValidData(e.validData));
          dispatch(addPlotView(e.plotView));
          dispatch(addLastRecordData(e.lastRecordValues));
          dispatch(addSensors(e.sensors));
          dispatch(addDashObj(newNote));
        } else {
          showAlert("Plot Can't Be Refreshed", "danger");
        }
        queryClient.invalidateQueries(["progseq"]);
      },
      onError: () => {
        showAlert("Plot Can't Be Refreshed", "danger");
      },
    });
  };
  function reArrangeKeys(plotView) {
    let i = 0;
    let obj = [];
    let j = 0;
    for (i = 0; i < 4; i++) {
      if (plotView[`keyallow${i + 1}`] === true) {
        obj[j] = plotView[`keyiso${i + 1}`];
        j = j + 1;
      }
    }
    setActKeys(obj);
  }
  function reArrangeKeys_(plotView, key, checked) {
    let i = 0;
    let obj = [];
    let arrObj = {};
    let j = 0;
    let k = 0;
    let l = 0;
    let m = [-1, -1, -1, -1];
    let n = 0;
    let objCopy = plotView;
    let allow = {};
    // //console.log(key)
    for (i = 0; i < 4; i++) {
      if (keyAllow[`keyallow${i + 1}`] === true && key !== `keyallow${i + 1}`) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`keytext${j + 1}`] = objCopy[`keytext${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === true) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`keytext${j + 1}`] = objCopy[`keytext${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === false) {
        l = i;
        m[i] = i;
        // n = n + 1;
      } else {
        m[i] = i;
        // n = n + 1;
      }
    }
    for (k = 0; k < 4; k++) {
      if (m[k] === k) {
        // //console.log(k);
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${k + 1}`];
        arrObj[`keytext${j + 1}`] = objCopy[`keytext${k + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${k + 1}`];
        allow[`keyallow${j + 1}`] = false;
        j = j + 1;
      }
    }
    // if (checked === false) {
    //   arrObj[`legisotextcolor14`] = plotView[`legisotextcolor1${l + 1}`];
    //   arrObj[`keytext4`] = plotView[`keytext${l + 1}`];
    //   arrObj[`keyiso4`] = plotView[`keyiso${l + 1}`];
    // }
    // for(k=j;k<4;k++) {
    //   arrObj[`keytext${k+1}`]=plotView[`keytext${k+1}`]
    // }
    setActPlotView({
      ...actPlotView,
      [`keytext1`]: arrObj[`keytext1`],
      [`keytext2`]: arrObj[`keytext2`],
      [`keytext3`]: arrObj[`keytext3`],
      [`keytext4`]: arrObj[`keytext4`],

      [`legisotextcolor11`]: arrObj[`legisotextcolor11`],
      [`legisotextcolor12`]: arrObj[`legisotextcolor12`],
      [`legisotextcolor13`]: arrObj[`legisotextcolor13`],
      [`legisotextcolor14`]: arrObj[`legisotextcolor14`],

      [`keyiso1`]: arrObj[`keyiso1`],
      [`keyiso2`]: arrObj[`keyiso2`],
      [`keyiso3`]: arrObj[`keyiso3`],
      [`keyiso4`]: arrObj[`keyiso4`],
      [key]: checked,
    });
    setKeyAllow({
      ["keyallow1"]: allow.keyallow1,
      ["keyallow2"]: allow.keyallow2,
      ["keyallow3"]: allow.keyallow3,
      ["keyallow4"]: allow.keyallow4,
    });
    // //console.log(allow);
    setActKeys(obj);
    setSettingUpdate(1);
    setLegIsoTextColor11(hexAToRGBA(arrObj[`legisotextcolor11`]));
    setLegIsoTextColor12(hexAToRGBA(arrObj[`legisotextcolor12`]));
    setLegIsoTextColor13(hexAToRGBA(arrObj[`legisotextcolor13`]));
    setLegIsoTextColor14(hexAToRGBA(arrObj[`legisotextcolor14`]));
    setTimeout(() => {
      setSettingUpdate(0);
    }, 1000);
  }

  function reArrangeKeys_1(plotView, key, checked) {
    let i = 0;
    let obj = [];
    let arrObj = {};
    let j = 0;
    let k = 0;
    let l = 0;
    let m = [-1, -1, -1, -1];
    let n = 0;
    let objCopy = plotView;
    let allow = {};
    // //console.log(key)
    for (i = 0; i < 4; i++) {
      if (keyAllow[`keyallow${i + 1}`] === true && key !== `keyallow${i + 1}`) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`legisotext1${j + 1}`] = objCopy[`legisotext1${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`legisocolor1${j + 1}`] = objCopy[`legisocolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === true) {
        obj[j] = objCopy[`keyiso${i + 1}`];
        arrObj[`legisotext1${j + 1}`] = objCopy[`legisotext1${i + 1}`];
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${i + 1}`];
        arrObj[`legisocolor1${j + 1}`] = objCopy[`legisocolor1${i + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${i + 1}`];
        allow[`keyallow${j + 1}`] = true;
        j = j + 1;
      } else if (key === `keyallow${i + 1}` && checked === false) {
        l = i;
        m[i] = i;
        // n = n + 1;
      } else {
        m[i] = i;
        // n = n + 1;
      }
    }
    for (k = 0; k < 4; k++) {
      if (m[k] === k) {
        // //console.log(k);
        arrObj[`legisotextcolor1${j + 1}`] =
          objCopy[`legisotextcolor1${k + 1}`];
        arrObj[`legisocolor1${j + 1}`] = objCopy[`legisocolor1${k + 1}`];
        arrObj[`legisotext1${j + 1}`] = objCopy[`legisotext1${k + 1}`];
        arrObj[`keyiso${j + 1}`] = objCopy[`keyiso${k + 1}`];
        allow[`keyallow${j + 1}`] = false;
        j = j + 1;
      }
    }
    // if (checked === false) {
    //   arrObj[`legisotextcolor14`] = plotView[`legisotextcolor1${l + 1}`];
    //   arrObj[`keytext4`] = plotView[`keytext${l + 1}`];
    //   arrObj[`keyiso4`] = plotView[`keyiso${l + 1}`];
    // }
    // for(k=j;k<4;k++) {
    //   arrObj[`keytext${k+1}`]=plotView[`keytext${k+1}`]
    // }
    setActPlotView({
      ...actPlotView,
      [`legisotext11`]: arrObj[`legisotext11`],
      [`legisotext12`]: arrObj[`legisotext12`],
      [`legisotext13`]: arrObj[`legisotext13`],
      [`legisotext14`]: arrObj[`legisotext14`],

      [`legisotextcolor11`]: arrObj[`legisotextcolor11`],
      [`legisotextcolor12`]: arrObj[`legisotextcolor12`],
      [`legisotextcolor13`]: arrObj[`legisotextcolor13`],
      [`legisotextcolor14`]: arrObj[`legisotextcolor14`],

      [`legisocolor11`]: arrObj[`legisocolor11`],
      [`legisocolor12`]: arrObj[`legisocolor12`],
      [`legisocolor13`]: arrObj[`legisocolor13`],
      [`legisocolor14`]: arrObj[`legisocolor14`],

      [`keyiso1`]: arrObj[`keyiso1`],
      [`keyiso2`]: arrObj[`keyiso2`],
      [`keyiso3`]: arrObj[`keyiso3`],
      [`keyiso4`]: arrObj[`keyiso4`],
      [key]: checked,
    });
    setKeyAllow({
      ["keyallow1"]: allow.keyallow1,
      ["keyallow2"]: allow.keyallow2,
      ["keyallow3"]: allow.keyallow3,
      ["keyallow4"]: allow.keyallow4,
    });
    // //console.log(allow);
    setActKeys(obj);
    setSettingUpdate(1);
    setLegIsoTextColor11(hexAToRGBA(arrObj[`legisotextcolor11`]));
    setLegIsoTextColor12(hexAToRGBA(arrObj[`legisotextcolor12`]));
    setLegIsoTextColor13(hexAToRGBA(arrObj[`legisotextcolor13`]));
    setLegIsoTextColor14(hexAToRGBA(arrObj[`legisotextcolor14`]));

    setLegIsoColor11(hexAToRGBA(arrObj[`legisocolor11`]));
    setLegIsoColor12(hexAToRGBA(arrObj[`legisocolor12`]));
    setLegIsoColor13(hexAToRGBA(arrObj[`legisocolor13`]));
    setLegIsoColor14(hexAToRGBA(arrObj[`legisocolor14`]));
    // setTimeout(() => {
    //   setSettingUpdate(0);
    // }, 1000);
  }

  const handleDatePicker = (value, states) => {
    let str = 0;
    let str_ = 0;
    let newDate = new Date(value[0]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // setFromDate(str)
    // //console.log(str);
    newDate = new Date(value[1]);
    str_ = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // //console.log(str);
    // setToDate(str)
    // //console.log(str);
    ////console.log(value);
    ////console.log(states);
    ////console.log(plotSelected);
    setDateFromTo({
      ...dateFromTo,
      [`dateFrom${plotSelected}`]: str,
      [`dateTo${plotSelected}`]: str_,
      [`lastFrom${plotSelected}`]: str,
      [`lastTo${plotSelected}`]: str_,
    });

    if (str.length > 2 && str_.length > 2) {
      let newNote = {
        imei: "",
        dateFrom1: "",
        dateTo1: "",
        plot1: "",
        key1: [],
        dateFrom2: "",
        dateTo2: "",
        plot2: "",
        key2: [],
        dateFrom3: "",
        dateTo3: "",
        plot3: "",
        key3: [],
        dateFrom4: "",
        dateTo4: "",
        plot4: "",
        key4: [],
        dateFrom5: "",
        dateTo5: "",
        plot5: "",
        key5: [],
        dateFrom6: "",
        dateTo6: "",
        plot6: "",
        key6: [],
        dateFrom7: "",
        dateTo7: "",
        plot7: "",
        key7: [],
        dateFrom8: "",
        dateTo8: "",
        plot8: "",
        key8: [],
        lastRecordRec1: "",
        lastRecordRec2: "",
        lastRecordRec3: "",
        lastRecordRec4: "",
        lastRecordRec5: "",
        lastRecordRec6: "",
        lastRecordRec7: "",
        lastRecordRec8: "",
        lastFrom1: "",
        lastTo1: "",
        lastFrom2: "",
        lastTo2: "",
        lastFrom3: "",
        lastTo3: "",
        lastFrom4: "",
        lastTo4: "",
        lastFrom5: "",
        lastTo5: "",
        lastFrom6: "",
        lastTo6: "",
        lastFrom7: "",
        lastTo7: "",
        lastFrom8: "",
        lastTo8: "",
      };
      let i = 0;
      let j = 0;
      newNote.imei = imeiId;
      for (i = 0; i < 8; i++) {
        if (i === plotSelected - 1) {
          newNote[`dateFrom${i + 1}`] = str;
          newNote[`dateTo${i + 1}`] = str_;
          newNote[`lastFrom${i + 1}`] = str;
          newNote[`lastTo${i + 1}`] = str_;
        } else {
          newNote[`dateFrom${i + 1}`] = dateFromTo[`dateFrom${i + 1}`];
          newNote[`dateTo${i + 1}`] = dateFromTo[`dateTo${i + 1}`];
          newNote[`lastFrom${i + 1}`] = dateFromTo[`lastFrom${i + 1}`];
          newNote[`lastTo${i + 1}`] = dateFromTo[`lastTo${i + 1}`];
        }
      }
      if (storeData.progSeq[0]) {
        newNote.plot1 = storeData.progSeq[0][0].plot;
        newNote.key1 = storeData.plotKeys[`${storeData.progSeq[0][0].plot}`];
      }
      if (storeData.progSeq[1]) {
        newNote.plot2 = storeData.progSeq[1][0].plot;
        newNote.key2 = storeData.plotKeys[`${storeData.progSeq[1][0].plot}`];
      }
      if (storeData.progSeq[2]) {
        newNote.plot3 = storeData.progSeq[2][0].plot;
        newNote.key3 = storeData.plotKeys[`${storeData.progSeq[2][0].plot}`];
      }
      if (storeData.progSeq[3]) {
        newNote.plot4 = storeData.progSeq[3][0].plot;
        newNote.key4 = storeData.plotKeys[`${storeData.progSeq[3][0].plot}`];
      }
      if (storeData.progSeq[4]) {
        newNote.plot5 = storeData.progSeq[4][0].plot;
        newNote.key5 = storeData.plotKeys[`${storeData.progSeq[4][0].plot}`];
      }
      if (storeData.progSeq[5]) {
        newNote.plot6 = storeData.progSeq[5][0].plot;
        newNote.key6 = storeData.plotKeys[`${storeData.progSeq[5][0].plot}`];
      }
      if (storeData.progSeq[6]) {
        newNote.plot7 = storeData.progSeq[6][0].plot;
        newNote.key7 = storeData.plotKeys[`${storeData.progSeq[6][0].plot}`];
      }
      if (storeData.progSeq[7]) {
        newNote.plot8 = storeData.progSeq[7][0].plot;
        newNote.key8 = storeData.plotKeys[`${storeData.progSeq[7][0].plot}`];
      }
      dispatch(addDashObj(newNote));
      setFetchData(newNote);

      //console.log(newNote);
    }
  };

  const handleOk = (f, t, p) => {
    //console.log(dateFromTo[`dateFrom${p}`]);
    //console.log(dateFromTo[`dateTo${p}`]);
    //console.log(p);
    // setPlotSelected(p);
  };

  const onChange = (e) => {
    // //console.log(e.target.value);
    // //console.log(e.target.name);
    // setActPlotView({
    //   ...actPlotView[`${sensorPlot}`][0],
    //   [`${e.target.name}`]: e.target.value,
    // });
    //let r = { [`${e.target.name}`]: e.target.value };
    setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.value });
    // //console.log(storeData.lastRecordDate["datedevice"]);
  };
  const onChecked_ = (e) => {
    // //console.log(e.target.name)
    reArrangeKeys_1(actPlotView, e.target.name, e.target.checked);
    // setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.checked });
  };
  const onPopup = (e) => {
    setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.checked });
  };
  const changeTitleColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`titlecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTitleColor(e);
  };
  const changeMiscColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`misccolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setMiscColor(e);
  };
  const changeLegIsoTextColor11 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor11`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor11(e);
  };
  const changeLegIsoTextColor12 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor12`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor12(e);
  };
  const changeLegIsoTextColor13 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor13`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor13(e);
  };
  const changeLegIsoTextColor14 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisotextcolor14`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoTextColor14(e);
  };

  const changeLegIsoColor11 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor11`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor11(e);
  };
  const changeLegIsoColor12 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor12`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor12(e);
  };
  const changeLegIsoColor13 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor13`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor13(e);
  };
  const changeLegIsoColor14 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor14`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setLegIsoColor14(e);
  };

  const changeContColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`contbgcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setContColor(e);
  };
  const changeMarkColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipheadcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setMarkColor(e);
    setSettingUpdate(1);
    setTimeout(() => {
      setSettingUpdate(0);
    }, 1000);
  };
  const changePathColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`innercircle1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setPathColor(e);
  };
  const changeTrailColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`outercircle1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTrailColor(e);
  };
  const changeDangerColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecirclestroke1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setDangerColor(e);
  };
  const changeValueColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`currenttext1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setValueColor(e);
  };
  const changePlotColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`plotbgcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setPlotColor(e);
  };
  const changeMarksColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`majorticks1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
      [`minorticks1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setMarksColor(e);
  };
  const changeNeedlePinColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecolor1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setNeedlePinColor(e);
  };
  const changeNeedleCircleColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecircle1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setNeedleCircleColor(e);
  };

  const changeSegColor1 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlestrokecolor1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor1(e);
  };
  const changeSegColor2 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlecirclestroke1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor2(e);
  };
  const changeSegColor3 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor13`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor3(e);
  };
  const changeSegColor4 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`legisocolor14`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor4(e);
  };
  const changeSegColor5 = (e) => {
    setActPlotView({
      ...actPlotView,
      [`needlestrokecolor1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setSegColor5(e);
  };
  const changeStemColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`linestrokecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setStemColor(e);
  };
  const changeXAxisColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`xaxislinecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setXAxisColor(e);
  };
  const changeYAxisColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`yaxislinecolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setYAxisColor(e);
  };
  const changeGridColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`isomisc1`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setGridColor(e);
  };

  const changeTooltipBgColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipbgcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTooltipBgColor(e);
  };
  const changeTooltipDataColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipdatacolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTooltipDataColor(e);
  };
  const changeTooltipHeadColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`tooltipheadcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setTooltipHeadColor(e);
  };

  const changeXLabelColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`xlabelcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setXLabelColor(e);
  };
  const changeYLabelColor = (e) => {
    setActPlotView({
      ...actPlotView,
      [`ylabelcolor`]: RGBAToHexA(e.r, e.g, e.b, e.a),
    });
    setYLabelColor(e);
  };
  const savePlotView = (e) => {
    // //console.log('ncfiodsnciodsncio')
    //console.log(actPlotView);
    c_user(actPlotView);
  };

  const setTitleColorFn = () => {
    setActPlotView({
      ...actPlotView,
      [`titlecolor`]: RGBAToHexA(
        titleColor.r,
        titleColor.g,
        titleColor.b,
        titleColor.a
      ),
    });
    // //console.log(RGBAToHexA(titleColor.r,titleColor.g,titleColor.b,titleColor.a))
  };
  function RGBAToHexA(r, g, b, a) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;

    return "#" + r + g + b + a;
  }
  function hexAToRGBA(h) {
    let r = 0,
      g = 0,
      b = 0,
      a = 1,
      d = 0;
    // //console.log(h.length);
    if (h.length === 7) {
      r = Number("0x" + h[1] + h[2]);
      g = Number("0x" + h[3] + h[4]);
      b = Number("0x" + h[5] + h[6]);
      d = 0;
    } else if (h.length === 9) {
      r = Number("0x" + h[1] + h[2]);
      g = Number("0x" + h[3] + h[4]);
      b = Number("0x" + h[5] + h[6]);
      a = "0x" + h[7] + h[8];
      a = +(a / 255).toFixed(3);
      d = 1;
    }
    if (d === 1) {
      return { r: r, g: g, b: b, a: a };
    }
    return { r: r, g: g, b: b, a: 1 };
  }
  // //console.log(hexAToRGBA('#ffabcd'))

  $(`options_plot_1`).css("marginRight", "50px");
  // //console.log($(`options_plot_1`));

  const handleDragEnd = (results) => {
    if (!results.destination) return;
    let valuy = storeData.progSeq_;
    var tempProg = [...storeData.progSeq_];
    let actProg = [
      [{ plot: "ainplot", progseq: "0" }],
      [{ plot: "digplot", progseq: "0" }],
      [{ plot: "isoplot", progseq: "0" }],
      [{ plot: "saeplot", progseq: "0" }],
      [{ plot: "nasplot", progseq: "0" }],
      [{ plot: "sigplot", progseq: "0" }],
      [{ plot: "tempplot", progseq: "0" }],
      [{ plot: "pulseplot", progseq: "0" }],
    ];
    // //console.log(storeData.progSeq_[results.source.index][0].progseq)
    let tempSeqProg1 = storeData.progSeq_[results.source.index][0].progseq;
    let tempSeqProg2 = storeData.progSeq_[results.destination.index][0].progseq;
    let updateProg = {};
    let i = 0;
    let j = 0;
    let k = 0;
    let r;
    //console.log(storeData.progSeq_[results.destination.index][0].progseq);
    j = results.source.index; //source
    k = results.destination.index; //destination
    if (
      storeData.progSeq_[results.source.index][0].progseq != 0 &&
      storeData.progSeq_[results.destination.index][0].progseq != 0
    ) {
      let [selectRow] = tempProg.splice(results.source.index, 1);
      tempProg.splice(results.destination.index, 0, selectRow);
      // tempProg[results.source.index][0].progseq=k
      // tempProg[results.destination.index][0]=j
      for (i = 0; i < 8; i++) {
        if (tempProg[i][0].progseq != 0) {
          actProg[i][0].progseq = `${i + 1}`;
          actProg[i][0].plot = tempProg[i][0].plot;
        } else {
          actProg[i][0].progseq = `${0}`;
          actProg[i][0].plot = tempProg[i][0].plot;
        }
      }
      // r = actProg[k][0].progseq;
      // actProg[k][0].progseq = actProg[j][0].progseq;
      // actProg[j][0].progseq = r;
      // //console.log(actProg,j,k);
      for (i = 0; i < 8; i++) {
        if (tempProg[i][0].plot === "ainplot") {
          updateProg["ainplot"] = actProg[i][0].progseq;
        } else if (tempProg[i][0].plot === "isoplot") {
          updateProg["isoplot"] = actProg[i][0].progseq;
        } else if (tempProg[i][0].plot === "nasplot") {
          updateProg["nasplot"] = actProg[i][0].progseq;
        } else if (tempProg[i][0].plot === "saeplot") {
          updateProg["saeplot"] = actProg[i][0].progseq;
        } else if (tempProg[i][0].plot === "digplot") {
          updateProg["digplot"] = actProg[i][0].progseq;
        } else if (tempProg[i][0].plot === "tempplot") {
          updateProg["tempplot"] = actProg[i][0].progseq;
        } else if (tempProg[i][0].plot === "pulseplot") {
          updateProg["pulseplot"] = actProg[i][0].progseq;
        } else if (tempProg[i][0].plot === "sigplot") {
          updateProg["sigplot"] = actProg[i][0].progseq;
        }
      }
      updateProg["imei"]=imeiId
      dispatch(addSeqProg_(actProg));
      // r = updateProg[`${j}`];
      // updateProg[`${j}`] = updateProg[`${k}`];
      // updateProg[`${k}`] = r;
      // //console.log(updateProg);
      c_user(updateProg)
    } else return;
  };
  const onChecked = (e) => {
    let i = 0;
    let j = 0;
    let r = 0;
    let s = 0;
    let up = 0;
    let updateProg = {};
    let actProg = [
      [{ plot: "ainplot", progseq: "0" }],
      [{ plot: "digplot", progseq: "0" }],
      [{ plot: "isoplot", progseq: "0" }],
      [{ plot: "saeplot", progseq: "0" }],
      [{ plot: "nasplot", progseq: "0" }],
      [{ plot: "sigplot", progseq: "0" }],
      [{ plot: "tempplot", progseq: "0" }],
      [{ plot: "pulseplot", progseq: "0" }],
    ];

    ////console.log(e.target.name)
    ////console.log(e.target.checked)
    for (i = 0; i < 8; i++) {
      actProg[i][0].progseq = storeData.progSeq_[i][0].progseq;
      actProg[i][0].plot = storeData.progSeq_[i][0].plot;
      if (e.target.checked === false) {
        if (storeData.progSeq_[i][0].plot == e.target.name) {
          j = i;
          r = storeData.progSeq_[i][0];
        }
      } else {
        if (storeData.progSeq_[i][0].progseq == 0) {
          if (up === 0) {
            up = 1;
            j = i;
            r = storeData.progSeq_[i][0];
          }
        }
        if (storeData.progSeq_[i][0].plot === e.target.name) {
          s = i;
        }
      }
    }
    if (e.target.checked == false) {
      for (i = j; i < 7; i++) {
        //if(storeData.progSeq_[i][0].plot!=e.target.name) {
        // actProg[i][0].plot=storeData.progSeq_[i][0].plot
        if (storeData.progSeq_[i + 1][0].progseq != 0) {
          actProg[i][0].progseq = `${i + 1}`;
          actProg[i][0].plot = storeData.progSeq_[i + 1][0].plot;
        } else {
          actProg[i][0].progseq = `${0}`;
          actProg[i][0].plot = storeData.progSeq_[i + 1][0].plot;
        }
        //}
      }
      actProg[i][0].progseq = `${0}`;
      actProg[i][0].plot = r.plot;
    } else if (e.target.checked == true) {
      //for(i=0;i<8;i++) {
      if (j != s) {
        actProg[s][0].progseq = `${j + 1}`;
        i = actProg[s][0];
        actProg[s][0] = actProg[j][0];
        actProg[j][0] = i;
      } else {
        actProg[j][0].progseq = `${j + 1}`;
      }
      //if(storeData.progSeq_[i][0].plot==e.target.name) {
      // actProg[i][0].plot=storeData.progSeq_[i][0].plot
      //actProg[i][0].progseq=`${i+1}`
      //}
      // else if(storeData.progSeq_[i][0].progseq!=0) {
      //   actProg[i][0].progseq=storeData.progSeq_[i][0].progseq
      // }
      //}
    }
    for (i = 0; i < 8; i++) {
      if (actProg[i][0].plot === "ainplot") {
        updateProg["ainplot"] = actProg[i][0].progseq;
      } else if (actProg[i][0].plot === "isoplot") {
        updateProg["isoplot"] = actProg[i][0].progseq;
      } else if (actProg[i][0].plot === "nasplot") {
        updateProg["nasplot"] = actProg[i][0].progseq;
      } else if (actProg[i][0].plot === "saeplot") {
        updateProg["saeplot"] = actProg[i][0].progseq;
      } else if (actProg[i][0].plot === "digplot") {
        updateProg["digplot"] = actProg[i][0].progseq;
      } else if (actProg[i][0].plot === "tempplot") {
        updateProg["tempplot"] = actProg[i][0].progseq;
      } else if (actProg[i][0].plot === "pulseplot") {
        updateProg["pulseplot"] = actProg[i][0].progseq;
      } else if (actProg[i][0].plot === "sigplot") {
        updateProg["sigplot"] = actProg[i][0].progseq;
      }
    }
    updateProg["imei"]=imeiId
    // //console.log(updateProg);
    dispatch(addSeqProg_(actProg));
    c_user(updateProg)
    //reArrangeKeys_(actPlotView, e.target.name, e.target.checked);
    // setActPlotView({ ...actPlotView, [`${e.target.name}`]: e.target.checked });
  };

  return (
    <>
      <h2
        style={{ textAlign: "center" }}
        className="my-2"
        id={`imei6_${imeiId}`}
      >
        {`Device : ${params.imei}`}{" "}
        {localStorage.getItem("loginType") === "Admin"
          ? `User: ${params.user}`
          : `Location: ${localStorage.getItem("imeiLocation")}`}
      </h2>
      <h3 style={{ textAlign: "center" }} className="" id={`plot6_${imeiId}`}>
        {"Plot Sequence"}
      </h3>
      <h4 style={{ textAlign: "center" }} className="" id={`plot7_${imeiId}`}>
        {localStorage.getItem("imeiEquipment")
          ? `Equipment: ${localStorage.getItem("imeiEquipment")}`
          : ""}
      </h4>
      {/* <h4 style={{ textAlign: "center" }} className="" id={`plot7_${imeiId}_1`}>
        {`Date Modified: `}
      </h4> */}
      <div className="programDiv">
        <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
          <table className="table table-hover programTable">
            <thead>
              <tr>
                <th scope="col">Sequence</th>
                <th scope="col">Sensor Plot</th>
                <th scope="col">Active Status</th>
              </tr>
            </thead>
            <Droppable droppableId="tbody">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {storeData.progSeq_ && storeData.progSeq_.length
                    ? storeData.progSeq_.map((prog, i) => {
                        // //console.log(prog[0])
                        return (
                          <Draggable
                            draggableId={`${prog[0].plot}`}
                            index={i}
                            key={prog[0].plot}
                          >
                            {(provided) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <th scope="row" {...provided.dragHandleProps}>
                                  {prog[0].progseq !== "0" ? `${i + 1}` : `0`}
                                </th>
                                <td>{prog[0].plot}</td>
                                <td>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={
                                      prog[0].progseq !== "0" ? true : false
                                    }
                                    // value={plotView.keyallow1}
                                    id={`flexCheckDefault2_${i}`}
                                    onChange={onChecked}
                                    name={`${prog[0].plot}`}
                                    style={{ marginRight: "10px" }}
                                  />
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        );
                      })
                    : ""}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
      {/* </div> */}
      {/* TAbles */}
      {localStorage.getItem("loginType") === "Admin" ? (
        <ReactTooltip
          anchorId={`imei6_${imeiId}`}
          place="top"
          content={`Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} Location: ${localStorage.getItem("imeiLocation")}`}
          multiline={true}
          html={`Device Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} 
                   Company: ${localStorage.getItem("companyName")}<br />
                   Customer: ${localStorage.getItem("customerName")}`}
          //localStorage.getItem('imeiLocation')
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PlotSeq;
