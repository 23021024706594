import {
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";

import {
  getPlotData,
  getInitialData,
} from "../../components/help";
import {useQuery} from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSeqProgams,
  addSeqProg,
  addPlotData,
  addPlotKeys,
  updateFirstPlot,
  addPlotFirstData,
  addPlotLatestData,
  addPlotLatestDate,
  addLastRecordDate,
  addLastRecordData,
  addValidData,
  addPlotView,
  addDashObj,
  addSensors,
} from "../../redux/features/postSlice";

import $ from "jquery";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import "./imeidata.scss";

import Resizable from "react-resizable-box";
import SpeedoGroupPlot from "../../components/d3svg/SpeedoGroupPlot";
import SemiSpeedoGroupPlot from "../../components/d3svg/SemiSpeedoGroupPlot";
import SemiGaugeGroupPlot from "../../components/d3svg/SemiGaugeGroupPlot";
import ProgressGroupPlot from "../../components/d3svg/ProgressGroupPlot";
import LineGroupPlot from "../../components/d3svg/LineGroupPlot";
import PlotTable from "./PlotTable";

import { useReactToPrint } from "react-to-print";
import LineScatterGroupPlot from "../../components/d3svg/LineScatterGroupPlot";
import ScatterGroupPlot from "../../components/d3svg/ScatterGroup";
import LollipopGroupPlot from "../../components/d3svg/LollipopGroupPlot";
import AreaGroupPlot from "../../components/d3svg/AreaGroupPlot";

const PulsePlot = () => {
  const storeData = useSelector((state) => {
    return state.app;
  });

  const params = useParams();
  let imeiId = localStorage.getItem("imeiId");

  let location = useLocation();
  let history = useNavigate();
  const dispatch = useDispatch();

  const updateRef = useRef(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history("/");
    } else {
      dispatch(updateSeqProgams(1));
    }
  }, [location]);
  useEffect(() => {
    setPlotWidth(window.innerWidth);
    setPlotHeight(window.innerHeight);
    //console.log(window.innerHeight, window.innerWidth);
    if(updateRef.current == true) {
      $("#example_line").DataTable().destroy();
    $(document).ready(function () {
        setTimeout(function () {
          $("#example_line").DataTable({
            pagingType: "full_numbers",
            //   pageLength: 5,
            processing: true,
            bDestroy: true,
            dom: "lBfrtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            orderCellsTop: true,
            fixedHeader: true,
            //   sPaginationType: "full_numbers",
            aaSorting: [[0, "asc"]],
            iDisplayLength: 5,
            aLengthMenu: [
              [5, 10, 25, 50, 100, -1],
              [5, 10, 25, 50, 100, "All"],
            ],
          });
          //    //console.log(table)
        }, 2000);
      });
    }
  }, [window.innerHeight, window.innerWidth]);

  useEffect(() => {
    if (updateRef.current === false) {
      $(document).ready(function () {
        setTimeout(function () {
          $("#example_line").DataTable({
            pagingType: "full_numbers",
            //   pageLength: 5,
            processing: true,
            bDestroy: true,
            dom: "lBfrtip",
            buttons: ["copy", "csv", "excel", "pdf", "print"],
            orderCellsTop: true,
            fixedHeader: true,
            //   sPaginationType: "full_numbers",
            aaSorting: [[0, "asc"]],
            iDisplayLength: 5,
            aLengthMenu: [
              [5, 10, 25, 50, 100, -1],
              [5, 10, 25, 50, 100, "All"],
            ],
          });
          //    //console.log(table)
        }, 1000);
      });
    }
    return () => {
      updateRef.current = true;
      // d3.select(`#main_plot_${plot_id}`).remove()
      // dispatch(updateFirstPlot(1));
    };
  }, []);

  const [plotSelected, setPlotSelected] = useState();

  const [plotType, setPlotType] = useState();

  const [sensorPlot, setSensorPlot] = useState(`pulseplot`);

  const [actPlot, setActPlot] = useState([1]);

  const [plotWidth, setPlotWidth] = useState(window.innerWidth);
  const [plotHeight, setPlotHeight] = useState(window.innerHeight);

  const [fetchData, setFetchData] = useState({
    imei: imeiId,
    dateFrom1: "",
    dateTo1: "",
    plot1: "",
    key1: [],
    dateFrom2: "",
    dateTo2: "",
    plot2: "",
    key2: [],
    dateFrom3: "",
    dateTo3: "",
    plot3: "",
    key3: [],
    dateFrom4: "",
    dateTo4: "",
    plot4: "",
    key4: [],
    dateFrom5: "",
    dateTo5: "",
    plot5: "",
    key5: [],
    dateFrom6: "",
    dateTo6: "",
    plot6: "",
    key6: [],
    dateFrom7: "",
    dateTo7: "",
    plot7: "",
    key7: [],
    dateFrom8: "",
    dateTo8: "",
    plot8: "",
    key8: [],
    lastRecordRec1: "",
    lastRecordRec2: "",
    lastRecordRec3: "",
    lastRecordRec4: "",
    lastRecordRec5: "",
    lastRecordRec6: "",
    lastRecordRec7: "",
    lastRecordRec8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  const [dateFromTo, setDateFromTo] = useState({
    dateFrom1: "",
    dateTo1: "",
    dateFrom2: "",
    dateTo2: "",
    dateFrom3: "",
    dateTo3: "",
    dateFrom4: "",
    dateTo4: "",
    dateFrom5: "",
    dateTo5: "",
    dateFrom6: "",
    dateTo6: "",
    dateFrom7: "",
    dateTo7: "",
    dateFrom8: "",
    dateTo8: "",
    lastFrom1: "",
    lastTo1: "",
    lastFrom2: "",
    lastTo2: "",
    lastFrom3: "",
    lastTo3: "",
    lastFrom4: "",
    lastTo4: "",
    lastFrom5: "",
    lastTo5: "",
    lastFrom6: "",
    lastTo6: "",
    lastFrom7: "",
    lastTo7: "",
    lastFrom8: "",
    lastTo8: "",
  });

  const { status, data, isFetching } = useQuery(
    ["progseq"],
    () =>
      getInitialData({
        imei: imeiId,
      }),
    {
      onSuccess: (e) => {
        // //console.log(e)
        if (storeData.seqUpdate == 1) {
          let newNote = {
            imei: imeiId,
            dateFrom1: "",
            dateTo1: "",
            plot1: "",
            key1: [],
            dateFrom2: "",
            dateTo2: "",
            plot2: "",
            key2: [],
            dateFrom3: "",
            dateTo3: "",
            plot3: "",
            key3: [],
            dateFrom4: "",
            dateTo4: "",
            plot4: "",
            key4: [],
            dateFrom5: "",
            dateTo5: "",
            plot5: "",
            key5: [],
            dateFrom6: "",
            dateTo6: "",
            plot6: "",
            key6: [],
            dateFrom7: "",
            dateTo7: "",
            plot7: "",
            key7: [],
            dateFrom8: "",
            dateTo8: "",
            plot8: "",
            key8: [],
            lastRecordRec1: "",
            lastRecordRec2: "",
            lastRecordRec3: "",
            lastRecordRec4: "",
            lastRecordRec5: "",
            lastRecordRec6: "",
            lastRecordRec7: "",
            lastRecordRec8: "",
            lastFrom1: "",
            lastTo1: "",
            lastFrom2: "",
            lastTo2: "",
            lastFrom3: "",
            lastTo3: "",
            lastFrom4: "",
            lastTo4: "",
            lastFrom5: "",
            lastTo5: "",
            lastFrom6: "",
            lastTo6: "",
            lastFrom7: "",
            lastTo7: "",
            lastFrom8: "",
            lastTo8: "",
          };
          dispatch(updateSeqProgams(0));
          dispatch(updateFirstPlot(0));
          let tempProg = [];
          let tempKey = e.keys;
          let i = 0;
          var j = 0;
          for (i = 0; i < 8; i++) {
            let recProg = e.seqProg.map((e) => {
              if (e["ainplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "ainplot";
                // //console.log(tempKey['ainplot']);
                newNote[`key${i + 1}`] = tempKey["ainplot"];
                return { plot: "ainplot", progseq: `${i + 1}` };
              } else if (e["digplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`plot${i + 1}`] = "digplot";
                newNote[`key${i + 1}`] = tempKey["digplot"];
                return { plot: "digplot", progseq: `${i + 1}` };
              } else if (e["isoplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["isoplot"];
                newNote[`plot${i + 1}`] = "isoplot";
                return { plot: "isoplot", progseq: `${i + 1}` };
              } else if (e["nasplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["nasplot"];
                newNote[`plot${i + 1}`] = "nasplot";
                return { plot: "nasplot", progseq: `${i + 1}` };
              } else if (e["pulseplot"] === Number(i + 1)) {
                setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["pulseplot"];
                newNote[`plot${i + 1}`] = "pulseplot";
                return { plot: "pulseplot", progseq: `${i + 1}` };
              } else if (e["saeplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["saeplot"];
                newNote[`plot${i + 1}`] = "saeplot";
                return { plot: "saeplot", progseq: `${i + 1}` };
              } else if (e["sigplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["sigplot"];
                newNote[`plot${i + 1}`] = "sigplot";
                return { plot: "sigplot", progseq: `${i + 1}` };
              } else if (e["tempplot"] === Number(i + 1)) {
                // setPlotSelected(i + 1);
                newNote[`key${i + 1}`] = tempKey["tempplot"];
                newNote[`plot${i + 1}`] = "tempplot";
                return { plot: "tempplot", progseq: `${i + 1}` };
              }
            });
            if (recProg[0] !== undefined) {
              // //console.log(recProg)
              tempProg[j] = recProg;
              j += 1;
            }
          }
          // //console.log(e.validData['ainplot']);
          dispatch(addSeqProg(tempProg));
          dispatch(addPlotData(e.data));
          dispatch(addPlotFirstData(e.data));
          dispatch(addPlotKeys(e.keys));
          dispatch(addLastRecordDate(e.lastRecordDate));
          dispatch(addValidData(e.validData));
          dispatch(addPlotView(e.plotView));
          dispatch(addLastRecordData(e.lastRecordValues));
          dispatch(addSensors(e.sensors));
          dispatch(addDashObj(newNote));
          // //console.log(e.plotView[0])
          setFetchData(newNote);
          setPlotType(e.plotView[`${sensorPlot}`][0].graphtype);
          // //console.log(e.plotView['ainplot'][0].graphtype)
        }
        //settime()
      },
    }
  );

  const { data1 } = useQuery(
    ["product"],
    () => {
      if (storeData.dashObj.imei) {
        return getPlotData(storeData.dashObj);
      } else {
        return getPlotData(fetchData);
      }
    },
    {
      onSuccess: (e) => {
        let i = 0;
        let j = 0;
        // //console.log(storeData.progSeq[1][0].plot)
        let newNote = {
          imei: imeiId,
          dateFrom1: "",
          dateTo1: "",
          plot1: "",
          key1: [],
          dateFrom2: "",
          dateTo2: "",
          plot2: "",
          key2: [],
          dateFrom3: "",
          dateTo3: "",
          plot3: "",
          key3: [],
          dateFrom4: "",
          dateTo4: "",
          plot4: "",
          key4: [],
          dateFrom5: "",
          dateTo5: "",
          plot5: "",
          key5: [],
          dateFrom6: "",
          dateTo6: "",
          plot6: "",
          key6: [],
          dateFrom7: "",
          dateTo7: "",
          plot7: "",
          key7: [],
          dateFrom8: "",
          dateTo8: "",
          plot8: "",
          key8: [],
          lastRecordRec1: "",
          lastRecordRec2: "",
          lastRecordRec3: "",
          lastRecordRec4: "",
          lastRecordRec5: "",
          lastRecordRec6: "",
          lastRecordRec7: "",
          lastRecordRec8: "",
          lastFrom1: "",
          lastTo1: "",
          lastFrom2: "",
          lastTo2: "",
          lastFrom3: "",
          lastTo3: "",
          lastFrom4: "",
          lastTo4: "",
          lastFrom5: "",
          lastTo5: "",
          lastFrom6: "",
          lastTo6: "",
          lastFrom7: "",
          lastTo7: "",
          lastFrom8: "",
          lastTo8: "",
        };
        dispatch(addPlotData(e.data));
        // //console.log(e.latestData['ainplot'].length)
        dispatch(addValidData(e.validData));
        dispatch(addPlotLatestData(e.latestData));
        dispatch(addPlotLatestDate(e.latestDate));
        dispatch(addLastRecordDate(e.lastRecordDate));
        dispatch(addLastRecordData(e.lastRecordValues));
        if (storeData.progSeq) {
          if (storeData.progSeq.length > 0) {
            for (i = 0; i < 8; i++) {
              newNote[`lastFrom${i + 1}`] = fetchData[`lastFrom${i + 1}`];
              newNote[`lastTo${i + 1}`] = fetchData[`lastTo${i + 1}`];
              newNote[`plot${i + 1}`] = fetchData[`plot${i + 1}`];
              newNote[`key${i + 1}`] = fetchData[`key${i + 1}`];

              newNote[`lastFrom${i + 1}`] =
                storeData.dashObj[`lastFrom${i + 1}`];
              newNote[`lastTo${i + 1}`] = storeData.dashObj[`lastTo${i + 1}`];
              newNote[`plot${i + 1}`] = storeData.dashObj[`plot${i + 1}`];
              newNote[`key${i + 1}`] = storeData.dashObj[`key${i + 1}`];
            }
            if (e.latestDate) {
              // //console.log(e.latestDate)
              for (i = 0; i < storeData.progSeq.length; i++) {
                if (e.latestDate[storeData.progSeq[i][0].plot]) {
                  newNote[`lastRecordRec${i + 1}`] =
                    e.latestDate[storeData.progSeq[i][0].plot];
                } else {
                  newNote[`lastRecordRec${i + 1}`] =
                    fetchData[`lastRecordRec${i + 1}`];
                }
              }
            } else {
              for (i = 0; i < storeData.progSeq.length; i++) {
                newNote[`lastRecordRec${i + 1}`] =
                  fetchData[`lastRecordRec${i + 1}`];
              }
            }
          }
        }
        if (storeData.dashObj.imei && e.imei) {
          // //console.log(storeData.dashObj)
          dispatch(addDashObj(newNote));
        }
        if (fetchData.imei && e.imei) {
          setFetchData(newNote);
        }
        // //console.log(storeData.dashObj.imei);
        // //console.log(e);
        // //console.log(e.data);
      },
      refetchIntervalInBackground: true,
      refetchInterval: 4000,
    }
  );

  const handleDatePicker = (value, states) => {
    let str = 0;
    let str_ = 0;
    let newDate = new Date(value[0]);
    str = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // setFromDate(str)
    // //console.log(str);
    newDate = new Date(value[1]);
    str_ = `${newDate.getFullYear()}-${
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : `${newDate.getMonth() + 1}`
    }-${
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : `${newDate.getDate()}`
    }`;
    // //console.log(str);
    // setToDate(str)
    // //console.log(str);
    ////console.log(value);
    ////console.log(states);
    ////console.log(plotSelected);
    setDateFromTo({
      ...dateFromTo,
      [`dateFrom${plotSelected}`]: str,
      [`dateTo${plotSelected}`]: str_,
      [`lastFrom${plotSelected}`]: str,
      [`lastTo${plotSelected}`]: str_,
    });

    if (str.length > 2 && str_.length > 2) {
      let newNote = {
        imei: "",
        dateFrom1: "",
        dateTo1: "",
        plot1: "",
        key1: [],
        dateFrom2: "",
        dateTo2: "",
        plot2: "",
        key2: [],
        dateFrom3: "",
        dateTo3: "",
        plot3: "",
        key3: [],
        dateFrom4: "",
        dateTo4: "",
        plot4: "",
        key4: [],
        dateFrom5: "",
        dateTo5: "",
        plot5: "",
        key5: [],
        dateFrom6: "",
        dateTo6: "",
        plot6: "",
        key6: [],
        dateFrom7: "",
        dateTo7: "",
        plot7: "",
        key7: [],
        dateFrom8: "",
        dateTo8: "",
        plot8: "",
        key8: [],
        lastRecordRec1: "",
        lastRecordRec2: "",
        lastRecordRec3: "",
        lastRecordRec4: "",
        lastRecordRec5: "",
        lastRecordRec6: "",
        lastRecordRec7: "",
        lastRecordRec8: "",
        lastFrom1: "",
        lastTo1: "",
        lastFrom2: "",
        lastTo2: "",
        lastFrom3: "",
        lastTo3: "",
        lastFrom4: "",
        lastTo4: "",
        lastFrom5: "",
        lastTo5: "",
        lastFrom6: "",
        lastTo6: "",
        lastFrom7: "",
        lastTo7: "",
        lastFrom8: "",
        lastTo8: "",
      };
      let i = 0;
      let j = 0;
      newNote.imei = imeiId;
      for (i = 0; i < 8; i++) {
        if (i === plotSelected - 1) {
          newNote[`dateFrom${i + 1}`] = str;
          newNote[`dateTo${i + 1}`] = str_;
          newNote[`lastFrom${i + 1}`] = str;
          newNote[`lastTo${i + 1}`] = str_;
        } else {
          newNote[`dateFrom${i + 1}`] = dateFromTo[`dateFrom${i + 1}`];
          newNote[`dateTo${i + 1}`] = dateFromTo[`dateTo${i + 1}`];
          newNote[`lastFrom${i + 1}`] = dateFromTo[`lastFrom${i + 1}`];
          newNote[`lastTo${i + 1}`] = dateFromTo[`lastTo${i + 1}`];
        }
      }
      if (storeData.progSeq[0]) {
        newNote.plot1 = storeData.progSeq[0][0].plot;
        newNote.key1 = storeData.plotKeys[`${storeData.progSeq[0][0].plot}`];
      }
      if (storeData.progSeq[1]) {
        newNote.plot2 = storeData.progSeq[1][0].plot;
        newNote.key2 = storeData.plotKeys[`${storeData.progSeq[1][0].plot}`];
      }
      if (storeData.progSeq[2]) {
        newNote.plot3 = storeData.progSeq[2][0].plot;
        newNote.key3 = storeData.plotKeys[`${storeData.progSeq[2][0].plot}`];
      }
      if (storeData.progSeq[3]) {
        newNote.plot4 = storeData.progSeq[3][0].plot;
        newNote.key4 = storeData.plotKeys[`${storeData.progSeq[3][0].plot}`];
      }
      if (storeData.progSeq[4]) {
        newNote.plot5 = storeData.progSeq[4][0].plot;
        newNote.key5 = storeData.plotKeys[`${storeData.progSeq[4][0].plot}`];
      }
      if (storeData.progSeq[5]) {
        newNote.plot6 = storeData.progSeq[5][0].plot;
        newNote.key6 = storeData.plotKeys[`${storeData.progSeq[5][0].plot}`];
      }
      if (storeData.progSeq[6]) {
        newNote.plot7 = storeData.progSeq[6][0].plot;
        newNote.key7 = storeData.plotKeys[`${storeData.progSeq[6][0].plot}`];
      }
      if (storeData.progSeq[7]) {
        newNote.plot8 = storeData.progSeq[7][0].plot;
        newNote.key8 = storeData.plotKeys[`${storeData.progSeq[7][0].plot}`];
      }
      dispatch(addDashObj(newNote));
      setFetchData(newNote);

      //console.log(newNote);
    }
  };

  const handleOk = (f, t, p) => {
    //console.log(dateFromTo[`dateFrom${p}`]);
    //console.log(dateFromTo[`dateTo${p}`]);
    //console.log(p);
    // setPlotSelected(p);
  };

  $(`options_plot_1`).css("marginRight", "50px");
  // //console.log($(`options_plot_1`));

  return (
    <>
      <div>
        <h2
          style={{ textAlign: "center" }}
          className="my-2"
          id={`imei6_${imeiId}`}
        >
          {`Device : ${params.imei}`}{" "}
          {localStorage.getItem("loginType") === "Admin"
            ? `User: ${params.user}`
            : `Location: ${localStorage.getItem("imeiLocation")}`}
        </h2>
        <h3 style={{ textAlign: "center" }} className="" id={`plot6_${imeiId}`}>
          Pulse Plot
        </h3>
        <h4 style={{ textAlign: "center" }} className="" id={`plot7_${imeiId}`}>
          {(localStorage.getItem("imeiEquipment")?`Equipment: ${localStorage.getItem("imeiEquipment")}`:'')}
        </h4>
        <div
          className="mainContainer"
          id={`main_Plot`}
          style={{ width: "100%", padding: "10px" }}
        >
          {storeData.progSeq && storeData.progSeq.length
            ? actPlot.map((i) => {
                let r = 0;
                let h = 0;
                let l = 0;
                let q = 0;
                let r1 = 0;
                // //console.log(storeData.lastRecordData[`ainplot`])
                if (storeData.plotData[`${sensorPlot}`]) {
                  if (storeData.plotData[`${sensorPlot}`].length > 0)
                    r = storeData.plotData[`${sensorPlot}`];
                } else {
                  // r=addDummyJson(storeData.plotKeys[`${prog[0].plot}`])
                  r = {};
                }
                if (storeData.plotLatestData[`${sensorPlot}`]) {
                  if (storeData.plotLatestData[`${sensorPlot}`].length > 0) {
                    q = storeData.plotLatestData[`${sensorPlot}`];
                  } else {
                    q = {};
                  }
                } else {
                  q = {};
                }
                if (storeData.lastRecordData[`${sensorPlot}`]) {
                  if (storeData.lastRecordData[`${sensorPlot}`]) {
                    r1 = storeData.lastRecordData[`${sensorPlot}`];
                  } else {
                    r1 = {};
                  }
                } else {
                  r1 = {};
                }
                let PROPS_ = {
                  plot_id: plotSelected,
                  title: "Title",
                  width: plotWidth - 100,
                  height: plotHeight / 1.9,
                  imei: imeiId,
                  datePicker: handleDatePicker,
                  handleOk: handleOk,
                  plotData: r,
                  plotKeys: storeData.plotKeys[`${sensorPlot}`],
                  plot_: `${sensorPlot}`,
                  latestData: q,
                  latestDate: storeData.lastRecordDate["datedevice"],
                  lastRecordData: storeData.lastRecordData[`${sensorPlot}`],
                  validData: storeData.validData[`${sensorPlot}`],
                  plotView: storeData.plotView[`${sensorPlot}`],
                  plotType: `${sensorPlot}`,
                };
                switch (storeData.plotView[`${sensorPlot}`][0].graphtype) {
                  case "Speedo":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={plotWidth - 100}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_0`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <SpeedoGroupPlot
                            key={`${sensorPlot}_1`}
                            {...PROPS_}
                          ></SpeedoGroupPlot>
                        </Resizable>
                      </>
                    );
                    break;
                  case "LineScatter":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotWidth - 100}
                        // height={plotHeight / 1.9}
                        key={`${sensorPlot}_2`}
                        // onResize={handleDragEnd}
                        id={i}
                        enable={false}
                      >
                        <LineScatterGroupPlot
                          key={`${sensorPlot}_3`}
                          {...PROPS_}
                        ></LineScatterGroupPlot>
                      </Resizable>
                    );
                    break;
                  case "Line":
                    return (
                      <>
                        <Resizable
                          className={`item subContainer 566 plot_${i + 1}`}
                          width={plotWidth - 100}
                          // height={plotHeight / 1.9}
                          key={`${sensorPlot}_4`}
                          // onResize={handleDragEnd}
                          id={i}
                          enable={false}
                        >
                          <LineGroupPlot
                            key={`${sensorPlot}_5`}
                            {...PROPS_}
                          ></LineGroupPlot>
                          {/* <PlotTable  key={`${sensorPlot}_6`} {...PROPS_}></PlotTable> */}
                        </Resizable>
                      </>
                    );
                    break;
                  case "Scatter":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotWidth - 100}
                        // height={plotHeight / 1.9}
                        key={`${sensorPlot}_7`}
                        // onResize={handleDragEnd}
                        id={i}
                        enable={false}
                      >
                        <ScatterGroupPlot
                          key={`${sensorPlot}_8`}
                          {...PROPS_}
                        ></ScatterGroupPlot>
                      </Resizable>
                    );
                    break;
                  case "Lollipop":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotWidth - 100}
                        // height={plotHeight / 1.9}
                        key={`${sensorPlot}_9`}
                        // onResize={handleDragEnd}
                        id={i}
                        enable={false}
                      >
                        <LollipopGroupPlot
                          key={`${sensorPlot}_10`}
                          {...PROPS_}
                        ></LollipopGroupPlot>
                      </Resizable>
                    );
                    break;
                  case "Area":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotWidth - 100}
                        // height={plotHeight / 1.9}
                        key={`${sensorPlot}_11`}
                        // onResize={handleDragEnd}
                        id={i}
                        enable={false}
                      >
                        <AreaGroupPlot
                          key={`${sensorPlot}_12`}
                          {...PROPS_}
                        ></AreaGroupPlot>
                      </Resizable>
                    );
                    break;
                  case "SemiGauge":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotWidth - 100}
                        // height={plotHeight / 1.9}
                        key={`${sensorPlot}_13`}
                        // onResize={handleDragEnd}
                        id={i}
                        enable={false}
                      >
                        <SemiGaugeGroupPlot
                          key={`${sensorPlot}_14`}
                          {...PROPS_}
                        ></SemiGaugeGroupPlot>
                      </Resizable>
                    );
                    break;
                  case "SemiSpeedo":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotWidth - 100}
                        // height={plotHeight / 1.9}
                        key={`${sensorPlot}_15`}
                        // onResize={handleDragEnd}
                        id={i}
                        enable={false}
                      >
                        <SemiSpeedoGroupPlot
                          key={`${sensorPlot}_16`}
                          {...PROPS_}
                        ></SemiSpeedoGroupPlot>
                      </Resizable>
                    );
                    break;
                  case "Progress":
                    return (
                      <Resizable
                        className={`item subContainer 566 plot_${i + 1}`}
                        width={plotWidth - 100}
                        // height={plotHeight / 1.9}
                        key={`${sensorPlot}_17`}
                        // onResize={handleDragEnd}
                        id={i}
                        enable={false}
                      >
                        <ProgressGroupPlot
                          key={`${sensorPlot}_18`}
                          {...PROPS_}
                        ></ProgressGroupPlot>
                      </Resizable>
                    );
                    break;
                  default:
                    break;
                }
                return i;
              })
            : ""}
        </div>
        {/* TAbles */}
      </div>
      {localStorage.getItem("loginType") === "Admin" ? (
        <ReactTooltip
          anchorId={`imei6_${imeiId}`}
          place="top"
          content={`Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} Location: ${localStorage.getItem("imeiLocation")}`}
          multiline={true}
          html={`Device Location: ${localStorage.getItem(
            "imeiLocation"
          )} ${`<br />`} 
               Company: ${localStorage.getItem("companyName")}<br />
               Customer: ${localStorage.getItem("customerName")}`}
          //localStorage.getItem('imeiLocation')
        />
      ) : (
        ""
      )}
      <div className="table_iso" style={{display:'flex',justifyContent:'center',marginBottom:'15px'}}>
        <table
          id="example_line"
          className="table display table-hover table-bordered"
          width={plotWidth-100}
        >
          <thead>
            <tr>
              <th>Sr No.</th>
              {storeData.plotKeys[`${sensorPlot}`] &&
              storeData.plotKeys[`${sensorPlot}`].length - 0 > 0 ? (
                <th>{storeData.plotView[`${sensorPlot}`][0].keytext1}</th>
              ) : (
                ""
              )}
              {storeData.plotKeys[`${sensorPlot}`] &&
              storeData.plotKeys[`${sensorPlot}`].length - 1 > 0 ? (
                <th>{storeData.plotView[`${sensorPlot}`][0].keytext2}</th>
              ) : (
                ""
              )}
              {storeData.plotKeys[`${sensorPlot}`] &&
              storeData.plotKeys[`${sensorPlot}`].length - 2 > 0 ? (
                <th>{storeData.plotView[`${sensorPlot}`][0].keytext3}</th>
              ) : (
                ""
              )}
              {storeData.plotKeys[`${sensorPlot}`] &&
              storeData.plotKeys[`${sensorPlot}`].length - 3 > 0 ? (
                <th>{storeData.plotView[`${sensorPlot}`][0].keytext4}</th>
              ) : (
                ""
              )}
              {storeData.plotKeys[`${sensorPlot}`] ? (
                <th>Date Modified</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {storeData.progSeq ? (
              <PlotTable
                key={1}
                plot_id={plotSelected}
                title={"Title"}
                width={plotWidth}
                height={plotHeight}
                imei={imeiId}
                plotData={storeData.plotData[`${sensorPlot}`]}
                plotKeys={storeData.plotKeys[`${sensorPlot}`]}
                plot_={`${sensorPlot}`}
                latestData={storeData.plotLatestData[`${sensorPlot}`]}
                latestDate={storeData.lastRecordDate["datedevice"]}
                lastRecordData={storeData.lastRecordData[`${sensorPlot}`]}
                validData={storeData.validData[`${sensorPlot}`]}
                plotView={storeData.plotView[`${sensorPlot}`]}
                plotType={`${sensorPlot}`}
              ></PlotTable>
            ) : (
              <tr>
              <td>1234</td>
            </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PulsePlot;

