import { useEffect, useState } from "react";
import DashContext from "./dashContext";
import { useDispatch,useSelector } from 'react-redux';
import $ from "jquery";

const DashState = (props) => {
  const {posts,loading}=useSelector((state)=>({...state.app}))
  const dispatch=useDispatch()
  const host = "http://localhost:5000/";
  const notes1 = [
    {
      _id: "643c1b5fdcae0e31d12ba832",
      user: "643be334478e31e692f95e97",
      title: "Title 1",
      description: "'ac.as;,.casdm,poasdmca'",
      tag: "General",
      date: "2023-04-16T15:59:27.156Z",
      __v: 0,
    },
    {
      _id: "644271ffa08818d68702f5b7",
      user: "643be334478e31e692f95e97",
      title: "Title 1",
      description: "'ac.as;,.casdm,poasdmca'",
      tag: "General",
      date: "2023-04-21T11:22:39.603Z",
      __v: 0,
    },
    {
      _id: "643c1b5fdcae0e31d12ba831",
      user: "643be334478e31e692f95e97",
      title: "Title 1",
      description: "'ac.as;,.casdm,poasdmca'",
      tag: "General",
      date: "2023-04-16T15:59:27.156Z",
      __v: 0,
    },
    {
      _id: "643c1b5fdcae0e31d12ba833",
      user: "643be334478e31e692f95e97",
      title: "Title 1",
      description: "'ac.as;,.casdm,poasdmca'",
      tag: "General",
      date: "2023-04-16T15:59:27.156Z",
      __v: 0,
    },
    {
      _id: "643c1b5fdcae0e31d12ba834",
      user: "643be334478e31e692f95e97",
      title: "Title 1",
      description: "'ac.as;,.casdm,poasdmca'",
      tag: "General",
      date: "2023-04-16T15:59:27.156Z",
      __v: 0,
    },
  ];
  const [users, setUsers] = useState();
  let user_json={}
  //GET ALL NOTES
  const getAllNotes = async () => {
    const url = `${host}api/notes/fetchallnotes/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQzYmUzMzQ0NzhlMzFlNjkyZjk1ZTk3In0sImlhdCI6MTY4MTY1ODIzMH0.-SUVcaQlDM5uDQbE8SmYW-cEFxXDw99CFU9lDOMSG8c",
      },
    });
    const json = await response.json();
    setNotes(json);
  };
  //ADD A NOTE
  const addNote = async (title, description, tag) => {
    const url = `${host}api/notes/addnote/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ title, description, tag }),
    });
    const json = await response.json();
    getAllNotes();
    //setNotes(notes.concat({title,description,tag}));
  };
  //EDIT A NOTE
  const editNote = async (id, title, description, tag) => {
    const url = `${host}api/notes/updatenote/${id}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ title, description, tag }),
    });
    const json = await response.json();
    getAllNotes();
  };
  //DELETE A NOTE
  const deleteNote = async (id) => {
    console.log(id);
    const url = `${host}api/notes/deletenote/${id}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
      },
    });
    const json = await response.json();
    getAllNotes();
    //const newNotes = notes.filter((note) => note._id !== id);
    //setNotes(newNotes);
  };

  //GET ALL Users
  const getAllUsers = async () => {
    let a={}
    const url = `${host}api/auth/getuser/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQzYmUzMzQ0NzhlMzFlNjkyZjk1ZTk3In0sImlhdCI6MTY4MTY1ODIzMH0.-SUVcaQlDM5uDQbE8SmYW-cEFxXDw99CFU9lDOMSG8c",
      },
    });
    const json = await response.json();
    return json
    // setUsers(json);
    // dispatch(getPosts({id:'budsuduwbduwbduw'}));
    //user_json=json;
    //console.log(json[0].name)
    //console.log(users)
  };
  //EDIT A NOTE
  const editUser = async (user) => {
    if(user.active==='false') user.active=false
    else user.active=true
    console.log(user.active)
    if(user.password==undefined) delete user['password']
    else console.log('define')
    const url = `${host}api/auth/updateuser`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({uname:user.name,upassword:user.password,ucompaneyName:user.companyName,uactive:user.active,_id:user._id}),
    });
    const json = await response.json();
    if(json.success==true) showAlert("User Updated","success")
    else showAlert("User Can't Be Updated","danger")
    // getAllUsers();
    // console.log(posts)
  };
    //DELETE A User
    const deleteUser_ = async (name) => {
      const url = `${host}api/auth/deleteuser`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "auth-token": `${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({name:name})
      });
      const json = await response.json();
      if(json.success==true) {
        showAlert("User Deleted","success")
        console.log('Delete')
        localStorage.setItem('userLength',(posts.length-1))
        localStorage.setItem('userCount','not')
        // console.log(posts)
      }
      else showAlert("User Can't Be Deleted","danger")
      getAllUsers();
      //const newNotes = users.filter((note) => note.name !== name);
      // setUsers(json);
    };
      //ADD A NOTE
  const addUser = async (user) => {
    const url = `${host}api/auth/createuser/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
      },
      body: JSON.stringify( user ),
    });
    const json = await response.json();
    if(json.success==true) showAlert("User Created","success")
    else showAlert("User Can't Be Created","danger")
    // getAllUsers();
    //setUsers(users);
  };

  const dummy_func = () => {
    console.log("dummy");
  };

  const s1 = {
    name: "Haroon",
    age: 28,
  };
  
  const [notes, setNotes] = useState(notes1);
  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };
  const parse_date=(date)=>{
    const d = new Date(date);
    let r=0;
    if(d.getMonth()>8) {
      r=`${d.getMonth()+1}`
    }
    else {
      r=`0${d.getMonth()+1}`
    }
    return `${d.getFullYear()}-${r}-${d.getDate()}`  
  }
  useEffect(() => {
    // console.log(localStorage.getItem('userLength'))
  }, [alert])
  // const [state,setState]=useState(s1)
  // const update=()=>{
  //     setTimeout(() => {
  //         setState({
  //             'name':'deep',
  //             'age':29
  //         })
  //     }, 1000);
  // }
  return (
    <DashContext.Provider value={{ users, alert, showAlert, getAllUsers,editUser,addUser,deleteUser_,setUsers,showAlert,parse_date }}>
      {props.children}
    </DashContext.Provider>
  );
};

export default DashState;
