import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./userlist.scss";
import DashContext from "../../context/dash/dashContext";
import Tables from "../../components/tables/Table";
import AddUser from "../../components/adduser/AddUser";

import { useDispatch,useSelector } from 'react-redux';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deletUsers, getUsers,updateUsers } from "../../components/help";

const UserList = (props) => {

  const getAllUsers_ = async () => {
    let a={}
    const url = `http://localhost:5000/api/auth/getuser/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "auth-token": `${localStorage.getItem("token")}`,
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjQzYmUzMzQ0NzhlMzFlNjkyZjk1ZTk3In0sImlhdCI6MTY4MTY1ODIzMH0.-SUVcaQlDM5uDQbE8SmYW-cEFxXDw99CFU9lDOMSG8c",
      },
    });
    const json = await response.json();
    console.log(json)
    return json
    // dispatch(getPosts({id:'budsuduwbduwbduw'}));
    //user_json=json;
    //console.log(json[0].name)
    //console.log(users)
  };

  let userx=0;
  const [user, setUser] = useState({
    name: "",
    customername: '',
    customerno: '',
    companyname: "",
    active: true,
    password: "",
    companyaddress: '',
  });

  const [user_, setUser_] = useState({
    name: "",
    companyName: "",
    active: true,
    password: "",
  });
  
  const ref = useRef(null);
  const refClose = useRef(null);
  const refDel = useRef(null);
  const refDelClose = useRef(null);
  const context = useContext(DashContext);
  const { users, getAllUsers, editUser,deleteUser_,setUsers,showAlert } = context;
  let location = useLocation();
  let history = useNavigate();
  const queryClient=useQueryClient()
  const {isLoading, isError, error, mutate} = useQuery(['users'],getUsers)
  const addMutation=useMutation(deletUsers)
  const delMutation=useMutation(updateUsers)
  const c_user=async(user)=>{
    await addMutation.mutate(user,{
      
      onSuccess:(e)=>{
        if(e.success==true) {
          showAlert("User Deleted","success")
        }
        else {
          showAlert("User Can't Be Deleted","danger")
        }
        queryClient.invalidateQueries(['users'])
      },
      onError:()=>{
        showAlert("User Can't Be Deleted","danger")
      }
    })
  }
  const c_user_1=async(user)=>{
    await delMutation.mutate(user,{
      onSuccess:(e)=>{
        if(e.success==true) {
          showAlert("User Updated","success")
        }
        else {
          showAlert("User Can't Be Updated","danger")
        }
        queryClient.invalidateQueries(['users'])
      },
      onError:()=>{
        showAlert("User Can't Be Updated","danger")
      }
    })
  }
  useEffect(() => {
    console.log(location.pathname);
    if (!localStorage.getItem("token")) {
      history("/");
    } else {
      // dispatch(getPosts({id:'budsuduwbduwbduw'}));
      // console.log(posts)
      // getAllUsers(); //initialize datatable

    }
  }, [location]);

  //const{status,data,isFetching}=useQuery(['users'],getAllUsers_)

  if (localStorage.getItem("token")) {
    //getAllUsers()
    // if(users)
    // console.log(users[0])
  }
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const deleteUser = (e) => {
    //editNote(note.id,note.etitle,note.edescription,note.etag)
    //console.log(e);
    userx=e.name;
    //useri=e._id;
    document.getElementById('delUser').innerHTML=`You Want To Delete User ${userx}`;
    refDel.current.click();
    //refClose.current.click();
    //props.showAlert("Note Updated Successfully","success")
    //getAllNotes();
  };
  const handleUserEdit = (e) => {
    ref.current.click();
    setUser({
      name: user.name,
      customername: user.customername,
      customerno: user.customerno,
      password: user.password,
      active: user.active,
      companyname: user.companyname,
      companyaddress: user.companyaddress,
    });
    //console.log(user._id);
    c_user_1(user);
    // console.log(user);
  };
  const handleUserDelete = (e) => {
    //console.log(useri)
    refDel.current.click();
    c_user(userx);
    localStorage.setItem('TableUpdate','Yes');
    localStorage.setItem('operation','DEL');
    // dispatch(getPosts({id:'budsuduwbduwbduw'}));
    //console.log(user_);
    //history("/loading");
  };
  const updateUser = (user) => {
    ref.current.click();
    //_id = user._id;
    setUser({
      _id: user._id,
      name: user.name,
      password: user.password,
      active: user.active,
      companyname: user.companyname,
      customername: user.customername,
      customerno: user.customerno,
      companyaddress: user.companyaddress,
    });
    //console.log(_id);
  };
  // if(localStorage.getItem('loginType')==='Admin') {
  //   getAllUsers();
  // }
  
  return (
    <>
<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal3" style={{ display: "none" }}
ref={refDel}>
  Launch demo modal
</button>

<div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Are You Sure?</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body" id="delUser">
        
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary"
        onClick={handleUserDelete}
        ref={refDelClose}>Delete</button>
      </div>
    </div>
  </div>
</div>


      <button
        ref={ref}
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{ display: "none" }}
      >
        Launch demo modal
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit User
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form action="" className="my-3">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    User Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    onChange={onChange}
                    value={user.name}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    onChange={onChange}
                    // value={user.name}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyname"
                    onChange={onChange}
                    value={user.companyname}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="companyAddress" className="form-label">
                    Company Address
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="companyAddress"
                    name="companyaddress"
                    onChange={onChange}
                    value={user.companyaddress}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="companyNo" className="form-label">
                    Customer No
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyNo"
                    name="customerno"
                    onChange={onChange}
                    value={user.customerno}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="customerName" className="form-label">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="customerName"
                    name="customername"
                    onChange={onChange}
                    value={user.customername}
                  />
                </div>
                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    id="inputGroupSelect02"
                    onChange={onChange}
                    value={user.active}
                    name="active"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Deactive</option>
                  </select>
                  <label
                    className="input-group-text"
                    htmlFor="inputGroupSelect02"
                  >
                    Status
                  </label>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={refClose}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUserEdit}
                disabled={user.name.length < 1 || user.password < 5}
              >
                Update User
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {localStorage.getItem("loginType") === "Admin" ? (
          <h1 style={{ textAlign: "center" }}>User Lists</h1>
        ) : (
          <h1 style={{ textAlign: "center" }}>Device Lists</h1>
        )}
        {localStorage.getItem("loginType") === "Admin" ? (
          <AddUser></AddUser>
        ) : (
          ""
        )}
        {localStorage.getItem("loginType") === "Admin" ? (
          <Tables updateUser={updateUser} deleteUser={deleteUser}></Tables>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UserList;
