import React,{ Component, useContext, useEffect, useState } from "react";
import "./home.scss";
import Navbar from "../../components/navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "../../components/login/LoginForm";
import DashState from "../../context/dash/dashState";
import About from "../about/About";
import Alert from "../../components/alert/Alert";
import DashContext from "../../context/dash/dashContext";
import UserList from "../userlist/UserList";

const Home = (props) => {
  const context=useContext(DashContext)
  const{showAlert}=context
  let location=useLocation();
  let history=useNavigate();
  useEffect(()=>{
    console.log(location.pathname)
    if(!localStorage.getItem('token')) {
      history('/');
    }
    else {
      if(localStorage.getItem("loginType")==='User') {
        localStorage.setItem('userId',localStorage.getItem("loginId"))
          localStorage.setItem('userName',localStorage.getItem("loginName"))
        history(`/devices/${localStorage.getItem("loginId")}/${localStorage.getItem("loginName")}`);
      }
      // localStorage.setItem("loginType", json.loginType);
      // localStorage.setItem("loginId", json.loginId);
      // localStorage.setItem("loginName", json.loginName);
    }
  },[])

  return (
    <>
      {localStorage.getItem('token')?<UserList></UserList> :<LoginForm showAlert={showAlert}></LoginForm>}
      
    </>
  );
};

export default Home;
